/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import api from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { handleResultado } from '../../../util/validaResultadoPesquisa';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { removerSpecials } from '../../../util/format';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { obterDataHora } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { addDays, format, startOfDay, endOfDay } from 'date-fns';
import Pagination from './Pagination/Pagination';

export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
    tipoEnvioPesquisa?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  tipo?: string;
};

const AnalisePerfilGrid = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const [totalItems, setTotalItems] = useState(10);

  const [loading, setLoading] = useState(false);
  const { page }: any = useParams();
  const [itensPerPage, setItensPerPage] = useState('10');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { addToast } = useToast();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [firstRequest, setFirstRequest] = useState(false);
  const [dataResults, setDataResults] = useState<ListagemHistoricoPesquisa[]>([]);
  const [dataSearch, setDataSearch] = useState<ListagemHistoricoPesquisa[]>([]);
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    rolePermission(
      [{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] }],
      'editar'
    );
  }, [ADMIN, rolePermission]);

  const paginate = (pageNumber: number) => {
    history.push(`/analise-perfil/painel/${pageNumber}`);
  };

  const handleGoToDetails = (each: any) => {
    const detalhePesquisaJanela = window.open(
      `/detalhe-pesquisa/${each.numLiberacao}/${each.idCliente}`,
      'janela',
      'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
    );

    const checkWindowClosed = setInterval(() => {
      if (detalhePesquisaJanela && detalhePesquisaJanela.closed) {
        clearInterval(checkWindowClosed);
        handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
      }
    }, 500);
  };

  const handleGoEdit = (each: any) => {
    const editarPesquisaJanela = window.open(
      `/analise-perfil/editar/${each.numLiberacao}`,
      'janela',
      'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
    );

    const checkWindowClosed = setInterval(() => {
      if (editarPesquisaJanela && editarPesquisaJanela.closed) {
        clearInterval(checkWindowClosed);
        handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
      }
    }, 500);
  };

  const retornaItemPesquisado = (each: any) => {
    let arr: string[] = [];

    if (each.operacao.includes('Integrada')) {
      each.itens.map((e: any) => {
        if (e.tipo.includes('Veículo')) {
          arr.push(e.itemPesquisado);
        } else {
          arr.push(e.descricao);
        }
      });
    } else {
      if (each.itens[0].tipo.includes('Veículo')) {
        arr.push(each.itens[0].itemPesquisado);
      } else {
        arr.push(each.itens[0].descricao);
      }
    }
    return arr.join();
  };

  const retornaTipo = (each: any) => {
    if (each.itens[0].tipoEnvioPesquisa === 'PESQUISA' || each.tipo === 'PESQUISA') {
      return 'Cadastro';
    }
    if (each.itens[0].tipoEnvioPesquisa === 'CONSULTA' || each.tipo === 'CONSULTA') {
      return 'Consulta';
    }

    if (each.itens[0].tipoEnvioPesquisa === 'MANUAL' || each.tipo === 'MANUAL') {
      return 'Manual';
    }

    return '';
  };

  const retornaResultado = (i: any) => {
    if (handleResultado(i) === 'Análise Vencida') {
      return 'alert-row';
    }
    if (handleResultado(i) === 'Pendente de Documentação') {
      return 'alert-row';
    }
    if (handleResultado(i) === 'Em Acordo') {
      return 'success-row';
    }
    return '';
  };

  const handleRequest = async ({ pagina, itensPerPage, keyword }: { pagina: number, itensPerPage: string, keyword: string }) => {
    setIsDialogAtualizandoOK(true); // Mantém o diálogo de "Atualizando..." enquanto a requisição está em andamento
    const dataTodos = {
      tipo: 'TODOS',
      tipoBusca: 'SEM_PARAMETROS',
      pagina,
      qtdRegistros: itensPerPage ? Number(itensPerPage) : 10,
      ...(keyword && { keyword }),
    };

    setCurrentPage(pagina);

    try {
      const response = await api.post(`/pesquisa/historico-gr`, dataTodos);
      setFirstRequest(true);

      setLoading(false);
      setDataResults(response.data.items);
      setTotalItems(response.data.count);
      setLastPage(response.data.pages);
      setIsDialogAtualizandoOK(false);
    } catch (error: any) {
      addToast({
        title: 'Erro!',
        description: 'Erro na comunicação com o servidor.',
        type: 'error',
      });
    } finally {
      setIsDialogAtualizandoOK(false);
      setRefreshitensPerPage(false);
      setRefresh(0);
      console.log('finally');
    }
  };

  useEffect(() => {
    handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
  }, []);

  useEffect(() => {
    if(!refresh) return

    handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;

    handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
  }, [refreshitensPerPage]);


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchValue && searchValue.trim() !== '') {
        // history.push('/analise-perfil/painel/1');
        handleRequest({ pagina: page, itensPerPage, keyword: searchValue });
      } else {
        handleRequest({ pagina: page, itensPerPage, keyword: '' });
      }
    }, 1000)

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchValue]);

  return (
    <Tela
      nome="Painel Análise Perfil"
      caminho="Análises de Perfil > Painel Análise Perfil"
      loading={!dataResults.length && !firstRequest}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataResults.length}
      paginate={paginate}
      currentPage={false}
    >
      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />
      {loadingSearch ? (
        <Spinner />
      ) : (
        <div className="table-responsive" style={{ flex: 1 }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Número</th>
                <th>Empresa</th>
                <th>Item Pesquisado</th>
                <th>Tipo</th>
                <th>Situação</th>
                <th>Lançamento</th>
                <th>Retorno</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dataResults &&
                dataResults.map((each: ListagemHistoricoPesquisa, index: number) => {
                  return (
                    <tr key={index} className={retornaResultado(each.itens)}>
                      <td>{each.numLiberacao}</td>
                      <td>{each.empresa}</td>
                      <td>{retornaItemPesquisado(each)}</td>
                      <td>{retornaTipo(each)}</td>
                      <td>{handleResultado(each.itens)}</td>
                      <td>{obterDataHora(each.dataLancamento, true)}</td>
                      <td>
                        {each.itens[0].dataRetorno === null ||
                        handleResultado(each.itens) === 'Em Andamento'
                          ? ''
                          : obterDataHora(each.itens[0].dataRetorno, true)}
                      </td>
                      <td align="right">
                        <ButtonTooltip
                          type="Editar"
                          onClick={() => {
                            if (canEdit) {
                              handleGoEdit(each);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                        />
                      </td>
                      <td align="right">
                        <ButtonTooltip type="Visualizar" onClick={() => handleGoToDetails(each)} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Pagination
              lastPage={lastPage}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              paginate={paginate}
              currentPage={String(currentPage)}
              setRefresh={setRefresh}
              setData={setDataResults}
              setRefreshitensPerPage={setRefreshitensPerPage}
              totalPosts={totalItems}
            />
        </div>
      )}
    </Tela>
  );
};

export default AnalisePerfilGrid;
