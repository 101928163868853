/* eslint-disable */

import { ChangeEvent, useEffect, useState, FC } from 'react';
import { Col, Form, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { EmbarcadorType } from './EmbarcadorGrid';
import { maskCep, maskCpnj, maskTel, validaNumero } from '../../util/Validacoes/Validacoes';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import instanceMaquina from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';

type ModelValidationType = {
  isCnpjInvalid: boolean;
  isTelefoneInvalid: boolean;
  isTelefoneDoisInvalid: boolean;
  isTelefoneTresInvalid: boolean;
  isFormInvalid: boolean;
};

type EditProps = {
  showEdit: boolean;
  setShowEdit: (bool: boolean) => void;
  id: any;
  canDelete?: boolean;
  canEdit?: boolean;
};

const EmbarcadorEdit: FC<EditProps> = ({ id, showEdit, setShowEdit, canDelete, canEdit }) => {
  const { addToast } = useToast();
  const history = useHistory();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [model, setModel] = useState<EmbarcadorType>({
    cnpj: '',
    enderecoBairro: '',
    enderecoCep: '',
    enderecoCidade: '',
    enderecoComplemento: '',
    enderecoLogradouro: '',
    enderecoNumero: '',
    idEmbarcador: 0,
    razaoSocial: '',
    statusAtivo: true,
    telefone: '',
    telefoneDois: '',
    telefoneTres: '',
    contato: '',
    contatoDois: '',
    contatoTres: '',
    uf: 0,
    idCliente: isAllowedByRole(['admin']) ? 0 : clienteId,
    razaoSocialCliente: '',
  });

  const [modelValidation, setModelValidation] = useState<ModelValidationType>({
    isCnpjInvalid: false,
    isTelefoneInvalid: false,
    isTelefoneDoisInvalid: false,
    isTelefoneTresInvalid: false,
    isFormInvalid: false,
  });

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [viaCepCidade, setViaCepCidade] = useState('');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    async function loadData() {
      const response = await axios.get(`/embarcador/listarPorId/${id}`);
      setModel({
        cnpj: `${response.data.cnpj.slice(0, 2)}.${response.data.cnpj.slice(
          2,
          5
        )}.${response.data.cnpj.slice(5, 8)}/${response.data.cnpj.slice(
          8,
          12
        )}-${response.data.cnpj.slice(-2)}`,

        enderecoBairro: response.data.enderecoBairro,

        enderecoCep: response.data.enderecoCep
          ? `${response.data.enderecoCep.substring(5, -3)}-${response.data.enderecoCep.substring(
              5
            )}`
          : '',

        enderecoCidade: response.data.enderecoCidade,
        enderecoComplemento: response.data.enderecoComplemento,
        enderecoLogradouro: response.data.enderecoLogradouro,
        enderecoNumero: response.data.enderecoNumero,
        idEmbarcador: response.data.idEmbarcador,
        razaoSocial: response.data.razaoSocial,
        statusAtivo: response.data.statusAtivo,

        telefone: response.data.telefone,
        telefoneDois: response.data.telefoneDois,
        telefoneTres: response.data.telefoneTres,

        contato: response.data.contato,
        contatoDois: response.data.contatoDois,
        contatoTres: response.data.contatoTres,
        uf: response.data.uf,
        idCliente: clienteId,
        razaoSocialCliente: '',
      });
    }
    loadData();
  }, [id]); // GET Embarcador ListarPorId

  function updateModel(e: ChangeEvent<HTMLInputElement>, validation?: Function) {
    setModel((prevState) => {
      if (validation) {
        return {
          ...prevState,
          [e.target.name]: validation(e.target.value),
        };
      }
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }
  useEffect(() => {
    instanceMaquina.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {

      setUfs(response.data);
    });
  }, []);

  const loadMunicipiosPorEstado = async (estado: any) => {
    if (!estado) return

    try {
      const response = await instanceMaquina.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {

    const buscarCep = () => {
      if (model.enderecoCep.length < 9) {
        setModel({
          ...model,
          enderecoLogradouro: '',
          enderecoBairro: '',
          enderecoComplemento: '',
          uf: 0,
          enderecoCidade: '',
          enderecoNumero: '',
        });
        return;
      }
      fetch(`https://viacep.com.br/ws/${model.enderecoCep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            return;
          }

          setViaCepCidade(data.localidade);

          setModel({
            ...model,
            uf: ufs?.find((each: any) => each.sigla === data.uf)?.id,
            enderecoCidade: data.localidade,
            enderecoLogradouro: data.logradouro,
            enderecoBairro: data.bairro,
          });

          loadMunicipiosPorEstado(ufs.find((each: any) => each.sigla === data.uf)?.id);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
        })
        .catch((erro) => {
          console.log('error', erro);
        });
    };
    buscarCep();
  }, [model.enderecoCep, ufs]); // buscarCep

  function handleCnpjValidation(cnpj: string) {
    if (cnpj.length == 0 || cnpj.length == 18) {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: true,
      });
    }
  }

  function handleTelefoneValidation(tel: string) {
    if (tel.length == 0 || tel.length == 13 || tel.length == 14) {
      setModelValidation({
        ...modelValidation,
        isTelefoneInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isTelefoneInvalid: true,
      });
    }
  }

  function handleTelefoneDoisValidation(tel: string) {
    if (tel.length == 0 || tel.length == 13 || tel.length == 14) {
      setModelValidation({
        ...modelValidation,
        isTelefoneDoisInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isTelefoneDoisInvalid: true,
      });
    }
  }

  function handleTelefoneTresValidation(tel: string) {
    if (tel.length == 0 || tel.length == 13 || tel.length == 14) {
      setModelValidation({
        ...modelValidation,
        isTelefoneTresInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isTelefoneTresInvalid: true,
      });
    }
  }

  async function handleDelete(id: number) {
    try {
      const response = await axios.put(`/embarcador/delete-logico/${id}`);

      addToast({
        title: 'Sucesso!',
        description: 'Embarcador excluído com sucesso.',
        type: 'success',
      });
      history.go(0)

    } catch (alert) {
      addToast({
        title: 'Erro!',
        description: 'Erro ao desativar o cadastro',
        type: 'error',
      });
    }
    setIsDeleteModalOpen(false);
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isAllowedByRole(['user'])) {
      if (!model.cnpj || !model.razaoSocial || !model.idCliente || modelValidation.isCnpjInvalid) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setModelValidation({
          ...modelValidation,
          isFormInvalid: true,
        });
        e.stopPropagation();
        return;
      }
    } else if (!model.cnpj || !model.razaoSocial || modelValidation.isCnpjInvalid) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setModelValidation({
        ...modelValidation,
        isFormInvalid: true,
      });
      e.stopPropagation();
      return;
    }

    if (
      modelValidation.isTelefoneInvalid ||
      modelValidation.isTelefoneDoisInvalid ||
      modelValidation.isTelefoneTresInvalid
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos de Telefone',
        type: 'error',
      });
      setModelValidation({
        ...modelValidation,
        isFormInvalid: true,
      });
      e.stopPropagation();
      return;
    }

    const data = {
      ...model,
      telefone: model.telefone
        ? model.telefone.replace('(', '').replace(')', '').replace('-', '')
        : '',
      telefoneDois: model.telefoneDois
        ? model.telefoneDois.replace('(', '').replace(')', '').replace('-', '')
        : '',
      telefoneTres: model.telefoneTres
        ? model.telefoneTres.replace('(', '').replace(')', '').replace('-', '')
        : '',
    };

    instanceMaquina
      .put(`/embarcador/atualizar/${id}`, data)
      .then(() => {
        setIsDialogVisibleCadEfetuado(true)
        history.go(0)

      })
      .catch(() => {
        addToast({
          title: 'Erro!',
          description: 'Erro ao editar!',
          type: 'error',
        });
      });
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Embarcador editado com sucesso!"
        footer={
          <Button
            onClick={() => {
              setIsDialogVisibleCadEfetuado(false);
              setShowEdit(false);
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.goBack()}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Regras de Apólice</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Regras de Apólice'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Regras de Apólice'}
            </h6>
          )}

          <button
            className="btn-desativar"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginLeft: '50%',
            }}
            onClick={() => {
              if (canDelete) {
                setIsDeleteModalOpen(true);
              } else {
                history.push('/acessonegado');
              }
            }}
            type="button"
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
          </button>
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form
          className="form-responsive"
          onSubmit={onSubmit}
          noValidate
          validated={modelValidation.isFormInvalid}
        >
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCnpj">
              <Form.Label className="require">CNPJ Nº</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                placeholder=""
                maxLength={18}
                minLength={18}
                required
                disabled
                value={model.cnpj}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskCpnj)}
                onBlur={() => handleCnpjValidation(model.cnpj)}
                isInvalid={modelValidation.isCnpjInvalid}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRazaoSocial">
              <Form.Label className="require">Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razaoSocial"
                placeholder=""
                maxLength={256}
                required
                value={model.razaoSocial}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCep">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                type="text"
                name="enderecoCep"
                placeholder=""
                maxLength={9}
                minLength={9}
                style={{ width: '32.8%' }}
                value={model.enderecoCep}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskCep)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridUf">
              <Form.Label>UF</Form.Label>
              <Form.Control
                type="number"
                name="uf"
                placeholder=""
                as="select"
                defaultValue={0}
                value={model.uf}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateModel(e), loadMunicipiosPorEstado(e.target.value);
                }}
              >
                <option value={0}>Selecione uma opção</option>
                {ufs.map((ufs) => (
                  <option value={ufs.id} key={ufs.id}>
                    {ufs.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                as="select"
                name="enderecoCidade"
                placeholder=""
                defaultValue=""
                value={model.enderecoCidade}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              >
                <option value="">Selecione uma opção</option>
                {model.enderecoCidade && <option>{model.enderecoCidade}</option>}
                {municipios.map((filteredMunicipio, index) => (
                  <option key={index} value={filteredMunicipio.nomeMunicipio}>
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                name="enderecoBairro"
                placeholder=""
                maxLength={128}
                value={model.enderecoBairro}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridLogradouro">
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                name="enderecoLogradouro"
                placeholder=""
                maxLength={256}
                value={model.enderecoLogradouro}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridComplemento">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                maxLength={128}
                name="enderecoComplemento"
                placeholder=""
                value={model.enderecoComplemento}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNumero">
              <Form.Label>Número</Form.Label>
              <Form.Control
                type="text"
                maxLength={8}
                name="enderecoNumero"
                placeholder=""
                value={model.enderecoNumero}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (validaNumero(e.target.value) || e.target.value.length === 0) {
                    updateModel(e);
                  } else {
                  }
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridContato">
              <Form.Label>Contato 1</Form.Label>
              <Form.Control
                name="contato"
                type="text"
                placeholder=""
                value={model.contato}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridContatoDois">
              <Form.Label>Contato 2</Form.Label>
              <Form.Control
                name="contatoDois"
                placeholder=""
                value={model.contatoDois}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridContatoTres">
              <Form.Label>Contato 3</Form.Label>
              <Form.Control
                name="contatoTres"
                placeholder=""
                value={model.contatoTres}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                maxLength={64}
                minLength={3}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridTelefone">
              <Form.Label>Telefone 1</Form.Label>
              <Form.Control
                name="telefone"
                placeholder=""
                value={model.telefone ? maskTel(model.telefone) : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
                onBlur={() => handleTelefoneValidation(model.telefone)}
                isInvalid={modelValidation.isTelefoneInvalid}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelefoneDois">
              <Form.Label>Telefone 2</Form.Label>
              <Form.Control
                name="telefoneDois"
                placeholder=""
                value={model.telefoneDois ? maskTel(model.telefoneDois) : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
                onBlur={() => handleTelefoneDoisValidation(model.telefoneDois)}
                isInvalid={modelValidation.isTelefoneDoisInvalid}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelefoneTres">
              <Form.Label>Telefone 3</Form.Label>
              <Form.Control
                name="telefoneTres"
                placeholder=""
                value={model.telefoneTres ? maskTel(model.telefoneTres) : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e, maskTel)}
                maxLength={14}
                minLength={13}
                onBlur={() => handleTelefoneTresValidation(model.telefoneTres)}
                isInvalid={modelValidation.isTelefoneTresInvalid}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              <Button
                className="btn-cancelar"
                onClick={() => {
                  // history.push({
                  //   pathname: '/admin/listar/embarcadores/1',
                  //   state: {
                  //     nome: model.razaoSocial,
                  //   },
                  // });
                  setShowEdit(false);
                }}
              >
                Cancelar
              </Button>
            </Form.Group>
            <Form.Group as={Col} md={2}>
              {canEdit ? (
                <Button className="btn-enviar" type="submit">
                  Confirmar
                </Button>
              ) : (
                <Button
                  className="btn-enviar"
                  type="button"
                  onClick={() => history.push('/acessonegado')}
                >
                  Confirmar
                </Button>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={isDeleteModalOpen}
        onHide={() => setIsDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro"{`${model.razaoSocial}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => {
              if (canDelete) {
                handleDelete(id);
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default EmbarcadorEdit;
