/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { obterDataHora } from '../../util/date';
import { StepperPointTravelProgress } from '../../components/StepperPointTravelProgress';
import { StepperLineTravelProgress } from '../../components/StepperLineTravelProgress';
import { ReactComponent as SucessIcon } from '../../assets/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as DangerIcon } from '../../assets/danger.svg';

export type Progressao = {
  blStatusInicializacao: boolean;
  desStatusInicioSm: string;
  dtHrChegadaProxPonto: string;
  dtHrUltimoEvento: string;
  dtInicioRealSm: string;
  hrInicioRealSm: string;
  idCarreta: number;
  idCarreta2: number;
  idCliente: number;
  idSm: number;
  idveiculo: number;
  monitSmProgresViagPontoVO: {
    dataChegadaPlanejada: string;
    dataReplanejada: string;
    distanciaProxPonto: number;
    dtHrCalculadaChegadaPonto: string;
    dtHrCalculadaProxPonto: string;
    dtHrChegadaPonto: string;
    dtHrInicioRealSM: string;
    dtHrInicioSM: string;
    dtHrPlanejadaViagemPonto: string;
    dtHrUltimoEvento: string;
    enderecoPonto: string;
    hintPonto: string;
    idLsc: number;
    idPlanoViagem: number;
    idPontoPlanejamentoSmo: number;
    idPontoSmo: number;
    idPontoViagem: number;
    idPontoViagemReplanejada: number;
    idSm: number;
    latitudePonto: number;
    longitudePonto: number;
    nomePonto: string;
    numSeqPonto: number;
    origemDestinoPonto: string;
    percPercorridoPonto: number;
    previsao: string;
    previsaoComparadas: string;
    remessa: any;
    statusConcluidoPonto: boolean;
    statusPonto: string;
    tempoProxPonto: number;
  }[];
  nomProxPonto: string;
  percPercorrido: number;
  percorrido: string;
  placas: string;
  proximoPonto: string;
  statusSm: string;
};

type Ponto = {
  dataChegadaPlanejada: string;
  dataReplanejada: string;
  distanciaProxPonto: number;
  dtHrCalculadaChegadaPonto: string;
  dtHrCalculadaProxPonto: string;
  dtHrChegadaPonto: string;
  dtHrInicioRealSM: string;
  dtHrInicioSM: string;
  dtHrPlanejadaViagemPonto: string;
  dtHrUltimoEvento: string;
  enderecoPonto: string;
  hintPonto: string;
  idLsc: number;
  idPlanoViagem: number;
  idPontoPlanejamentoSmo: number;
  idPontoSmo: number;
  idPontoViagem: number;
  idPontoViagemReplanejada: number;
  idSm: number;
  latitudePonto: number;
  longitudePonto: number;
  nomePonto: string;
  numSeqPonto: number;
  origemDestinoPonto: string;
  percPercorridoPonto: number;
  previsao: string;
  previsaoComparadas: string;
  remessa: any;
  statusConcluidoPonto: boolean;
  statusPonto: string;
  tempoProxPonto: number;
};

const ProgressaoViagens: FC = () => {
  const [parametroLogistico, setParametroLogistico] = useState<{
    tempoAtualizacaoDadosEstaticosEmMinutos: number | undefined;
  }>();
  const [loading, setLoading] = useState(false);
  const [dataProgressao, setDataProgressao] = useState<Progressao[]>([]);
  const usuario = getCookieSessionData().usuarioVO.usuarioId;
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);

  useEffect(() => {
    api.get('parametro-logistico').then((response) => setParametroLogistico(response.data));
  }, []);

  useEffect(() => {
    const loadProgressaoViagem = async () => {
      if (!parametroLogistico || !parametroLogistico.tempoAtualizacaoDadosEstaticosEmMinutos) {
        return;
      }
      const milisegundosParaAtualizacao =
        parametroLogistico.tempoAtualizacaoDadosEstaticosEmMinutos * 1000 * 100;

      setLoading(true);
      try {
        const response = await api.get(
          `/solicitacao-monitoramento/monitoramento-sm-progressao-viagem-mestre-detalhe?idUsuario=${usuario}`
        );
        setDataProgressao(response.data);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
      setTimeout(() => {
        loadProgressaoViagem();
        setIsDialogAtualizandoOK(true);
      }, milisegundosParaAtualizacao);
      setIsDialogAtualizandoOK(false);
    };
    loadProgressaoViagem();
  }, [parametroLogistico]);

  const choosePontoBackgroundColor = (statusPonto: string) => {
    if (statusPonto === 'B') return '#bfffbd';
    if (statusPonto === 'A') return '#ffabb4';
    if (statusPonto === 'D') return '#ffdaa6';
    return '#FFFFFF';
  };

  const getHintPonto = (ponto: Ponto) => {
    if (ponto.origemDestinoPonto === 'O') {
      return (
        <div className="d-flex flex-column align-items-start">
          <span>{ponto.nomePonto}</span>
          <span>Previsão de Saída: {obterDataHora(ponto.dtHrInicioSM, true)}</span>
          {ponto.dtHrInicioRealSM !== null && (
            <span>Saída: {obterDataHora(ponto.dtHrInicioRealSM, true)}</span>
          )}
        </div>
      );
    }

    return (
      <div className="d-flex flex-column align-items-start">
        <span>{ponto.nomePonto} </span>
        <span>
          {ponto.dtHrPlanejadaViagemPonto === null
            ? ''
            : `Agendada: ${obterDataHora(ponto.dtHrPlanejadaViagemPonto, true)} `}
        </span>
        <span>
          {ponto.dtHrInicioRealSM !== null &&
            (ponto.dtHrCalculadaProxPonto === null
              ? ''
              : `Previsão de Chegada: ${obterDataHora(ponto.dtHrCalculadaProxPonto, true)}`)}
        </span>
        <span>
          {ponto.dtHrChegadaPonto === null
            ? ''
            : `Chegada: ${obterDataHora(ponto.dtHrChegadaPonto, true)}`}
        </span>
      </div>
    );
  };

  const iconProgresstatus = (ponto: Ponto) => {
    switch (ponto.statusPonto) {
      case 'A':
        return <DangerIcon className="icon" />;
      case 'B':
        return <SucessIcon className="icon" />;
      case 'D':
        return <WarningIcon className="icon" />;
      default:
        return null;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />

      {/* <ModalProgressao setShow={setShow} show={show} data={modalData} /> */}
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Rastreamento</h2>
          <h6 className="subtitulo">{'Rastreamento > Progressão de viagens'}</h6>
        </div>
        <div className="painel">
          {loading ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Origem</th>
                    <th id="progressao-linha">Progressão</th>
                    <th id="destino-header">Destino</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="progressao-cabecalho" />
                    <td className="progressao-cabecalho">
                      <span className="progressao-cabecalho-texto">{`Total de viagens: ${dataProgressao.length} `}</span>
                      <span className="progressao-cabecalho-texto">{`No prazo: ${
                        dataProgressao.filter((each) => each.statusSm === 'B').length
                      }`}</span>
                      <span className="progressao-cabecalho-texto">{`Atrasadas: ${
                        dataProgressao.filter((each) => each.statusSm === 'A').length
                      }`}</span>
                    </td>
                    <td className="progressao-cabecalho" />
                  </tr>
                  {dataProgressao &&
                    dataProgressao.map((viagem) => {
                      const forecastComing = viagem.monitSmProgresViagPontoVO.find(
                        (forecast) => forecast.dtHrCalculadaProxPonto !== null
                      );

                      return (
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>
                            {viagem.monitSmProgresViagPontoVO[0] === undefined
                              ? ''
                              : viagem.monitSmProgresViagPontoVO[0].nomePonto}
                          </td>
                          <td>
                            <tr>
                              <span className="progressao-body-texto">{`Nº SM: ${viagem.idSm} `}</span>
                              <span className="progressao-body-texto">{`Placas: ${viagem.placas}`}</span>
                              <span className="progressao-body-texto">{`Percorrido: ${viagem.percorrido}`}</span>
                              {viagem.percPercorrido === 100 ? null : (
                                <span className="progressao-body-texto">{`Próximo ponto: ${viagem.proximoPonto
                                  .split('(')[0]
                                  .trim()} ( ${
                                  forecastComing &&
                                  obterDataHora(forecastComing.dtHrCalculadaProxPonto, true)
                                } )`}</span>
                              )}
                            </tr>
                            <tr className="timeline">
                              {viagem.monitSmProgresViagPontoVO.map((ponto, index) => {
                                const isLastPonto =
                                  index + 1 === viagem.monitSmProgresViagPontoVO.length;
                                if (isLastPonto) {
                                  return null;
                                }
                                return (
                                  <>
                                    <StepperPointTravelProgress ponto={ponto} index={index} />
                                    <div className="progresso-linha pointer">
                                      <StepperLineTravelProgress ponto={ponto} />
                                    </div>
                                  </>
                                );
                              })}

                              <OverlayTrigger
                                key={Number(viagem.idveiculo)}
                                overlay={
                                  <Tooltip
                                    className="tooltipTravel"
                                    id={viagem.idveiculo.toString()}
                                  >
                                    {getHintPonto(
                                      viagem.monitSmProgresViagPontoVO[
                                        viagem.monitSmProgresViagPontoVO.length - 1
                                      ]
                                    )}
                                  </Tooltip>
                                }
                              >
                                {viagem.monitSmProgresViagPontoVO[
                                  viagem.monitSmProgresViagPontoVO.length - 1
                                ].statusConcluidoPonto ? (
                                  <div className="progresso-ponto">
                                    {iconProgresstatus(
                                      viagem.monitSmProgresViagPontoVO[
                                        viagem.monitSmProgresViagPontoVO.length - 2
                                      ]
                                    )}
                                    <div className="progress-name-container">
                                      <span style={{ opacity: 0.5 }}>
                                        {
                                          viagem.monitSmProgresViagPontoVO[
                                            viagem.monitSmProgresViagPontoVO.length - 1
                                          ].nomePonto
                                        }
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="progresso-ponto"
                                    style={{
                                      backgroundColor:
                                        viagem.monitSmProgresViagPontoVO[
                                          viagem.monitSmProgresViagPontoVO.length - 1
                                        ].percPercorridoPonto === 0
                                          ? '#fff'
                                          : choosePontoBackgroundColor(
                                              viagem.monitSmProgresViagPontoVO[
                                                viagem.monitSmProgresViagPontoVO.length - 1
                                              ].statusPonto
                                            ),
                                      border: '2px dashed #ddd',
                                      opacity: 0.3,
                                    }}
                                  >
                                    <span>{viagem.monitSmProgresViagPontoVO.length}</span>
                                    <div className="progress-name-container">
                                      <span>
                                        {
                                          viagem.monitSmProgresViagPontoVO[
                                            viagem.monitSmProgresViagPontoVO.length - 1
                                          ].nomePonto
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </OverlayTrigger>
                            </tr>
                          </td>
                          {viagem.monitSmProgresViagPontoVO[0] === undefined ? null : (
                            <>
                              <td
                                style={{
                                  verticalAlign: 'middle',
                                  justifyItems: 'flex-end',
                                  alignSelf: 'flex-end',
                                  justifyContent: 'flex-end',
                                  textAlign: 'end',
                                }}
                              >
                                {
                                  viagem.monitSmProgresViagPontoVO[
                                    viagem.monitSmProgresViagPontoVO.length - 1
                                  ].nomePonto
                                }
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressaoViagens;
