/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { PAGE } from '../../../constants/Paginacao';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type AnalistaPerfilType = {
  rapCdId: number;
  rapTxNome: string;
  rapBlAtivo?: boolean;
  rapNuOrdem: number;
};

const AnalistaPerfilForm: React.FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [model, setModel] = useState<AnalistaPerfilType>({
    rapCdId: 0,
    rapTxNome: '',
    rapNuOrdem: 0,
    rapBlAtivo: true,
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { rapTxNome } = model;

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.ANALISTA_PERFIL]}], 'editar');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.ANALISTA_PERFIL]}], 'excluir');
  }, [ADMIN, rolePermission]);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/responsavel-analise-perfil/find-id?idAnalise=${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!rapTxNome) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/responsavel-analise-perfil/atualizar?idAnalise=${id}`, model);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/responsavel-analise-perfil/save', model);
        setIsDialogCadOK(true);
      } catch (error: any) {
        addToast({
          title: 'Erro',
          description: error.response.data.message
            ? error.response.data.message
            : 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/responsavel-analise-perfil/delete-logico?idAnalise=${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      setShow(false);
      setShowDialogDelete(true);
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Analistas de Perfil ${id ? 'alterado' : 'cadastrado'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.push(`/listar/analistas-de-perfil/${PAGE}`)}
      />
      <Dialog
        header="Analista de Perfil excluído com sucesso!"
        footer={
          <Button onClick={() => history.push(`/listar/analistas-de-perfil/${PAGE}`)}>Ok</Button>
        }
        visible={showDialogDelete}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.push(`/listar/analistas-de-perfil/${PAGE}`)}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Analistas de Perfil excluída com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Analistas de Perfil</h2>
          <h6 className="subtitulo">{`Cadastro > Analistas de Perfil > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Group>
                  <Form.Label className="requiredField">Nome</Form.Label>
                  <Form.Control
                    value={rapTxNome}
                    onChange={(e) => {
                      setModel({ ...model, rapTxNome: e.target.value });
                    }}
                    required
                  />
                </Form.Group>
                <ButtonsForm canEdit={canEdit} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalistaPerfilForm;
