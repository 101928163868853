/* eslint-disable */

import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import { Form, Col, ProgressBar, Button as ButtonBootstrap } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Modal from 'react-bootstrap/esm/Modal';
import sortBy from 'lodash/sortBy';
import { login as loginService } from '../../services/login';
import axios from '../../config/axiosMaquina';
import ModalAddParada from './ModalAddParada';
import { ParadaSMType } from '../../api/model/ParadaType';
import { SolicitacaoMonitoramentoType } from '../../api/model/SolicitacaoMonitoramentoType';
import { VeiculoIdPlaca } from '../../api/model/VeiculoIdPlacaType';
import { CarretaIdPlaca } from '../../api/model/CarretaIdPlacaType';
import { Carreta2IdPlaca } from '../../api/model/Carreta2IdPlacaType';
import { BauIdNumero } from '../../api/model/BauIdNumeroType';
import { VeiculoDadosSM } from '../../api/model/VeiculoDadosSMType';
import { CarretaDadosSM } from '../../api/model/CarretaDadosSMType';
import { Carreta2DadosSM } from '../../api/model/Carreta2DadosSMType';
import { BauIntercambiavelDadosSM } from '../../api/model/BauIntercambiavelDadosSMType';
import { EmbarcadorIdRazaoSocial } from '../../api/model/EmbarcadorIdRazaoSocialType';
import { EmbarcadorDadosSM } from '../../api/model/EmbarcadorDadosSMType';
import { CondutorIdNome } from '../../api/model/CondutorIdNomeType';
import { AjudanteIdNome } from '../../api/model/AjudanteIdNomeType';
import { TipoOperacaoSMIdDescricao } from '../../api/model/TipoOperacaoSMIdDescricaoType';
import { TecnologiaRastreamentoIdNome } from '../../api/model/TecnologiaRastreamentoIdNomeType';
import { TipoComunicacaoIdNome } from '../../api/model/TipoComunicacaoIdNomeType';
import ModalCadastrarRotaPorSM from '../Rotas/modalCadastroRotaPorSM';
import ModalCadastroCondutorPorSM from '../Condutor/CadastroCondutor/modalCadastroCondutorPorSM';
import ModalCadastroAjudantePorSM from '../AjudanteCadastro/modalCadastroAjudantePorSM';
import ModalCadastroBauPorSM from '../BauIntercambiavel/BinAdd/modalCadastroBauPorSM';
import ModalCadastroVeiculoPorSM from '../Veiculo/CadastroVeiculo/modalCadastroVeiculoPorSM';
import { RotaIdNome } from '../../api/model/RotaIdNomeType';
import {
  soLetras,
  validarPlaca,
  soNumeros,
  isHourLessThanBaseHour,
  isDateLessThanBaseDate,
  toDecimal,
  toCurrency,
} from '../../util/Validacoes/Validacoes';
import './styles.css';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { maskTelefone } from '../../util/masks';
import { confirmUser, getCookieSessionData } from '../../services/cookieService';
import getUfEndereco from '../../util/getUfEndereco';
import { ParametroOperacional } from '../../api/model/ParametroOperacional';
import { useToast } from '../../hooks/Toast';
import { ListaPontosType } from '../../api/model/ConfirmarPreSMType';
import { TipoOperacoesCliente } from '../../api/model/TipoOperacoesClienteType';
import { ListaPontos } from '../../api/Types/agendarViagensType';
import { BauDTO, DispositivoDTO } from '../../Types/AssistenciaTecnica';
import { LoginUser, PontoType } from '../../Types/SmTypes';
import { LinhaForm } from '../../components/LinhaForm';
import { Spinner } from '../../components/Spinner';
import { AvisosBloqueios as ServicosContratadosType } from '../../api/Types/Types';
import { AddButton } from '../../components/AddButton';
import { RotaType } from '../../api/model/RotaType';
import {
  nowToZoned,
  obterDataAtual,
  obterDataHora,
  obterData,
  dataHoraConverter,
  dataConverter,
  toZoned,
} from '../../util/date';
import { handleCreatePointsSM } from '../../services/transitTime';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const SolicitacaoMonitoramentoCadastro: React.FC = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsertVeiculo } = useRolePermission();
  const { doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canInsertBau } = useRolePermission();
  const { doesHavePermission: canInsertCondutor } = useRolePermission();
  const { doesHavePermission: canInsertAjudante } = useRolePermission();
  const { doesHavePermission: canInsertRota } = useRolePermission();

  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');
  const history = useHistory();
  const { addToast } = useToast();

  // id da SM se for confirmação de pré-sm, 0 se for nova SM
  const { id }: any = useParams();
  // dados do pré agendamento da SM
  const { state } = useLocation();

  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;

  // States add Paradas
  const [showModal, setShowModal] = useState(false);
  const [sequencia, setSequencia] = useState(0);
  const [motivo, setMotivo] = useState('');
  const [local, setLocal] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState(0);
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [idCodigoIbge, setIdCodigoIbge] = useState(0);
  const [cep, setCep] = useState('');
  const [idParada, setIdParada] = useState('');
  const [paradas, setParadas] = useState<ParadaSMType[]>([]);

  // states dos dispositivos
  const [dispositivos, setDispositivos] = useState<DispositivoDTO | null>({} as DispositivoDTO);
  const [dispositivosCarreta, setDispositivosCarreta] = useState<DispositivoDTO | null>(
    {} as DispositivoDTO
  );
  const [dispositivosCarreta2, setDispositivosCarreta2] = useState<DispositivoDTO | null>(
    {} as DispositivoDTO
  );
  const [dispositivosBau, setDispositivosBau] = useState<BauDTO | null>({} as BauDTO);

  const { login } = getCookieSessionData().usuarioVO;

  const [usuarioLogado, setUsuarioLogado] = useState<LoginUser>({
    usuario: login,
    senha: '',
    isLogado: false,
  });
  const [usuarioLogado2, setUsuarioLogado2] = useState<LoginUser>({
    usuario: login,
    senha: '',
    isLogado: false,
  });
  const [usuarioLogado3, setUsuarioLogado3] = useState<LoginUser>({
    usuario: login,
    senha: '',
    isLogado: false,
  });
  const [usuarioLogado4, setUsuarioLogado4] = useState<LoginUser>({
    usuario: login,
    senha: '',
    isLogado: false,
  });
  const [usuarioLogado5, setUsuarioLogado5] = useState<LoginUser>({
    usuario: login,
    senha: '',
    isLogado: false,
  });

  const [pontoOrigem, setPontoOrigem] = useState<PontoType>({
    onlyDataPonto: '',
    onlyTimePonto: '',
    dataPonto: '',
    idPonto: null,
    idSm: null,
    idSmPv: null,
    idUsuario,
    remessa: '',
    sequencia: 0,
    tipoPonto: '',
  });

  const [pontoDestino, setPontoDestino] = useState<PontoType>({
    onlyDataPonto: '',
    onlyTimePonto: '',
    dataPonto: '',
    idPonto: null,
    idSm: null,
    idSmPv: null,
    idUsuario,
    remessa: '',
    sequencia: 0,
    tipoPonto: '',
  });
  const [servicosContratados, setServicosContratados] = useState<ServicosContratadosType>();

  const codigoUsuarioLogado = getCookieSessionData().usuarioVO.clienteId;
  const roleUsuarioLogado = getCookieSessionData().usuarioVO.role;

  const [showModalRota, setShowModalRota] = useState(false);
  const [showModalCondutor, setShowModalCondutor] = useState(false);
  const [showModalAjudante, setShowModalAjudante] = useState(false);
  const [showModalBau, setShowModalBau] = useState(false);
  const [showModalVeiculo, setShowModalVeiculo] = useState(false);

  const [erroLoginUs, setErroLoginUs] = useState(false);
  const [erroLoginUs2, setErroLoginUs2] = useState(false);
  const [erroLoginUs3, setErroLoginUs3] = useState(false);
  const [erroLoginUs4, setErroLoginUs4] = useState(false);
  const [erroLoginUs5, setErroLoginUs5] = useState(false);

  const [showSuccessLoginUs, setShowSuccessLoginUs] = useState(false);
  const [showSuccessLoginUs2, setShowSuccessLoginUs2] = useState(false);
  const [showSuccessLoginUs3, setShowSuccessLoginUs3] = useState(false);
  const [showSuccessLoginUs4, setShowSuccessLoginUs4] = useState(false);
  const [showSuccessLoginUs5, setShowSuccessLoginUs5] = useState(false);

  const [camposEscolta, setCamposEscolta] = useState(false);
  const [veiculoIdPlaca, setVeiculoIdPlaca] = useState<VeiculoIdPlaca[]>([]);
  const [carretaIdPlaca, setCarretaIdPlaca] = useState<CarretaIdPlaca[]>([]);
  const [carreta2IdPlaca, setCarreta2IdPlaca] = useState<Carreta2IdPlaca[]>([]);
  const [bauIdNumero, setBauIdNumero] = useState<BauIdNumero[]>([]);
  const [condutorIdNome, setCondutorIdNome] = useState<CondutorIdNome[]>([]);
  const [ajudanteIdNome, setAjudanteIdNome] = useState<AjudanteIdNome[]>([]);

  const [rotaIdNome, setRotaIdNome] = useState<RotaIdNome[]>([]);
  const [veiculo, setVeiculo] = useState<VeiculoDadosSM>();
  const [carreta, setCarreta] = useState<CarretaDadosSM>();
  const [carreta2, setCarreta2] = useState<Carreta2DadosSM>();
  const [bau, setBau] = useState<BauIntercambiavelDadosSM>();
  const [embarcador, setEmbarcador] = useState<EmbarcadorDadosSM | null>(null);
  const [tipoOperacaoSelecionada, setTipoOperacaoSelecionada] = useState('');
  const [selectedRota, setSelectedRota] = useState<RotaIdNome>();

  const [tipoComunicacaoIsca, setTipoComunicacaoIsca] = useState<TipoComunicacaoIdNome[]>([]);
  const [tipoComunicacaoEscolta, setTipoComunicacaoEscolta] = useState<TipoComunicacaoIdNome[]>([]);
  const [listaTiposOperacoesCliente, setListaTiposOperacoesCliente] = useState<
    TipoOperacoesCliente[]
  >([]);

  const [flagObrigatoriedadeIsca, setFlagObrigatoriedadeIsca] = useState(false);
  const [flagObrigatoriedadeEscolta, setFlagObrigatoriedadeEscolta] = useState(false);

  const [erroPonto, setErroPonto] = useState(false);

  const [mostrarCamposLoginSenha, setMostrarCamposLoginSenha] = useState(false);
  const [mostrarCamposLoginSenha2, setMostrarCamposLoginSenha2] = useState(false);
  const [mostrarCamposLoginSenha3, setMostrarCamposLoginSenha3] = useState(false);
  const [mostrarCamposLoginSenha4, setMostrarCamposLoginSenha4] = useState(false);
  const [mostrarCamposLoginSenha5, setMostrarCamposLoginSenha5] = useState(false);
  const [mostrarCamposEscolta, setMostrarCamposEscolta] = useState(false);
  const [mostrarCamposIsca, setMostrarCamposIsca] = useState(false);
  const [adicionaPontosIntermediarios, setAdicionaPontosIntermediarios] = useState(false);
  const [
    adicionaPontosIntermediariosApartirPontoOrigem,
    setAdicionaPontosIntermediariosApartirPontoOrigem,
  ] = useState(false);

  const [camposIsca, setCamposIsca] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataOrigemMudou, setDataOrigemMudou] = useState(false);
  const [horaOrigemMudou, setHoraOrigemMudou] = useState(false);
  const [erroDataInicial, setErroDataInicial] = useState(false);
  const [erroDataInicial2, setErroDataInicial2] = useState(false);
  const [erroHoraAntecendencia, setErroHoraAntecendencia] = useState(false);
  const [erroHoraInicialFim, setErroHoraInicialFim] = useState(false);
  const [erroHoraPonto, setErroHoraPonto] = useState('');
  const [erroDataPonto, setErroDataPonto] = useState('');
  const [erroDataPontoIntermediario, setErroDataPontoIntermediario] = useState<string[]>([]);
  const [erroPlacaEscolta, setErroPlacaEscolta] = useState(false);
  const [erroDataPontoInterm, setErroDataPontoInterm] = useState<string[]>([]);

  const [erroDataFinal, setErroDataFinal] = useState(false);
  const [erroHoraFinal, setErroHoraFinal] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogErroPesquisa, setIsDialogErroPesquisa] = useState(false);
  const [isDialogErroDistanceMatrix, setIsDialogErroDistanceMatrix] = useState(false);
  const [isDialogErroRecuperarPontos, setIsDialogErroRecuperarPontos] = useState(false);

  const [isDialogVisibleBloqueioChecklist, setIsDialogVisibleBloqueioChecklist] = useState(false);
  const [isDialogVisibleBloqueioSat, setIsDialogVisibleBloqueioSat] = useState(false);
  const [isDialogVisibleUsuarioNaoLogado, setIsDialogVisibleUsuarioNaoLogado] = useState(false);
  const [
    isDialogVisibleVeiculoNaoRastreadoFaturamento,
    setIsDialogVisibleVeiculoNaoRastreadoFaturamento,
  ] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleCadOkEmail, setIsDialogVisibleCadOkEmail] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [isDialogVisibleMsgSmExiste, setIsDialogVisibleMsgSmExiste] = useState(false);
  const [itensLancamento, setItensLancamento] = useState('');
  const [isDialogVisibleMsgVeiculoNaoVigente, setIsDialogVisibleMsgVeiculoNaoVigente] =
    useState(false);
  const [isDialogVisibleMsgCarreta1NaoVigente, setIsDialogVisibleMsgCarreta1NaoVigente] =
    useState(false);
  const [isDialogVisibleMsgCarreta2NaoVigente, setIsDialogVisibleMsgCarreta2NaoVigente] =
    useState(false);
  const [isDialogVisibleMsgCondutorNaoVigente, setIsDialogVisibleMsgCondutorNaoVigente] =
    useState(false);

  const [parametros, setParametros] = useState<ParametroOperacional>();

  const [isVeiculoBloqueado, setIsVeiculoBloqueado] = useState(false);
  const [isCarretaBloqueada, setIsCarretaBloqueada] = useState(false);
  const [isCarreta2Bloqueada, setIsCarreta2Bloqueada] = useState(false);
  const [isCondutorBloqueado, setIsCondutorBloqueado] = useState(false);
  const [isAjudanteBloqueado, setIsAjudanteBloqueado] = useState(false);
  const [isCondutor2Bloqueado, setIsCondutor2Bloqueado] = useState(false);
  const [isAjudante2Bloqueado, setIsAjudante2Bloqueado] = useState(false);

  const [cnhCondutor1Valida, setCnhCondutor1Valida] = useState<boolean | string>('');
  const [cnhCondutor2Valida, setCnhCondutor2Valida] = useState<boolean | string>('');

  const [criticaVei, setCriticaVei] = useState('');
  const [criticaCond, setCriticaCond] = useState('');
  const [criticaAju, setCriticaAju] = useState('');
  const [criticaCar1, setCriticaCar1] = useState('');
  const [criticaCar2, setCriticaCar2] = useState('');
  const [pesquisaVei, setPesquisaVei] = useState('');
  const [pesquisaCond, setPesquisaCond] = useState('');
  const [pesquisaCond2, setPesquisaCond2] = useState('');
  const [pesquisaAju, setPesquisaAju] = useState('');
  const [pesquisaAju2, setPesquisaAju2] = useState('');
  const [pesquisaCar1, setPesquisaCar1] = useState('');
  const [pesquisaCar2, setPesquisaCar2] = useState('');
  const [checklistValidade, setChecklistValidade] = useState('');

  const [cnhCondutor1Invalida, setCnhCondutor1Invalida] = useState(false);
  const [cnhCondutor2Invalida, setCnhCondutor2Invalida] = useState(false);

  const [displayMsgChecklistInexistenteVeiculo, setDisplayMsgChecklistInexistenteVeiculo] =
    useState(false);
  const [displayMsgChecklistInexistenteCarreta, setDisplayMsgChecklistInexistenteCarreta] =
    useState(false);
  const [displayMsgChecklistInexistenteCarreta2, setDisplayMsgChecklistInexistenteCarreta2] =
    useState(false);

  const [displayMsgChecklistInexistenteBau, setDisplayMsgChecklistInexistenteBau] = useState(false);

  const [displayMsgSatExistenteVeiculo, setDisplayMsgSatExistenteVeiculo] = useState(false);
  const [displayMsgSatExistenteCarreta1, setDisplayMsgSatExistenteCarreta1] = useState(false);
  const [displayMsgSatExistenteCarreta2, setDisplayMsgSatExistenteCarreta2] = useState(false);
  const [displayMsgSatExistenteBau, setDisplayMsgSatExistenteBau] = useState(false);

  const [flagSolicitarFrotaNaSM, setFlagSolicitarFrotaNaSM] = useState(false);
  const [flagSolicitarNumeroRemessaNaSM, setFlagSolicitarNumeroRemessaNaSM] = useState(false);
  const [flagCriticarAnaliseFrotaPropriaNaSM, setFlagCriticarAnaliseFrotaPropriaNaSM] =
    useState(false);
  const [flagCriticarAnaliseAgregadoNaSM, setFlagCriticarAnaliseAgregadoNaSM] = useState(false);
  const [flagCriticarAnaliseTerceiroNaSM, setFlagCriticarAnaliseTerceiroNaSM] = useState(false);
  const [flagValidarChecklistPendente, setFlagValidarChecklistPendente] = useState();

  const [flagHabilitaPesquisasConsultasVigentes, setFlagHabilitaPesquisasConsultasVigentes] =
    useState(false);
  const [flagSolicitarOperacaoNaSM, setFlagSolicitarOperacaoNaSM] = useState(false);
  const [flagCriticarAnaliseCondutorAgregadoNaSM, setFlagCriticarAnaliseCondutorAgregadoNaSM] =
    useState(false);
  const [flagCriticarAnaliseCondutorAutonomoNaSM, setFlagCriticarAnaliseCondutorAutonomoNaSM] =
    useState(false);
  const [
    flagCriticarAnaliseCondutorFuncionarioNaSM,
    setFlagCriticarAnaliseCondutorFuncionarioNaSM,
  ] = useState(false);
  const [
    flagCriticarAnaliseNaoMotoristaAgregadoNaSM,
    setFlagCriticarAnaliseNaoMotoristaAgregadoNaSM,
  ] = useState(false);
  const [
    flagCriticarAnaliseNaoMotoristaAutonomoNaSM,
    setFlagCriticarAnaliseNaoMotoristaAutonomoNaSM,
  ] = useState(false);
  const [
    flagCriticarAnaliseNaoMotoristaFuncionarioNaSM,
    setFlagCriticarAnaliseNaoMotoristaFuncionarioNaSM,
  ] = useState(false);

  const [flagSolicitarNumeroIdentificadorNaSM, setFlagSolicitarNumeroIdentificadorNaSM] =
    useState(false);
  const [
    flagServicoMonitoramentoVeiculosContratado,
    setFlagServicoMonitoramentoVeiculosContratado,
  ] = useState(false);
  const [flagServicoRotaPorPontosContratado, setFlagServicoRotaPorPontosContratado] =
    useState(false);

  const [flagAnalisePerfil, setFlagAnalisePerfil] = useState(false);

  const [embarcadorIdRazaoSocial, setEmbarcadorIdRazaoSocial] = useState<EmbarcadorIdRazaoSocial[]>(
    []
  );
  const [tipoOperacaoSMIdDescricao, setTipoOperacaoSMIdDescricao] = useState<
    TipoOperacaoSMIdDescricao[]
  >([]);
  const [tecnologiaRastreamentoIdNome, setTecnologiaRastreamentoIdNome] = useState<
    TecnologiaRastreamentoIdNome[]
  >([]);


  const [dataInicioPreSm, setDataInicioPreSm] = useState('');
  const [horaInicioPreSm, setHoraInicioPreSm] = useState('');
  const [dataFimPreSm, setDataFimPreSm] = useState('');
  const [horaFimPreSm, setHoraFimPreSm] = useState('');
  const [isDataPreSm, setIsDataPreSm] = useState(false);
  const [valorCargaMinimoErro, setValorCargaMinimoErro] = useState(false);

  const [condutorBloqueadoPontos, setCondutorBloqueadoPontos] = useState(false);
  const [veiculoBloqueadoPontos, setVeiculoBloqueadoPontos] = useState(false);
  const [carretaBloqueadoPontos, setCarretaBloqueadoPontos] = useState(false);
  const [carreta2BloqueadoPontos, setCarreta2BloqueadoPontos] = useState(false);

  const [valorCargaChanged, setValorCargaChanged] = useState(false);
  const [validaCondutor, setValidaCondutor] = useState(false);
  const [isSMLoading, setIsSMLoading] = useState(false);

  const [loadingParadas, setLoadingParadas] = useState(false);
  const dataAgora: any = obterDataAtual();

  const [model, setModel] = useState<SolicitacaoMonitoramentoType>({
    idUsuarioCadastro: getCookieSessionData().usuarioVO.usuarioId,
    dataInicio: '',
    horaInicio: '',
    dataFim: '',
    horaFim: '',
    valorCarga: null,
    descricaoCarga: null,
    obsPesquisa: '',
    flagEscolta: false,
    veiculoEscolta: null,
    flagMonitoramentoEscoltaGs: false,
    numeroEquipamentoTecnologiaEscolta: null,
    empresaEscolta: null,
    telEscolta: null,
    agenteEscolta: null,
    agente2Escolta: null,
    telAgente2Escolta: null,
    telAgenteEscolta: null,
    flagIsca: false,
    iscaNumEquipamento: null,
    iscaSite: null,
    iscaSiteLogin: null,
    iscaSiteSenha: null,
    obs: null,
    romaneio: null,
    dataCadastro: nowToZoned(),
    statusVisualizacao: false,
    statusInicializacao: null,
    statusFinalizacao: null,
    motivoFinalizacao: null,
    temperatura: null,
    temperaturaMaxima: null,
    temperaturaMinima: null,
    velocidadeMaxima: null,
    dataInicioReal: null,
    horaInicioReal: null,
    dataFimReal: null,
    horaFimReal: null,
    statusAtivo: true,
    idCliente,
    idVeiculo: null,
    idCarreta: null,
    idCarreta2: null,
    idBau: null,
    idCondutor: null,
    idCondutor2: null,
    idAjudante: null,
    idAjudante2: null,
    idEmbarcador: null,
    idRota: null,
    idSat: null,
    idCheck: null,
    idCar2Check: null,
    idBauCheck: null,
    idBauSat: null,
    idCar2Sat: null,
    idCarCheck: null,
    idCarSat: null,
    idParada: null,
    tipoOperacao: 0,
    idTecnologiaEscolta: '',
    idTipoComunicacaoEscolta: '',
    idTipoComunicacaoIsca: '',
    cancelamento: false,
    dataHoraCancelamento: null,
    idTecnologiaIsca: null,
    idUsuarioCancelamento: null,
    motivoCancelamento: null,
    usuario: null,
    idTipoOperacaoCliente: null,
    solicitarFrotaCliente: '',
    solicitarIdentificadorCliente: '',
    solicitacaoMonitoramentoPlanoViagemVO: [
      {
        onlyDataPonto: '',
        onlyTimePonto: '',
        dataPonto: '',
        idPonto: null,
        idSm: null,
        idSmPv: null,
        idUsuario: 0,
        remessa: '',
        sequencia: 0,
        tipoPonto: '',
      },
    ],
    paradaVO: [
      {
        ativo: true,
        bairro: '',
        cep: '',
        cidade: '',
        complemento: '',
        idCodigoIbge: 0,
        idUf: 0,
        local: '',
        logradouro: '',
        motivo: '',
        numero: 0,
        rotaId: 0,
        sequencia: 0,
        statusAtivo: true,
      },
    ],
  });

  const [isRotaPorPontos, setIsRotaPorPontos] = useState(false);
  const [isRotaAvulsa, setIsRotaAvulsa] = useState(false);
  const [isRotaEmbarcador, setIsRotaEmbarcador] = useState(false);
  const [embarcadorMudou, setEmbarcadorMudou] = useState(false);

  const [cpfErroAjudante, setCpfErroAjudante] = useState(false);
  const [cpfErroAjudante2, setCpfErroAjudante2] = useState(false);
  const [cpfErroCondutor, setCpfErroCondutor] = useState(false);
  const [cpfErroCondutor2, setCpfErroCondutor2] = useState(false);

  const [carretaVisible, setCarretaVisible] = useState(false);
  const [temperaturaVisible, setTemperaturaVisible] = useState(false);
  const [veiculoNaoRastreado, setVeiculoNaoRastreado] = useState(false);
  const [veiculoNaoRastreadoFaturamento, setVeiculoNaoRastreadoFaturamento] = useState(false);
  const [desabilitaForm, setDesabilitaForm] = useState(false);

  const [veiculoID, setVeiculoID] = useState();

  const [loadingDadosSM, setLoadingDadosSM] = useState(false);

  const [idCarreta1, setIdCarreta1] = useState<number | null>(null);
  const [idCarreta2, setIdCarreta2] = useState<number | null>(null);

  const [isClienteEmbarcador, setIsClienteEmbarcador] = useState(false);
  const [embarcadorId, setEmbarcadorId] = useState();
  const [embarcadorId2, setEmbarcadorId2] = useState();
  const [embarcadorPreSM, setEmbarcadorPreSM] = useState();

  const [idPontoPreenchido, setIdPontoPreenchido] = useState(false);
  const [remessaPreenchida, setRemessaPreenchida] = useState(false);
  const [dataPreenchida, setDataPreenchida] = useState(false);
  const [horaPreenchida, setHoraPreenchida] = useState(false);

  const [loadingInsert, setLoadingInsert] = useState(false);
  const [erroValorCarga, setErroValorCarga] = useState(false);
  const [erroTemperaturaMaxima, setErroTemperaturaMaxima] = useState(false);
  const [erroTemperaturaMinima, setErroTemperaturaMinima] = useState(false);
  const [erroVelocidadeMaxima, setErroVelocidadeMaxima] = useState(false);
  const [pontosCliente, setPontosCliente] = useState<ListaPontos[]>([]);
  const [checklistVencido, setChecklistVencido] = useState(false);
  const [checklistVencidoCar1, setChecklistVencidoCar1] = useState(false);
  const [checklistVencidoCar2, setChecklistVencidoCar2] = useState(false);
  const [checklistVencidoBau, setChecklistVencidoBau] = useState(false);
  const [checklistValidadeCar1, setChecklistValidadeCar1] = useState('');
  const [checklistValidadeCar2, setChecklistValidadeCar2] = useState('');
  const [preSmResults, setPreSmResults] = useState<ListaPontosType[]>([]);
  const [isConfirmPreSM, setIsConfirmPreSM] = useState(false);
  const [bloqueioInsertBlacklistAjudante, setBloqueioInsertBlacklistAjudante] = useState(false);
  const [bloqueioInsertBlacklistCondutor, setBloqueioInsertBlacklistCondutor] = useState(false);
  const [condutorID, setCondutorID] = useState();
  const [tipoRota, setTipoRota] = useState<number>(0);
  const [tipoCondutor, setTipoCondutor] = useState<number>(0);
  const [tipoCondutor2, setTipoCondutor2] = useState<number>(0);
  const [tipoAjudante, setTipoAjudante] = useState<number>(0);
  const [tipoAjudante2, setTipoAjudante2] = useState<number>(0);
  const [tipoVeiculo, setTipoVeiculo] = useState<number>(0);
  const [tipoCarreta1, setTipoCarreta1] = useState<number>(0);
  const [tipoCarreta2, setTipoCarreta2] = useState<number>(0);
  const [idSmEnviada, setIdSmEnviada] = useState<any>();

  useEffect(() => {
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }], 'inserir');
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.BAU] }], 'inserir');
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR] }], 'inserir');
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.AJUDANTE] }], 'inserir');
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }], 'inserir');
  }, []);

  useEffect(() => {
    const dependencias = [
      pontosCliente,
      listaTiposOperacoesCliente,
      bauIdNumero,
      veiculoIdPlaca,
      carretaIdPlaca,
      embarcadorIdRazaoSocial,
      condutorIdNome,
      ajudanteIdNome,
      rotaIdNome,
    ];
    if (progress >= 99) {
      setProgressMessage('Pronto!');
      return;
    }
    if (progressMessage === '') {
      setProgressMessage('Carregando...');
    }
    const gap = Math.round(progress + 100 / dependencias.length);
    if (pontosCliente) {
      setProgress(gap);
    }
    if (listaTiposOperacoesCliente) {
      setProgress(gap);
    }
    if (bauIdNumero) {
      setProgress(gap);
    }
    if (veiculoIdPlaca) {
      setProgress(gap);
    }
    if (carretaIdPlaca) {
      setProgress(gap);
    }
    if (embarcadorIdRazaoSocial) {
      setProgress(gap);
    }
    if (condutorIdNome) {
      setProgress(gap);
    }
    if (ajudanteIdNome) {
      setProgress(gap);
    }
    if (rotaIdNome) {
      setProgress(gap);
    }
    if (flagSolicitarFrotaNaSM) {
      setProgress(gap);
    }
    if (servicosContratados) {
      setProgress(gap);
    }
  }, [
    pontosCliente,
    listaTiposOperacoesCliente,
    bauIdNumero,
    veiculoIdPlaca,
    carretaIdPlaca,
    embarcadorIdRazaoSocial,
    condutorIdNome,
    ajudanteIdNome,
    rotaIdNome,
  ]);

  // verifica pontos condutor
  useEffect(() => {
    const verificaPontos = async () => {
      if (model.idCondutor && model.idEmbarcador) {
        const response = await axios.get(
          '/solicitacao-monitoramento/verificar-vigencia-condutor-sm',
          {
            params: {
              idCliente,
              idCondutor: model.idCondutor,
              idEmbarcador: model.idEmbarcador,
            },
          }
        );
        if (response.data) {
          addToast({
            title: 'Erro',
            description: 'Condutor com mais de 30 pontos de não conformidade!',
            type: 'error',
          });
          setCondutorBloqueadoPontos(true);
        } else {
          setCondutorBloqueadoPontos(false);
        }
      }
    };
    verificaPontos();
  }, [model.idCondutor, model.idEmbarcador]);

  // verifica pontos veiculo
  useEffect(() => {
    const handlePontos = async () => {
      if (model.idVeiculo && model.idEmbarcador) {
        try {
          const response = await axios.get(
            '/solicitacao-monitoramento/verificar-vigencia-veiculo-sm',
            {
              params: {
                idCliente,
                idVeiculo: model.idVeiculo,
                idEmbarcador: model.idEmbarcador,
              },
            }
          );
          if (response.data) {
            addToast({
              title: 'Erro',
              description: 'Veículo com mais de 30 pontos de não conformidade!',
              type: 'error',
            });
            setVeiculoBloqueadoPontos(true);
          } else {
            setVeiculoBloqueadoPontos(false);
          }
        } catch (error) {
          setVeiculoBloqueadoPontos(false);
        }
      } else {
        setVeiculoBloqueadoPontos(false);
      }
    };
    handlePontos();
  }, [model.idVeiculo, model.idEmbarcador]);
  // verifica pontos carreta1
  useEffect(() => {
    const handlePontos = async () => {
      if (model.idCarreta && model.idEmbarcador) {
        const response = await axios.get(
          '/solicitacao-monitoramento/verificar-vigencia-veiculo-sm',
          {
            params: {
              idCliente,
              idVeiculo: model.idCarreta,
              idEmbarcador: model.idEmbarcador,
            },
          }
        );
        if (response.data) {
          addToast({
            title: 'Erro',
            description: 'Carreta(1) com mais de 30 pontos de não conformidade!',
            type: 'error',
          });
          setCarretaBloqueadoPontos(true);
        } else {
          setCarretaBloqueadoPontos(false);
        }
      }
    };
    handlePontos();
  }, [model.idCarreta, model.idEmbarcador]);
  // verifica pontos carreta2
  useEffect(() => {
    const handlePontos = async () => {
      if (model.idCarreta2 && model.idEmbarcador) {
        const response = await axios.get(
          '/solicitacao-monitoramento/verificar-vigencia-veiculo-sm',
          {
            params: {
              idCliente,
              idVeiculo: model.idCarreta2,
              idEmbarcador: model.idEmbarcador,
            },
          }
        );
        if (response.data) {
          addToast({
            title: 'Erro',
            description: 'Carreta(2) com mais de 30 pontos de não conformidade!',
            type: 'error',
          });
          setCarreta2BloqueadoPontos(true);
        } else {
          setCarreta2BloqueadoPontos(false);
        }
      }
    };
    handlePontos();
  }, [model.idCarreta2, model.idEmbarcador]);

  const confirmacaoLoginUsuario = (usuario: string) => {
    if (usuario === 'usuario1') {
      setMostrarCamposLoginSenha(false);
      setShowSuccessLoginUs(true);
    } else if (usuario === 'usuario2') {
      setMostrarCamposLoginSenha2(false);
      setShowSuccessLoginUs2(true);
    } else if (usuario === 'usuario3') {
      setMostrarCamposLoginSenha3(false);
      setShowSuccessLoginUs3(true);
    } else if (usuario === 'usuario4') {
      setMostrarCamposLoginSenha4(false);
      setShowSuccessLoginUs4(true);
    } else {
      setMostrarCamposLoginSenha5(false);
      setShowSuccessLoginUs5(true);
    }
  };

  const confirmacaoErroLoginUsuario = (usuario: string) => {
    if (usuario === 'usuario1') {
      setErroLoginUs(true);
    } else if (usuario === 'usuario2') {
      setErroLoginUs2(true);
    } else if (usuario === 'usuario3') {
      setErroLoginUs3(true);
    } else if (usuario === 'usuario4') {
      setErroLoginUs4(true);
    } else {
      setErroLoginUs5(true);
    }
  };

  async function handleConfirmLogin(
    usuario: string,
    login: LoginUser,
    role: string,
    codigo: number,
    setUsuario: React.Dispatch<React.SetStateAction<LoginUser>>
  ) {
    setLoading(true);
    if (login.usuario === getCookieSessionData().usuarioVO.login) {
      const loginResponse = await loginService({
        login: login.usuario,
        senha: login.senha,
        role,
        codigo,
      });

      if (typeof loginResponse === 'string') {
        confirmacaoErroLoginUsuario(usuario);
        setLoading(false);
        return;
      }

      setUsuario((prevState) => ({ ...prevState, isLogado: true }));
      confirmacaoLoginUsuario(usuario);
      setLoading(false);
    } else {
      confirmacaoErroLoginUsuario(usuario);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/solicitacao-monitoramento/${id}`);
        if (response.data.length === 0) {
          setIsDataPreSm(false);
        } else {
          setIsDataPreSm(true);
          setDataInicioPreSm(response.data.dataInicio);
          setHoraInicioPreSm(response.data.horaInicio);
          setHoraFimPreSm(response.data.horaFim);
          setDataFimPreSm(response.data.dataFim);
        }
      } catch (err: any) {
        if (err.response) {
          const res = await axios.get(
            `/solicitacao-monitoramento/listardadossmpendente?solicitacaoMonitoramento=${id}`
          );
          setDataInicioPreSm(res.data.dataInicio);
          setHoraInicioPreSm(res.data.horaInicio);
          setHoraFimPreSm(res.data.horaFim);
          setDataFimPreSm(res.data.dataFim);
          setEmbarcadorPreSM(res.data.idEmbarcador);
          loadDadosEmbarcador(res.data.idEmbarcador);
        }
      }
    };
    if (id !== '0') {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (preSmResults.length) {
      setModel({
        ...model,
        dataInicio: dataInicioPreSm.toString().split('T')[0],
        dataFim: dataFimPreSm.toString().split('T')[0],
        horaFim: `${horaFimPreSm.toString().split(/[T,.]/)[1]}`,
        horaInicio: `${horaInicioPreSm.toString().split(/[T,.]/)[1]}`,
        idEmbarcador: embarcadorPreSM,
      });
    }
  }, [
    dataInicioPreSm,
    dataFimPreSm,
    horaFimPreSm,
    horaInicioPreSm,
    model.idVeiculo,
    embarcadorPreSM,
  ]);

  let flagBloqueioSAT: null | number = null;
  let flagChecklistPendente: null | number = null;

  const getParticularidades = async () => {
    try {
      const response = await axios.get(`/cliente/getParticularidadesCliente`, {
        params: {
          cliente: idCliente,
        },
      });
      setProgressMessage('Carregando particularidades...');
      setFlagSolicitarFrotaNaSM(response.data.flagSolicitarFrotaNaSM);
      setFlagSolicitarNumeroIdentificadorNaSM(response.data.flagSolicitarNumeroIdentificadorNaSM);
      setFlagSolicitarOperacaoNaSM(response.data.flagSolicitarOperacaoNaSM);

      flagBloqueioSAT = response.data.flagBloqueioSATNaSM;
      flagChecklistPendente = response.data.flagValidarChecklistPendente;
      setFlagValidarChecklistPendente(response.data.flagValidarChecklistPendente);
      setFlagCriticarAnaliseFrotaPropriaNaSM(response.data.flagCritiarAnaliseFrotaPropriaNaSM);
      setFlagCriticarAnaliseAgregadoNaSM(response.data.flagCriticarAnaliseAgregadoNaSM);
      setFlagCriticarAnaliseTerceiroNaSM(response.data.flagCriticarAnaliseTerceiroNaSM);
      setFlagSolicitarNumeroRemessaNaSM(response.data.flagSolicitarNumeroRemessaNaSM);
      setFlagCriticarAnaliseCondutorAgregadoNaSM(
        response.data.flagCriticarAnaliseCondutorAgregadoNaSM
      );
      setFlagCriticarAnaliseCondutorAutonomoNaSM(
        response.data.flagCriticarAnaliseCondutorAutonomoNaSM
      );
      setFlagCriticarAnaliseCondutorFuncionarioNaSM(
        response.data.flagCriticarAnaliseCondutorFuncionarioNaSM
      );
      setFlagCriticarAnaliseNaoMotoristaAgregadoNaSM(
        response.data.flagCriticarAnaliseNaoMotoristaAgregadoNaSM
      );
      setFlagCriticarAnaliseNaoMotoristaAutonomoNaSM(
        response.data.flagCriticarAnaliseNaoMotoristaAutonomoNaSM
      );
      setFlagCriticarAnaliseNaoMotoristaFuncionarioNaSM(
        response.data.flagCriticarAnaliseNaoMotoristaNaSM
      );
      setFlagHabilitaPesquisasConsultasVigentes(
        response.data.flagHabilitaPesquisasConsultasVigentes
      );
    } catch (err: any) {}
  };

  useEffect(() => {
    getParticularidades();
  }, []);

  useEffect(() => {
    const getServicosContratados = async () => {
      try {
        const response = await axios.get('/cliente/getServicosContratadosCliente', {
          params: {
            cliente: idCliente,
          },
        });

        setProgressMessage('Carregando serviços contratados...');
        setServicosContratados(response.data);
        setFlagServicoMonitoramentoVeiculosContratado(response.data.cscNuMonitoramentoVeiculos);
        setFlagAnalisePerfil(response.data.cscNuCadastroConsulta === 1);
        setModel({
          ...model,
          obsPesquisa:
            response.data.cscNuCadastroConsulta === 1
              ? ''
              : `Análise de Perfil não realizada pela GS. Favor verificar se este(s) condutor(es), ajudante(s), veículo e/ou carreta possui liberação com outra Gerenciadora dentro da validade da viagem atual.`,
        });
        setFlagServicoRotaPorPontosContratado(response.data.cscNuRotaPorPontos === 1);
      } catch (err: any) {}
    };
    getServicosContratados();
  }, []);

  const [vehicleTemperatureVinculed, setVehicleTemperatureVincule] = useState(false)
  const [vehicleSpeedVinculed, setVehicleSpeedVincule] = useState(false)

  useEffect(() => {
    if (!model.idVeiculo) return
    const getVeiculoVinculo = async () => {
      try {

        setVehicleTemperatureVincule(false)
        setVehicleSpeedVincule(false)

        const veiculo = veiculoIdPlaca.find((data: { id: number; placa: string }) => data.id === model.idVeiculo)

        const temperatura = await axios.get('/vehicle-control-temperature/', {
          params: {
            page_size: 1,
            cliente: idCliente,
            page: 1,
            placa: veiculo?.placa,
            temperature: true
          },
        });

        const velocidade = await axios.get('/vehicle-control-speed/', {
          params: {
            page_size: 1,
            cliente: idCliente,
            page: 1,
            placa: veiculo?.placa,
            speed: true
          },
        });

        if (!temperatura.data.results.length) {
          setVehicleTemperatureVincule(false)
        } else {
          setVehicleTemperatureVincule(true)
        }

        if (!velocidade.data.results.length) {
          setVehicleSpeedVincule(false)
        } else {
          setVehicleSpeedVincule(true)
        }

      } catch (err: any) {}
    };

    getVeiculoVinculo();
  }, [model.idVeiculo]);


  const loadVeiculos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/veiculo/listarIdEPlaca?cliente=${idCliente}&grupoMacroVeículo=1`
      );
      setVeiculoIdPlaca(response.data);
      setProgressMessage('Carregando veiculos...');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVeiculos();
  }, [setVeiculoIdPlaca, idCliente]);

  useEffect(() => {
    loadCarretas2();
  }, [setCarreta2IdPlaca, idCliente]);

  useEffect(() => {
    const listaOperacoesCliente = async () => {
      try {
        const response = await axios.get(`/cliente/getTipoOperacaoCliente`);
        setListaTiposOperacoesCliente(response.data);
        setProgressMessage('Carregando operações...');
      } catch (err: any) {}
    };

    listaOperacoesCliente();
  }, []);

  useEffect(() => {
    const loadCarretas = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/veiculo/listarIdEPlaca?cliente=${idCliente}&grupoMacroVeículo=2`
        );
        setProgressMessage('Carregando carretas...');
        setCarretaIdPlaca(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    loadCarretas();
  }, [setCarretaIdPlaca, idCliente]);

  const loadCarretas2 = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/veiculo/listarIdEPlaca?cliente=${idCliente}&grupoMacroVeículo=2`
      );
      setCarreta2IdPlaca(response.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const loadBau = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/bau-intercambiavel/listaIdENumero?cliente=${idCliente}`);
      setBauIdNumero(response.data);
      setProgressMessage('Carregando baús...');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBau();
  }, [setBauIdNumero, idCliente]);

  useEffect(() => {
    const loadDadosEmbarcadorIdRazaoSocial = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/embarcador/listarIdRazaoSocialEmbarcador?cliente=${idCliente}`
        );
        setEmbarcadorIdRazaoSocial(response.data);
        setProgressMessage('Carregando embarcadores...');
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    loadDadosEmbarcadorIdRazaoSocial();
  }, [idCliente, setEmbarcadorIdRazaoSocial]);

  const loadDadosCondutorIdNome = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/condutor/listarCondutorNome?cliente=${idCliente}`);
      setCondutorIdNome(response.data);
      setProgressMessage('Carregando condutores...');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDadosCondutorIdNome();
  }, [setCondutorIdNome, idCliente]);

  const loadDadosAjudanteIdNome = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/ajudante-funcionario/listarAjudanteNome?cliente=${idCliente}`
      );

      setAjudanteIdNome(response.data);
      setProgressMessage('Carregando ajudantes...');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDadosAjudanteIdNome();
  }, [setAjudanteIdNome, idCliente]);

  useEffect(() => {
    const loadDadosTipoOperacaoSMIdDescricao = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/tipo-operacao-sm/listarTipoOperacaoSM');
        setTipoOperacaoSMIdDescricao(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    loadDadosTipoOperacaoSMIdDescricao();
  }, [setTipoOperacaoSMIdDescricao]);

  useEffect(() => {
    const loadDadosTecnologiaRastreamentoIdNome = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/tecnologia-rastreamento/listarIdNome');
        setTecnologiaRastreamentoIdNome(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    loadDadosTecnologiaRastreamentoIdNome();
  }, [setTecnologiaRastreamentoIdNome]);

  const loadDadosRotaIdNome = async (data?: any) => {
    setLoading(true);
    try {
      const response = await axios.get(`/rota/listarIdNome?cliente=${idCliente}`);
      if (isRotaAvulsa) {
        const { nome, cepOrigem, cepDestino, numeroOrigem, numeroDestino } = data;
        const _avulsa = response.data.find(
          (item: any) =>
            item.nome === nome &&
            item.cepOrigem === cepOrigem &&
            item.numeroOrigem === Number(numeroOrigem) &&
            item.cepDestino === cepDestino &&
            item.numeroDestino === Number(numeroDestino)
        );
        setSelectedRota(_avulsa);
        setRotaIdNome([_avulsa]);
        setModel({ ...model, idRota: _avulsa.id });
        return;
      }
      setRotaIdNome(response.data.filter((item: any) => !item.rotaAvulsa));
      setProgressMessage('Carregando rotas...');
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isRotaAvulsa) {
      return;
    }
    loadDadosRotaIdNome();
  }, [setRotaIdNome, tipoRota, idCliente]);

  useEffect(() => {
    const rota = rotaIdNome.find((each) => each.id === model.idRota);
    if (!rota) {
      setSelectedRota(undefined);
    }
    setSelectedRota(rota);
  }, [model.idRota, rotaIdNome]);

  useEffect(() => {
    const fetchParametros = async () => {
      const response = await axios.get('/parametro-operacional');
      setParametros(response.data);
    };
    fetchParametros();
  }, []);

  const handleMostrarCamposLoginSenha = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCamposLoginSenha(selectedOption.target.value === 'Sim');
  };

  const handleMostrarCamposLoginSenha2 = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCamposLoginSenha2(selectedOption.target.value === 'Sim');
  };

  const handleMostrarCamposLoginSenha3 = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCamposLoginSenha3(selectedOption.target.value === 'Sim');
  };

  const handleMostrarCamposLoginSenha4 = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCamposLoginSenha4(selectedOption.target.value === 'Sim');
  };

  const handleMostrarCamposLoginSenha5 = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setMostrarCamposLoginSenha5(selectedOption.target.value === 'Sim');
  };

  const handleSelecionarCamposEscolta = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedOption.target.value === '1') {
      setModel({ ...model, flagEscolta: true });
      setCamposEscolta(true);
    } else {
      setModel({ ...model, flagEscolta: false });
      setCamposEscolta(false);
    }
  };

  const handleMostrarCamposIsca = () => {
    if (mostrarCamposIsca === true) {
      setModel({ ...model, flagIsca: true });
    } else {
      setModel({ ...model, flagIsca: false });
    }
  };

  const handleSelecionarCamposIsca = (selectedOption: any) => {
    if (selectedOption.target.value === '1') {
      setModel({ ...model, flagIsca: true });
      setCamposIsca(true);
    } else {
      setModel({ ...model, flagIsca: false });
      setCamposIsca(false);
    }
  };

  /* Função para atualizar o model de acordo com os valores inseridos/modificados no form */
  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      [e.target.name]: e.target.value,
    });
  }

  const isPontosNull = (d: any) => {
    const pontos = d.filter(
      (each: any) => each.idPonto && (!each.onlyDataPonto || !each.onlyTimePonto)
    );
    return !!pontos.length;
  };

  /* Função para submeter o form */
  const insertSM = async (event: any) => {
    event.preventDefault();

    if (condutorBloqueadoPontos) {
      addToast({
        title: 'Erro',
        description: 'Condutor com mais de 30 pontos de não conformidade!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    if (veiculoBloqueadoPontos) {
      addToast({
        title: 'Erro',
        description: 'Veículo com mais de 30 pontos de não conformidade!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    if (carretaBloqueadoPontos) {
      addToast({
        title: 'Erro',
        description: 'Carreta(1) com mais de 30 pontos de não conformidade!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    if (carreta2BloqueadoPontos) {
      addToast({
        title: 'Erro',
        description: 'Carreta(2) com mais de 30 pontos de não conformidade!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (cnhCondutor1Invalida) {
      addToast({
        title: 'Erro',
        description: 'Condutor(1) com CNH vencida.',
        type: 'error',
      });
      return;
    }

    if (cnhCondutor2Invalida) {
      addToast({
        title: 'Erro',
        description: 'Condutor(2) com CNH vencida.',
        type: 'error',
      });
      return;
    }

    // Críticas de pesquisa

    const itensPesquisas = [
      pesquisaVei,
      pesquisaCar1,
      pesquisaCar2,
      pesquisaCond,
      pesquisaCond2,
      pesquisaAju,
      pesquisaAju2,
    ];
    const filtered = itensPesquisas.filter((each: any) => each.trim() != '');
    if (filtered.length > 1) {
      addToast({
        title: 'Erro',
        description: 'Um item ou mais não possui pesquisa ou possui pesquisa vencida.',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    if (filtered.length === 1) {
      const pesquisaValida = filtered.find((e: any) => e.trim() != '');
      addToast({
        title: 'Erro',
        description: pesquisaValida,
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (bloqueioInsertBlacklistAjudante === true) {
      addToast({
        title: 'Erro',
        description: 'Ajudante cadastrado na Blacklist!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (bloqueioInsertBlacklistCondutor === true) {
      addToast({
        title: 'Erro',
        description: 'Condutor cadastrado na Blacklist!',
        type: 'error',
      });
      event.stopPropagation();

      return;
    }

    if (itensLancamento.trim() != '') {
      setIsDialogVisibleMsgSmExiste(true);
      event.stopPropagation();
      return;
    }
    setIsDialogVisibleMsgSmExiste(false);

    if (flagChecklistPendente === 1 || flagBloqueioSAT === 1) {
      if (
        displayMsgChecklistInexistenteVeiculo ||
        displayMsgSatExistenteBau ||
        displayMsgSatExistenteCarreta1 ||
        displayMsgSatExistenteCarreta2 ||
        displayMsgSatExistenteVeiculo ||
        displayMsgChecklistInexistenteBau ||
        displayMsgChecklistInexistenteCarreta ||
        displayMsgChecklistInexistenteCarreta2
      ) {
        addToast({
          title: 'Erro',
          description: `${
            flagBloqueioSAT ? 'SAT existente' : 'CheckList inexistente'
          } . Esta viagem não é recomendada pela Golden Service!`,
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }
    if (displayMsgChecklistInexistenteVeiculo || displayMsgSatExistenteVeiculo) {
      if (!mostrarCamposLoginSenha) {
        if (usuarioLogado.isLogado === true) {
          setIsDialogVisibleUsuarioNaoLogado(false);
        } else {
          setIsDialogVisibleUsuarioNaoLogado(true);
          event.stopPropagation();
          return;
        }
      } else if (usuarioLogado.isLogado === true) {
        setIsDialogVisibleUsuarioNaoLogado(false);
      } else {
        setIsDialogVisibleUsuarioNaoLogado(true);
        event.stopPropagation();
        return;
      }
    }

    if (displayMsgChecklistInexistenteCarreta || displayMsgSatExistenteCarreta1) {
      if (!mostrarCamposLoginSenha2) {
        if (usuarioLogado2.isLogado === true) {
          setIsDialogVisibleUsuarioNaoLogado(false);
        } else {
          setIsDialogVisibleUsuarioNaoLogado(true);
          event.stopPropagation();
          return;
        }
      } else if (usuarioLogado2.isLogado === true) {
        setIsDialogVisibleUsuarioNaoLogado(false);
      } else {
        setIsDialogVisibleUsuarioNaoLogado(true);
        event.stopPropagation();
        return;
      }
    }

    if (displayMsgChecklistInexistenteCarreta2 || displayMsgSatExistenteCarreta2) {
      if (!mostrarCamposLoginSenha3) {
        if (usuarioLogado3.isLogado === true) {
          setIsDialogVisibleUsuarioNaoLogado(false);
        } else {
          setIsDialogVisibleUsuarioNaoLogado(true);
          event.stopPropagation();
          return;
        }
      } else if (usuarioLogado3.isLogado === true) {
        setIsDialogVisibleUsuarioNaoLogado(false);
      } else {
        setIsDialogVisibleUsuarioNaoLogado(true);
        event.stopPropagation();
        return;
      }
    }
    if (checklistVencido || checklistVencidoCar1 || checklistVencidoCar2) {
      if (!mostrarCamposLoginSenha4) {
        if (usuarioLogado4.isLogado === true) {
          setIsDialogVisibleUsuarioNaoLogado(false);
        } else {
          setIsDialogVisibleUsuarioNaoLogado(true);
          event.stopPropagation();
          return;
        }
      } else if (usuarioLogado4.isLogado === true) {
        setIsDialogVisibleUsuarioNaoLogado(false);
      } else {
        setIsDialogVisibleUsuarioNaoLogado(true);
        event.stopPropagation();
        return;
      }
    }

    if (displayMsgSatExistenteBau || displayMsgChecklistInexistenteBau) {
      if (!mostrarCamposLoginSenha5) {
        if (usuarioLogado5.isLogado === true) {
          setIsDialogVisibleUsuarioNaoLogado(false);
        } else {
          setIsDialogVisibleUsuarioNaoLogado(true);
          event.stopPropagation();
          return;
        }
      } else if (usuarioLogado5.isLogado === true) {
        setIsDialogVisibleUsuarioNaoLogado(false);
      } else {
        setIsDialogVisibleUsuarioNaoLogado(true);
        event.stopPropagation();
        return;
      }
    }

    // SE TEM ESCOLTA, DEVE SER PREENCHIDO
    if (mostrarCamposEscolta) {
      if (
        erroPlacaEscolta ||
        !model.veiculoEscolta ||
        !model.numeroEquipamentoTecnologiaEscolta ||
        !model.empresaEscolta ||
        !model.agenteEscolta ||
        !model.telAgenteEscolta ||
        !model.telEscolta
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    // SE TEM ESCOLTA, DEVE SER PREENCHIDO
    if (mostrarCamposIsca) {
      if (
        !model.idTecnologiaIsca ||
        !model.idTipoComunicacaoIsca ||
        !model.iscaNumEquipamento ||
        !model.iscaSite ||
        !model.iscaSiteLogin ||
        !model.iscaSiteSenha
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (desabilitaForm) {
      addToast({
        title: 'Erro',
        description: 'Checklist Pendente',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (model.valorCarga === null) {
      setValorCargaChanged(true);
    }
    if (!preSmResults.length) {
      if (isRotaPorPontos) {
        if (
          !model.solicitacaoMonitoramentoPlanoViagemVO[0].onlyDataPonto ||
          !model.solicitacaoMonitoramentoPlanoViagemVO[0].onlyTimePonto ||
          !model.solicitacaoMonitoramentoPlanoViagemVO[0].idPonto
        ) {
          addToast({
            title: 'Erro',
            description: 'Rota por Pontos precisa ter Ponto Origem e Ponto Final. Adicione Pontos!',
            type: 'error',
          });
          setIsFormInvalid(true);
          event.stopPropagation();
          return;
        }
        if (
          isPontosNull(model.solicitacaoMonitoramentoPlanoViagemVO) ||
          erroDataPontoIntermediario.filter((e: any) => e).length > 0 ||
          erroDataPonto != ''
        ) {
          addToast({
            title: 'Erro',
            description: 'Verifique o preenchimento dos campos obrigatórios!',
            type: 'error',
          });
          setIsFormInvalid(true);
          event.stopPropagation();
          return;
        }
      } else if (!model.idRota) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios!',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }

      if (adicionaPontosIntermediarios) {
        if (flagSolicitarNumeroRemessaNaSM) {
          if (
            !model.idVeiculo ||
            !model.dataInicio ||
            !model.horaInicio ||
            !model.dataFim ||
            !model.horaFim ||
            !model.idEmbarcador ||
            !model.idCondutor ||
            !model.tipoOperacao ||
            !model.descricaoCarga ||
            isCondutorBloqueado ||
            isVeiculoBloqueado ||
            isCarretaBloqueada ||
            isCarreta2Bloqueada ||
            isAjudanteBloqueado ||
            model.valorCarga === 0 ||
            model.valorCarga === null ||
            !model.valorCarga ||
            valorCargaMinimoErro ||
            !pontoOrigem.idPonto ||
            !pontoOrigem.onlyDataPonto ||
            !pontoOrigem.onlyTimePonto ||
            !pontoOrigem.remessa ||
            !remessaPreenchida ||
            erroValorCarga
          ) {
            addToast({
              title: 'Erro',
              description: 'Verifique o preenchimento dos campos obrigatórios',
              type: 'error',
            });
            setIsFormInvalid(true);
            event.stopPropagation();
            return;
          }
        } else if (
          !model.idVeiculo ||
          !model.dataInicio ||
          !model.horaInicio ||
          !model.dataFim ||
          !model.horaFim ||
          !model.idEmbarcador ||
          !model.idCondutor ||
          !model.tipoOperacao ||
          !model.descricaoCarga ||
          isCondutorBloqueado ||
          isVeiculoBloqueado ||
          isCarretaBloqueada ||
          isCarreta2Bloqueada ||
          isAjudanteBloqueado ||
          model.valorCarga === 0 ||
          model.valorCarga === null ||
          !model.valorCarga ||
          valorCargaMinimoErro ||
          !pontoOrigem.idPonto ||
          !pontoOrigem.onlyDataPonto ||
          !pontoOrigem.onlyTimePonto ||
          erroValorCarga
        ) {
          addToast({
            title: 'Erro',
            description: 'Verifique o preenchimento dos campos obrigatórios',
            type: 'error',
          });
          setIsFormInvalid(true);
          event.stopPropagation();
          return;
        }
      } else if (flagSolicitarNumeroRemessaNaSM) {
        if (
          !model.idVeiculo ||
          !model.dataInicio ||
          !model.horaInicio ||
          !model.dataFim ||
          !model.horaFim ||
          !model.idEmbarcador ||
          !model.idCondutor ||
          !model.tipoOperacao ||
          !model.descricaoCarga ||
          isCondutorBloqueado ||
          isVeiculoBloqueado ||
          isCarretaBloqueada ||
          isCarreta2Bloqueada ||
          isAjudanteBloqueado ||
          model.valorCarga === 0 ||
          model.valorCarga === null ||
          !model.valorCarga ||
          valorCargaMinimoErro ||
          !pontoOrigem.idPonto ||
          !pontoOrigem.onlyDataPonto ||
          !pontoOrigem.onlyTimePonto ||
          !pontoOrigem.remessa ||
          erroValorCarga
        ) {
          addToast({
            title: 'Erro',
            description: 'Verifique o preenchimento dos campos obrigatórios',
            type: 'error',
          });
          setIsFormInvalid(true);
          event.stopPropagation();
          return;
        }
      } else if (
        !model.idVeiculo ||
        !model.dataInicio ||
        !model.horaInicio ||
        !model.dataFim ||
        !model.horaFim ||
        !model.idEmbarcador ||
        !model.idCondutor ||
        !model.tipoOperacao ||
        !model.descricaoCarga ||
        isCondutorBloqueado ||
        isVeiculoBloqueado ||
        isCarretaBloqueada ||
        isCarreta2Bloqueada ||
        isAjudanteBloqueado ||
        !model.valorCarga ||
        model.valorCarga === 0 ||
        model.valorCarga === null ||
        valorCargaMinimoErro ||
        (isRotaPorPontos &&
          (!pontoOrigem.idPonto || !pontoOrigem.onlyDataPonto || !pontoOrigem.onlyTimePonto)) ||
        erroValorCarga
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else if (preSmResults.length) {
      if (
        !model.idVeiculo ||
        !model.dataInicio ||
        !model.horaInicio ||
        !model.dataFim ||
        !model.horaFim ||
        !model.idEmbarcador ||
        !model.idCondutor ||
        !model.tipoOperacao ||
        !model.descricaoCarga ||
        isCondutorBloqueado ||
        isVeiculoBloqueado ||
        isCarretaBloqueada ||
        isCarreta2Bloqueada ||
        isAjudanteBloqueado ||
        model.valorCarga === 0 ||
        model.valorCarga === null ||
        !model.valorCarga ||
        valorCargaMinimoErro ||
        erroValorCarga
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else if (
      !model.idVeiculo ||
      !model.dataInicio ||
      !model.horaInicio ||
      !model.dataFim ||
      !model.horaFim ||
      !model.idEmbarcador ||
      !model.idCondutor ||
      !model.tipoOperacao ||
      !model.descricaoCarga ||
      isCondutorBloqueado ||
      isVeiculoBloqueado ||
      isCarretaBloqueada ||
      isCarreta2Bloqueada ||
      isAjudanteBloqueado ||
      !model.valorCarga ||
      model.valorCarga === 0 ||
      model.valorCarga === null ||
      valorCargaMinimoErro ||
      erroValorCarga
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });

      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (
      flagSolicitarOperacaoNaSM ||
      flagSolicitarNumeroIdentificadorNaSM ||
      flagSolicitarFrotaNaSM
    ) {
      if (
        !model.idTipoOperacaoCliente ||
        !model.solicitarFrotaCliente ||
        !model.solicitarIdentificadorCliente
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (temperaturaVisible) {
      if (servicosContratados?.controleTemperatura == 1 && vehicleTemperatureVinculed) {
        if (
          !model.temperaturaMaxima ||
          !model.temperaturaMinima ||
          erroTemperaturaMaxima ||
          erroTemperaturaMinima
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique o preenchimento dos campos obrigatórios',
            type: 'error',
          });
          setIsFormInvalid(true);
          event.stopPropagation();
          return;
        }
      }
      // else if (!model.temperatura) {
      //   addToast({
      //     title: 'Erro!',
      //     description: 'Verifique o preenchimento dos campos obrigatórios',
      //     type: 'error',
      //   });
      //   setIsFormInvalid(true);
      //   event.stopPropagation();
      //   return;
      // }
    }

    if (carretaVisible) {
      if (!model.idCarreta) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (veiculoNaoRastreado) {
      addToast({
        title: 'Erro',
        description: 'Veículo sem rastreador cadastrado.',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    if (veiculoNaoRastreadoFaturamento) {
      setIsDialogVisibleVeiculoNaoRastreadoFaturamento(true);
      event.stopPropagation();
      return;
    }

    const pontosArray = model.solicitacaoMonitoramentoPlanoViagemVO;

    const pontosArrayAtualizado = pontosArray.map((ponto, index) => ({
      ...ponto,
      dataPonto: dataHoraConverter(ponto.onlyDataPonto, ponto.onlyTimePonto),
      idUsuario,
      tipoPonto: 'I',
      sequencia: index + 2,
    }));

    const ultimoObjetoArray = pontosArrayAtualizado.pop();

    if (!model.idRota) {
      var paradasArray = model.paradaVO;
      paradasArray.shift();
    } else if (model.paradaVO[0] === undefined) {
      var paradasArray = model.paradaVO;
      paradasArray.shift();
    } else {
      var paradasArray = model.paradaVO;
    }
    const paradasArrayAtualizado = paradasArray.map((parada, index) => ({
      ...parada,
    }));
    let data = null;

    if (isRotaPorPontos) {
      data = {
        ...model,
        flagEscolta: (flagObrigatoriedadeEscolta && mostrarCamposEscolta) || camposEscolta,
        flagIsca: (flagObrigatoriedadeIsca && flagObrigatoriedadeIsca) || camposIsca,
        idCheck: idChecklist,
        idSat: satId,
        idCarCheck: idChecklistCarreta,
        idCarSat: satIdCarreta,
        idCar2Check: idChecklistCarreta2,
        idCar2Sat: satIdCarreta2,
        idBauSat: satIdBau,
        idBauCheck: idChecklistBau,
        dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        dataFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        horaInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        horaFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        solicitacaoMonitoramentoPlanoViagemVO: [
          {
            ...pontoOrigem,
            dataPonto: dataHoraConverter(
              dataOrigemMudou ? pontoOrigem.onlyDataPonto : model.dataInicio,
              horaOrigemMudou ? pontoOrigem.onlyTimePonto : model.horaInicio
            ),
            tipoPonto: 'O',
            sequencia: 1,
          },

          ...pontosArrayAtualizado,
          {
            ...ultimoObjetoArray,
            tipoPonto: 'D',
          },
        ],
        paradaVO: [...paradasArrayAtualizado],
      };
    } else if (preSmResults) {
      const arrayPontosPreSm: any[] = [];
      preSmResults.forEach((each: any, index: number) => {
        arrayPontosPreSm.push({
          dataPonto: dataConverter(each.agendamento?.slice(0, 16)),
          idPonto: each.idPonto,
          idSm: +id,
          idSmPv: null,
          idUsuario,
          remessa: each.remessa,
          sequencia: each.sequencia,
          tipoPonto: index === 0 ? 'O' : index === preSmResults.length - 1 ? 'D' : 'I',
        });
      });
      data = {
        ...model,
        flagEscolta: (flagObrigatoriedadeEscolta && mostrarCamposEscolta) || camposEscolta,
        flagIsca: (flagObrigatoriedadeIsca && flagObrigatoriedadeIsca) || camposIsca,
        idCheck: idChecklist,
        idSat: satId,
        idCarCheck: idChecklistCarreta,
        idCarSat: satIdCarreta,
        idCar2Check: idChecklistCarreta2,
        idCar2Sat: satIdCarreta2,
        idBauSat: satIdBau,
        idBauCheck: idChecklistBau,
        idPreSm: null,
        dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        dataFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        horaInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        horaFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        solicitacaoMonitoramentoPlanoViagemVO: arrayPontosPreSm,
        paradaVO: [...paradasArrayAtualizado],
      };
    } else {
      data = {
        ...model,
        flagEscolta: (flagObrigatoriedadeEscolta && mostrarCamposEscolta) || camposEscolta,
        flagIsca: (flagObrigatoriedadeIsca && flagObrigatoriedadeIsca) || camposIsca,
        idCheck: idChecklist,
        idSat: satId,
        idCarCheck: idChecklistCarreta,
        idCarSat: satIdCarreta,
        idCar2Check: idChecklistCarreta2,
        idCar2Sat: satIdCarreta2,
        idBauSat: satIdBau,
        idBauCheck: idChecklistBau,
        dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        dataFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        horaInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
        horaFim: toZoned(`${model.dataFim} ${model.horaFim}`),
        solicitacaoMonitoramentoPlanoViagemVO: [],
        paradaVO: [...paradasArrayAtualizado],
      };
    }

    setLoadingInsert(true);

    if (state && state === 'confirmaSM') {
      try {
        await axios.put(`/solicitacao-monitoramento/atualizar-pre-sm-para-sm?idSm=${id}`, data);
        setLoadingInsert(false);
        setIsDialogVisible(true);
      } catch (err: any) {
        setLoadingInsert(false);
        if (
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.includes('email')
        ) {
          setIsDialogVisibleCadOkEmail(true);
        }
        if (
          err.response.data &&
          err.response.data.message === 'Não foi possível recuperar os dados do(s) Ponto(s)'
        ) {
          setIsDialogVisibleCadOk(true);
          setErroPonto(true);
          return;
        }
        setIsDialogVisible(true);
      }
    } else {
      try {
        setLoadingInsert(false);
        setIsDialogVisible(true);
      } catch (err: any) {
        if (
          err.response.data &&
          err.response.data.message?.includes('histórico de posição do veículo')
        ) {
          setIdSmEnviada(soNumeros(err.response.data.message));
          setIsDialogErroRecuperarPontos(true);
          return;
        }
        if (
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.includes('email')
        ) {
          setIsDialogVisibleCadOkEmail(true);
        } else if (
          err.response.data &&
          err.response.data.message === 'Não foi possível recuperar os dados do(s) Ponto(s)'
        ) {
          setIsDialogVisibleCadOk(true);
          setErroPonto(true);
          return;
        } else if (err.response.data && err.response.data.message?.includes('MATRIX')) {
          setIsDialogErroDistanceMatrix(true);
          return;
        } else if (
          err.response.data &&
          (err.response.data.message?.includes('com pesquisa vencida') ||
            err.response.data.message?.includes('Analise a pesquisa ID'))
        ) {
          addToast({
            title: 'Erro',
            description: err.response.data.message,
            type: 'error',
          });
          return;
        }
        return;
      } finally {
        setLoadingInsert(true);
        if (isRotaPorPontos) {
          const useRouteByPointsData = {
            ...data,
            ...(cnhCondutor1Valida !== '' && { cnhPrimeiroCondutorValidade: cnhCondutor1Valida }),
            ...(cnhCondutor2Valida !== '' && { cnhSegundoCondutorValidade: cnhCondutor2Valida }),
            statusValidadePesquisa: veiculo?.statusValidadePesquisa,
            statusCritica: veiculo?.statusCritica,
            statusValidadeChecklist: veiculo?.statusValidadeChecklist,
            rotaPorPontos: true,
            velocidadeMaxima: vehicleSpeedVinculed ? model.velocidadeMaxima : null,
            temperaturaMaxima: vehicleTemperatureVinculed ? model.temperaturaMaxima : null,
            temperaturaMinima: vehicleTemperatureVinculed ? model.temperaturaMinima : null ,
          };
          setIsDialogVisible(true);

          const pointCreated = await handleCreatePointsSM(useRouteByPointsData);
          setLoadingInsert(false);
          setIdSmEnviada(pointCreated.sm);
          return setIsSMLoading(true);
        }
        const dontUseRouteByPointsData = {
          ...data,
          ...(cnhCondutor1Valida !== '' && { cnhPrimeiroCondutorValidade: cnhCondutor1Valida }),
          ...(cnhCondutor2Valida !== '' && { cnhSegundoCondutorValidade: cnhCondutor2Valida }),
          statusValidadePesquisa: veiculo?.statusValidadePesquisa,
          statusCritica: veiculo?.statusCritica,
          statusValidadeChecklist: veiculo?.statusValidadeChecklist,
          rotaPorPontos: false,
          velocidadeMaxima: vehicleSpeedVinculed ? model.velocidadeMaxima : null,
          temperaturaMaxima: vehicleTemperatureVinculed ? model.temperaturaMaxima : null,
          temperaturaMinima: vehicleTemperatureVinculed ? model.temperaturaMinima : null ,
        };

        setIsDialogVisible(true);

        const pointCreated = await handleCreatePointsSM(dontUseRouteByPointsData);
        setLoadingInsert(false);
        setIdSmEnviada(pointCreated.sm);
        return setIsSMLoading(true);
      }
    }
  };

  const [satId, setSatId] = useState<number | null>(null);
  const [idChecklist, setIdChecklist] = useState<number | null>(null);
  const resetVeiculo = () => {
    setDisplayMsgSatExistenteCarreta1(false);
    setDisplayMsgSatExistenteCarreta2(false);
    setDispositivosCarreta(null);
    setDispositivosCarreta2(null);
    setChecklistValidade('');
    setVeiculo(undefined);
    setIsVeiculoBloqueado(false);
    setModel({
      ...model,
      idCarreta: null,
      idCarreta2: null,
      idVeiculo: null,
      idCheck: null,
      idSat: null,
    });
    setDispositivos(null);
    setSatId(null);
    setIdChecklist(null);
    setMostrarCamposLoginSenha(false);
    setMostrarCamposLoginSenha4(false);
    setCarretaVisible(false);
    setChecklistVencido(false);
    setTemperaturaVisible(false);
    setDisplayMsgChecklistInexistenteCarreta(false);
    setDisplayMsgChecklistInexistenteCarreta2(false);
    setDisplayMsgChecklistInexistenteVeiculo(false);
    setDisplayMsgSatExistenteVeiculo(false);
    setUsuarioLogado({ isLogado: false, senha: '', usuario: login });
    setVeiculoNaoRastreado(false);
    setVeiculoNaoRastreadoFaturamento(false);
  };
  const verificaPesquisaVeiculo = async (tipoFrota: any, idVeiculo: number, dataInicio: string) => {
    if (flagAnalisePerfil) {
      if (
        ((flagCriticarAnaliseFrotaPropriaNaSM && tipoFrota === '1') ||
          (flagCriticarAnaliseAgregadoNaSM && tipoFrota === '2') ||
          (flagCriticarAnaliseTerceiroNaSM && tipoFrota === '3')) &&
        dataInicio
      ) {
        try {
          const response = await axios.get(`/pesquisa/verificar-vigencia-veiculo`, {
            params: {
              dataInicio: new Date(dataInicio),
              idVeiculo,
              idCliente,
            },
          });
          if (response.data === 'Pesquisa em acordo') {
            return '';
          }
          if (response.data === 'Veiculo possui pesquisa vencida') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
          if (response.data === 'Veiculo não possui pesquisa ou pesquisa em acordo') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
        } catch {
          return '';
        }
      } else {
        return '';
      }
    }
    return '';
  };

  const getTemperatura = (response: any, velocidadeMaxima: number | null) => {
    if (response.veiculoTipoNome.toLowerCase().includes('refrigerado')) {
      setModel({
        ...model,
        temperaturaMaxima: response.temperaturaMaxima,
        temperaturaMinima: response.temperaturaMinima,
        velocidadeMaxima,
      });
    } else {
      setModel({
        ...model,
        velocidadeMaxima,
      });
    }
  };

  const getVelocidadeMaxima = useCallback(async () => {
    try {
      const response = await axios.get(`/veiculo/${model.idVeiculo}`);
      const _violacao = await axios.get(`/violacao-velocidade/idCliente?idCliente=${idCliente}`);
      let velocidadeMax = null;
      if (response.data.velocidadeMaxima) {
        velocidadeMax = response.data.velocidadeMaxima;
      } else if (_violacao.data.velocidadeMaximaFrota) {
        velocidadeMax = _violacao.data.velocidadeMaximaFrota;
      }
      getTemperatura(response.data, velocidadeMax);
    } catch (err) {
      console.error(err);
    }
  }, [model.idVeiculo, idCliente, temperaturaVisible]);

  useEffect(() => {
    if (model.idVeiculo) {
      getVelocidadeMaxima();
    }
  }, [model.idVeiculo, temperaturaVisible]);

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoVeiculo && model.idVeiculo) {
        const vigenciaPesquisa = await verificaPesquisaVeiculo(
          tipoVeiculo,
          model.idVeiculo,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaVei(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoVeiculo, model.idVeiculo, model.dataInicio]);

  const loadDadosVeiculo = async (idVeiculo: number) => {
    setLoadingDadosSM(true);

    try {
      const response = await axios.get(
        `/veiculo/listarDadosVeiculoParaSM?idCliente=${idCliente}&veiculo=${idVeiculo}`
      );

      setVeiculo(response.data);
      setCriticaVei(response.data.statusCritica);
      setChecklistValidade(response.data.statusValidadeChecklist);
      setVeiculoID(response.data.id);
      setTipoVeiculo(response.data.tipoFrota);
      await getParticularidades();

      if (response.data.sat) {
        setSatId(response.data.idSat);

        if (flagBloqueioSAT === 2) {
          await fetchDadosVeiculosParaSat(idVeiculo);
          setDisplayMsgSatExistenteVeiculo(true);
          setIsDialogVisibleBloqueioSat(true);
        }

        if (flagBloqueioSAT === 1) {
          await fetchDadosVeiculosParaSat(idVeiculo);
          setDisplayMsgSatExistenteVeiculo(true);
          setDesabilitaForm(true);
          setIsDialogVisibleBloqueioSat(true);
        } else setDesabilitaForm(false);
      } else {
        setDisplayMsgSatExistenteVeiculo(false);
        setIsDialogVisibleBloqueioSat(false);
      }

      if (flagChecklistPendente === 2) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteVeiculo(true);
        } else {
          setIdChecklist(response.data.idCheck);
          setDisplayMsgChecklistInexistenteVeiculo(false);
        }
      }

      if (flagChecklistPendente === 1) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteVeiculo(true);
          setDesabilitaForm(true);
          setIsDialogVisibleBloqueioChecklist(true);
        } else {
          setIdChecklist(response.data.idCheck);
          setDisplayMsgChecklistInexistenteVeiculo(false);
          setDesabilitaForm(false);
          setIsDialogVisibleBloqueioChecklist(false);
        }
      }
      if (response.data.tipoCarreta) {
        if (response.data.tipoCarreta.includes('REFRIGERADO')) {
          setTemperaturaVisible(true);
        } else {
          setTemperaturaVisible(false);
        }
        if (
          response.data.tipoCarreta.includes('CAVALO MECANICO') ||
          response.data.tipoCarreta.includes('ROMEU')
        ) {
          setCarretaVisible(true);
        } else {
          setCarretaVisible(false);
        }
      }

      if (
        response.data.tecnologia?.toLowerCase().includes('não rastreado') ||
        response.data.tecnologia?.toLowerCase().includes('nao rastreado')
      ) {
        setVeiculoNaoRastreado(true);
        addToast({
          title: 'Erro',
          description: 'Veículo sem rastreador cadastrado.',
          type: 'error',
        });
      }

      if (
        response.data.faturarComo === 'DESABILITADO' ||
        response.data.faturarComo === 'NAO RASTREADO'
      ) {
        setIsDialogVisibleVeiculoNaoRastreadoFaturamento(true);
      }

      if (!response.data.tipoFrota) {
        addToast({
          title: 'Erro',
          description: `Tipo de Frota do Veículo ${response.data.placa} inválido.`,
          type: 'error',
        });
      }

      if (response.data.bloqueado) {
        setIsVeiculoBloqueado(true);
        addToast({
          title: 'Erro',
          description: 'Veículo Bloqueado',
          type: 'error',
        });
      } else {
        setIsVeiculoBloqueado(false);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingDadosSM(false);
    }
  };

  const [satIdCarreta, setSatIdCarreta] = useState<number | null>(null);
  const [idChecklistCarreta, setIdChecklistCarreta] = useState<number | null>(null);

  const resetCarreta1 = () => {
    setDisplayMsgChecklistInexistenteCarreta(false);
    setCarreta(undefined);
    setIsCarretaBloqueada(false);
    setChecklistValidadeCar1('');
    setModel({
      ...model,
      idCarreta: null,
      idCarCheck: null,
      idCarSat: null,
    });
    setDispositivosCarreta(null);
    setIdChecklistCarreta(null);
    setSatIdCarreta(null);
    setMostrarCamposLoginSenha2(false);
    setUsuarioLogado2({ isLogado: false, senha: '', usuario: login });
    setPesquisaCar1('');
  };

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoCarreta1 && model.idCarreta) {
        const vigenciaPesquisa = await verificaPesquisaCarreta(
          tipoCarreta1,
          model.idCarreta,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaCar1(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoCarreta1, model.idCarreta, model.dataInicio]);

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoCarreta2 && model.idCarreta2) {
        const vigenciaPesquisa = await verificaPesquisaCarreta(
          tipoCarreta2,
          model.idCarreta2,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaCar2(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoCarreta2, model.idCarreta2, model.dataInicio]);

  const verificaPesquisaCarreta = async (tipoFrota: any, idCarreta: number, dataInicio: string) => {
    if (flagAnalisePerfil) {
      if (
        ((flagCriticarAnaliseFrotaPropriaNaSM && tipoFrota === '1') ||
          (flagCriticarAnaliseAgregadoNaSM && tipoFrota === '2') ||
          (flagCriticarAnaliseTerceiroNaSM && tipoFrota === '3')) &&
        dataInicio
      ) {
        try {
          const response = await axios.get(`/pesquisa/verificar-vigencia-carreta`, {
            params: {
              dataInicio: new Date(dataInicio),
              idCarreta,
              idCliente,
            },
          });
          if (response.data === 'Pesquisa em acordo') {
            return '';
          }
          if (response.data === 'Carreta possui pesquisa vencida') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
          if (response.data === 'Carreta não possui pesquisa ou pesquisa em acordo') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
        } catch {
          return '';
        }
      } else {
        return '';
      }
    }
    return '';
  };

  const loadDadosCarreta = async (idCarreta: number) => {
    setLoadingDadosSM(true);
    try {
      const response = await axios.get(
        `/veiculo/listarDadosVeiculoParaSM?idCliente=${idCliente}&veiculo=${idCarreta}`
      );
      setCarreta(response.data);
      setIdCarreta1(response.data.id);
      setCriticaCar1(response.data.statusCritica);
      setChecklistValidadeCar1(response.data.statusValidadeChecklist);
      await getParticularidades();

      setTipoCarreta1(response.data.tipoFrota);

      if (flagChecklistPendente === 2) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteCarreta(true);
        } else {
          setIdChecklistCarreta(response.data.idCheck);
          setDisplayMsgChecklistInexistenteCarreta(false);
        }
      }

      if (flagChecklistPendente === 1) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteCarreta(true);
          setDesabilitaForm(true);
          setIsDialogVisibleBloqueioChecklist(true);
        } else {
          setIdChecklistCarreta(response.data.idCheck);
          setDisplayMsgChecklistInexistenteCarreta(false);
          setDesabilitaForm(false);
          setIsDialogVisibleBloqueioChecklist(false);
        }
      }

      if (
        response.data.faturarComo === 'DESABILITADO' ||
        response.data.faturarComo === 'NAO RASTREADO'
      ) {
        setIsDialogVisibleVeiculoNaoRastreadoFaturamento(true);
        setVeiculoNaoRastreadoFaturamento(true);
      }

      if (flagBloqueioSAT) {
        if (response.data.sat) {
          setSatIdCarreta(response.data.satId);

          await fetchDadosCarreta1ParaSat(response.data.id);
          setDisplayMsgSatExistenteCarreta1(true);
        } else {
          setDisplayMsgSatExistenteCarreta1(false);
        }
      } else {
        setDisplayMsgSatExistenteCarreta1(false);
      }

      if (response.data.tipoFrota === null) {
        addToast({
          title: 'Erro',
          description: `Tipo de Frota da Carreta ${response.data.placa} inválido.`,
          type: 'error',
        });
      }

      if (response.data.bloqueado === true) {
        setIsCarretaBloqueada(true);

        addToast({
          title: 'Erro',
          description: 'Carreta Bloqueada',
          type: 'error',
        });
      } else {
        setIsCarretaBloqueada(false);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingDadosSM(false);
    }
  };

  const [satIdCarreta2, setSatIdCarreta2] = useState<number | null>(null);
  const [idChecklistCarreta2, setIdChecklistCarreta2] = useState<number | null>(null);

  const resetCarreta2 = () => {
    setCarreta2(undefined);
    setIsCarretaBloqueada(false);
    setChecklistValidadeCar2('');
    setModel({
      ...model,
      idCarreta2: null,
      idCar2Check: null,
      idCar2Sat: null,
    });
    setSatIdCarreta2(null);
    setIdChecklistCarreta2(null);
    setDispositivosCarreta2(null);
    setMostrarCamposLoginSenha3(false);
    setUsuarioLogado3({ isLogado: false, senha: '', usuario: login });
    setDisplayMsgChecklistInexistenteCarreta2(false);
    setPesquisaCar2('');
  };

  const loadDadosCarreta2 = async (idCarreta2: number) => {
    setLoadingDadosSM(true);
    try {
      const response = await axios.get(
        `/veiculo/listarDadosVeiculoParaSM?idCliente=${idCliente}&veiculo=${idCarreta2}`
      );
      setCarreta2(response.data);
      setIdCarreta2(response.data.id);
      setCriticaCar2(response.data.statusCritica);

      await getParticularidades();
      setTipoCarreta2(response.data.tipoFrota);

      if (flagChecklistPendente === 2) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteCarreta2(true);
        } else {
          setIdChecklistCarreta2(response.data.idCheck);
          setDisplayMsgChecklistInexistenteCarreta2(false);
        }
      }

      if (
        response.data.faturarComo === 'DESABILITADO' ||
        response.data.faturarComo === 'NAO RASTREADO'
      ) {
        setIsDialogVisibleVeiculoNaoRastreadoFaturamento(true);
        setVeiculoNaoRastreadoFaturamento(true);
      }

      if (flagChecklistPendente === 1) {
        if (response.data.statusValidadeChecklist === 'SEM CHECKLIST') {
          setDisplayMsgChecklistInexistenteCarreta2(true);
          setDesabilitaForm(true);
          setIsDialogVisibleBloqueioChecklist(true);
        } else {
          setIdChecklistCarreta2(response.data.idCheck);
          setDisplayMsgChecklistInexistenteCarreta2(false);
          setDesabilitaForm(false);
          setIsDialogVisibleBloqueioChecklist(false);
        }
      }

      if (flagBloqueioSAT) {
        if (response.data.sat) {
          setSatIdCarreta2(response.data.satId);

          await fetchDadosCarreta2ParaSat(response.data.id);
          setDisplayMsgSatExistenteCarreta2(true);
        } else {
          setDisplayMsgSatExistenteCarreta2(false);
        }
      } else {
        setDisplayMsgSatExistenteCarreta2(false);
      }

      if (response.data.tipoFrota === null) {
        addToast({
          title: 'Erro',
          description: `Tipo de Frota da Carreta ${response.data.placa} inválido.`,
          type: 'error',
        });
      }

      if (response.data.bloqueado === true) {
        setIsCarreta2Bloqueada(true);

        addToast({
          title: 'Erro',
          description: 'Carreta Bloqueada',
          type: 'error',
        });
      } else {
        setIsCarreta2Bloqueada(false);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoadingDadosSM(false);
    }
  };

  const [satIdBau, setSatIdBau] = useState<number | null>(null);
  const [idChecklistBau, setIdChecklistBau] = useState<number | null>(null);
  const loadDadosBau = async (idBau: number) => {
    setBau(undefined);
    setModel({
      ...model,
      idBau: null,
      idBauSat: null,
      idBauCheck: null,
    });
    setDispositivosBau(null);

    setIdChecklistBau(null);
    setSatIdBau(null);
    setMostrarCamposLoginSenha5(false);
    setUsuarioLogado5({ isLogado: false, senha: '', usuario: login });

    setLoading(true);
    try {
      const response = await axios.get(`/bau-intercambiavel/listarDadosBauParaSM?bau=${idBau}`);
      setBau(response.data);
      await getParticularidades();

      if (response.data.idSat) {
        setSatIdBau(response.data.idSat);
        await fetchDadosBauParaSat(idBau);
        setDisplayMsgSatExistenteBau(true);
      } else {
        setDisplayMsgSatExistenteBau(false);
      }

      if (flagChecklistPendente === 1) {
        if (response.data.checklist || response.data.idChecklist) {
          setIdChecklistBau(response.data.idChecklist);
          setDisplayMsgChecklistInexistenteBau(false);
          setDesabilitaForm(false);
          setIsDialogVisibleBloqueioChecklist(false);
        } else {
          setDisplayMsgChecklistInexistenteBau(true);
          setDesabilitaForm(true);
          setIsDialogVisibleBloqueioChecklist(true);
        }
      }

      if (flagChecklistPendente === 2) {
        if (response.data.checklist || response.data.idChecklist) {
          setIdChecklistBau(response.data.idChecklist);
          setDisplayMsgChecklistInexistenteBau(false);
        } else {
          setDisplayMsgChecklistInexistenteBau(true);
        }
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const loadDadosEmbarcador = async (idEmbarcador: number) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/embarcador/listarDadosEmbarcadorParaSM?embarcador=${idEmbarcador}`
      );
      setEmbarcador(response.data);
      setEmbarcadorId(response.data.idCliente);
      setEmbarcadorId2(response.data.id);
      setIsClienteEmbarcador(response.data.isClienteEmbarcador);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const handleTipoOperacaoChange = (e: any) => {
    const tipoOperacaoNome = e.target.options[e.target.selectedIndex].text;
    const tipoOperacaoId = parseInt(e.target.value, 10);

    setTipoOperacaoSelecionada(tipoOperacaoNome);
    setModel({
      ...model,
      tipoOperacao: tipoOperacaoId,
    });
  };

  const confereValoresLimiteEmbarcador = (vrCarga: number) => {
    const { tipoOperacao } = model;
    if (tipoOperacao === 0) {
      addToast({
        title: 'Erro',
        description: 'É preciso selecionar antes o Tipo de Operação',
        type: 'error',
      });
    }

    if (tipoOperacaoSelecionada === 'Transferência') {
      const valorLimiteCargaTransferencia =
        embarcador && embarcador.valorLimiteCargaTransferencia
          ? embarcador.valorLimiteCargaTransferencia.toFixed(2)
          : 0;
      const valorPisoEscoltaTransferencia =
        embarcador && embarcador.valorPisoEscoltaTransferencia
          ? embarcador.valorPisoEscoltaTransferencia.toFixed(2)
          : 0;
      const valorPisoIscaTransferencia =
        embarcador && embarcador.valorPisoIscaTransferencia
          ? embarcador.valorPisoIscaTransferencia.toFixed(2)
          : 0;

      let transferenciaModel = model;

      if (vrCarga >= valorPisoEscoltaTransferencia) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
        transferenciaModel = {
          ...transferenciaModel,
          flagEscolta: true,
        };
        setModel(transferenciaModel);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
        transferenciaModel = {
          ...transferenciaModel,
          flagEscolta: false,
          idTecnologiaEscolta: '',
          idTipoComunicacaoEscolta: '',
          veiculoEscolta: null,
          flagMonitoramentoEscoltaGs: false,
          numeroEquipamentoTecnologiaEscolta: null,
          empresaEscolta: null,
          telEscolta: null,
          agenteEscolta: null,
          agente2Escolta: null,
          telAgente2Escolta: null,
          telAgenteEscolta: null,
        };
        setModel(transferenciaModel);
      }

      if (vrCarga > valorLimiteCargaTransferencia) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoIscaTransferencia) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
        transferenciaModel = {
          ...transferenciaModel,
          flagIsca: true,
        };
        setModel(transferenciaModel);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
        transferenciaModel = {
          ...transferenciaModel,
          flagIsca: false,
          iscaNumEquipamento: null,
          iscaSite: null,
          iscaSiteLogin: null,
          iscaSiteSenha: null,
          idTipoComunicacaoIsca: '',
          idTecnologiaIsca: '',
        };
        setModel(transferenciaModel);
      }
    } else if (tipoOperacaoSelecionada === 'Distribuição') {
      const valorLimiteCargaDistribuicao =
        embarcador && embarcador.valorLimiteCargaDistribuicao
          ? embarcador.valorLimiteCargaDistribuicao.toFixed(2)
          : 0;
      const valorPisoEscoltaDistribuicao =
        embarcador && embarcador.valorPisoEscoltaDistribuicao
          ? embarcador.valorPisoEscoltaDistribuicao.toFixed(2)
          : 0;
      const valorPisoIscaDistribuicao =
        embarcador && embarcador.valorPisoIscaDistribuicao
          ? embarcador.valorPisoIscaDistribuicao.toFixed(2)
          : 0;

      let distribuicaoModel = model;

      if (vrCarga > valorLimiteCargaDistribuicao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaDistribuicao) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
        distribuicaoModel = {
          ...distribuicaoModel,
          flagEscolta: true,
        };
        setModel(distribuicaoModel);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
        distribuicaoModel = {
          ...distribuicaoModel,
          flagEscolta: false,
          idTecnologiaEscolta: '',
          idTipoComunicacaoEscolta: '',
          veiculoEscolta: null,
          flagMonitoramentoEscoltaGs: false,
          numeroEquipamentoTecnologiaEscolta: null,
          empresaEscolta: null,
          telEscolta: null,
          agenteEscolta: null,
          agente2Escolta: null,
          telAgente2Escolta: null,
          telAgenteEscolta: null,
        };
        setModel(distribuicaoModel);
      }

      if (vrCarga >= valorPisoIscaDistribuicao) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
        distribuicaoModel = {
          ...distribuicaoModel,
          flagIsca: true,
        };
        setModel(distribuicaoModel);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
        distribuicaoModel = {
          ...distribuicaoModel,
          flagIsca: false,
          iscaNumEquipamento: null,
          iscaSite: null,
          iscaSiteLogin: null,
          iscaSiteSenha: null,
          idTipoComunicacaoIsca: '',
          idTecnologiaIsca: '',
        };
        setModel(distribuicaoModel);
      }
    } else if (tipoOperacaoSelecionada === 'Coleta') {
      const valorLimiteCargaColeta =
        embarcador && embarcador.valorLimiteCargaColeta
          ? embarcador.valorLimiteCargaColeta.toFixed(2)
          : 0;
      const valorPisoEscoltaColeta =
        embarcador && embarcador.valorPisoEscoltaColeta
          ? embarcador.valorPisoEscoltaColeta.toFixed(2)
          : 0;
      const valorPisoIscaColeta =
        embarcador && embarcador.valorPisoIscaColeta
          ? embarcador.valorPisoIscaColeta.toFixed(2)
          : 0;

      let coletaModel = model;

      if (vrCarga > valorLimiteCargaColeta) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaColeta) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
        coletaModel = {
          ...coletaModel,
          flagEscolta: true,
        };
        setModel(coletaModel);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
        coletaModel = {
          ...coletaModel,
          flagEscolta: false,
          idTecnologiaEscolta: '',
          idTipoComunicacaoEscolta: '',
          veiculoEscolta: null,
          flagMonitoramentoEscoltaGs: false,
          numeroEquipamentoTecnologiaEscolta: null,
          empresaEscolta: null,
          telEscolta: null,
          agenteEscolta: null,
          agente2Escolta: null,
          telAgente2Escolta: null,
          telAgenteEscolta: null,
        };
        setModel(coletaModel);
      }

      if (vrCarga >= valorPisoIscaColeta) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
        coletaModel = {
          ...coletaModel,
          flagIsca: true,
        };
        setModel(coletaModel);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
        coletaModel = {
          ...coletaModel,
          flagIsca: false,
          iscaNumEquipamento: null,
          iscaSite: null,
          iscaSiteLogin: null,
          iscaSiteSenha: null,
          idTipoComunicacaoIsca: '',
          idTecnologiaIsca: '',
        };
        setModel(coletaModel);
      }
    } else if (tipoOperacaoSelecionada === 'Exportação') {
      const valorLimiteCargaExportacao =
        embarcador && embarcador.valorLimiteCargaExportacao
          ? embarcador.valorLimiteCargaExportacao.toFixed(2)
          : 0;
      const valorPisoEscoltaExportacao =
        embarcador && embarcador.valorPisoEscoltaExportacao
          ? embarcador.valorPisoEscoltaExportacao.toFixed(2)
          : 0;
      const valorPisoIscaExportacao =
        embarcador && embarcador.valorPisoIscaExportacao
          ? embarcador.valorPisoIscaExportacao.toFixed(2)
          : 0;

      let exportacaoModel = model;

      if (vrCarga > valorLimiteCargaExportacao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaExportacao) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
        exportacaoModel = {
          ...exportacaoModel,
          flagEscolta: true,
        };
        setModel(exportacaoModel);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
        exportacaoModel = {
          ...exportacaoModel,
          flagEscolta: false,
          idTecnologiaEscolta: '',
          idTipoComunicacaoEscolta: '',
          veiculoEscolta: null,
          flagMonitoramentoEscoltaGs: false,
          numeroEquipamentoTecnologiaEscolta: null,
          empresaEscolta: null,
          telEscolta: null,
          agenteEscolta: null,
          agente2Escolta: null,
          telAgente2Escolta: null,
          telAgenteEscolta: null,
        };
        setModel(exportacaoModel);
      }

      if (vrCarga >= valorPisoIscaExportacao) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
        exportacaoModel = {
          ...exportacaoModel,
          flagIsca: true,
        };
        setModel(exportacaoModel);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
        exportacaoModel = {
          ...exportacaoModel,
          flagIsca: false,
          iscaNumEquipamento: null,
          iscaSite: null,
          iscaSiteLogin: null,
          iscaSiteSenha: null,
          idTipoComunicacaoIsca: '',
          idTecnologiaIsca: '',
        };
        setModel(exportacaoModel);
      }
    } else if (tipoOperacaoSelecionada === 'Devolução') {
      const valorLimiteCargaDevolucao =
        embarcador && embarcador.valorLimiteCargaDevolucao
          ? embarcador.valorLimiteCargaDevolucao.toFixed(2)
          : 0;
      const valorPisoEscoltaDevolucao =
        embarcador && embarcador.valorPisoEscoltaDevolucao
          ? embarcador.valorPisoEscoltaDevolucao.toFixed(2)
          : 0;
      const valorPisoIscaDevolucao =
        embarcador && embarcador.valorPisoIscaDevolucao
          ? embarcador.valorPisoIscaDevolucao.toFixed(2)
          : 0;

      let devolucaoModel = model;

      if (vrCarga > valorLimiteCargaDevolucao) {
        setErroValorCarga(true);
      } else {
        setErroValorCarga(false);
      }

      if (vrCarga >= valorPisoEscoltaDevolucao) {
        setFlagObrigatoriedadeEscolta(true);
        setMostrarCamposEscolta(true);
        devolucaoModel = {
          ...devolucaoModel,
          flagEscolta: true,
        };
        setModel(devolucaoModel);
      } else {
        setFlagObrigatoriedadeEscolta(false);
        setMostrarCamposEscolta(false);
        devolucaoModel = {
          ...devolucaoModel,
          flagEscolta: false,
          idTecnologiaEscolta: '',
          idTipoComunicacaoEscolta: '',
          veiculoEscolta: null,
          flagMonitoramentoEscoltaGs: false,
          numeroEquipamentoTecnologiaEscolta: null,
          empresaEscolta: null,
          telEscolta: null,
          agenteEscolta: null,
          agente2Escolta: null,
          telAgente2Escolta: null,
          telAgenteEscolta: null,
        };
        setModel(devolucaoModel);
      }

      if (vrCarga >= valorPisoIscaDevolucao) {
        setFlagObrigatoriedadeIsca(true);
        setMostrarCamposIsca(true);
        devolucaoModel = {
          ...devolucaoModel,
          flagIsca: true,
        };
        setModel(devolucaoModel);
      } else {
        setFlagObrigatoriedadeIsca(false);
        setMostrarCamposIsca(false);
        devolucaoModel = {
          ...devolucaoModel,
          flagIsca: false,
          iscaNumEquipamento: null,
          iscaSite: null,
          iscaSiteLogin: null,
          iscaSiteSenha: null,
          idTipoComunicacaoIsca: '',
          idTecnologiaIsca: '',
        };
        setModel(devolucaoModel);
      }
    }
  };

  const handleValorCargaChange = (value: number) => {
    value = Number(toDecimal(value));

    if (parametros && value < Number(parametros.valorMinimoCargas)) {
      setValorCargaMinimoErro(true);
    } else {
      setValorCargaMinimoErro(false);
    }

    confereValoresLimiteEmbarcador(value);

    setModel((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return {
        ...prevState,
        valorCarga: value === 0 || value === 0.0 ? null : value,
      };
    });
  };

  const verificaPesquisaCondutor = async (tipo: any, idCondutor: number, dataInicio: string) => {
    if (flagAnalisePerfil) {
      if (
        ((flagCriticarAnaliseCondutorAgregadoNaSM && tipo === '1') ||
          (flagCriticarAnaliseCondutorAutonomoNaSM && tipo === '2') ||
          (flagCriticarAnaliseCondutorFuncionarioNaSM && tipo === '3')) &&
        dataInicio
      ) {
        try {
          const response = await axios.get(`/pesquisa/verificar-vigencia-condutor`, {
            params: {
              dataInicio: new Date(dataInicio),
              idCondutor,
              idCliente,
            },
          });
          if (response.data === 'Pesquisa em acordo') {
            return '';
          }
          if (response.data === 'Condutor possui pesquisa vencida') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
          if (response.data === 'Condutor não possui pesquisa ou pesquisa em acordo') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
        } catch {
          return '';
        }
      }
      return '';
    }
    return '';
  };

  const resetCondutor = () => {
    setPesquisaCond('');
    setCnhCondutor1Invalida(false)
    setIsCondutorBloqueado(false)
    setCondutorBloqueadoPontos(false)
  };

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoCondutor && model.idCondutor) {
        const vigenciaPesquisa = await verificaPesquisaCondutor(
          tipoCondutor,
          model.idCondutor,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaCond(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoCondutor, model.idCondutor, model.dataInicio]);

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoCondutor2 && model.idCondutor2) {
        const vigenciaPesquisa = await verificaPesquisaCondutor(
          tipoCondutor2,
          model.idCondutor2,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaCond2(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoCondutor2, model.idCondutor2, model.dataInicio]);

  const loadDadosCondutor = async (idCondutor: number, isSecond: boolean) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/condutor/listarDadosCondutorParaSM?condutor=${idCondutor}`
      );
      setCriticaCond(response.data.statusCritica);
      setCondutorID(response.data.id);
      if (isSecond) {
        verificaCpfBlackListCondutor2(response.data.cpf);
      } else {
        verificaCpfBlackListCondutor1(response.data.cpf);
      }
      isSecond ? setTipoCondutor2(response.data.tipo) : setTipoCondutor(response.data.tipo);

      if (response.data.bloqueado && isSecond) {
        setIsCondutor2Bloqueado(true);

        addToast({
          title: 'Erro',
          description: 'Condutor bloqueado!',
          type: 'error',
        });
      } else if (!response.data.bloqueado && isSecond) {
        setIsCondutor2Bloqueado(false);
      } else if (response.data.bloqueado && !isSecond) {
        setIsCondutorBloqueado(true);

        addToast({
          title: 'Erro',
          description: 'Condutor bloqueado!',
          type: 'error',
        });
      } else if (!response.data.bloqueado && !isSecond) {
        setIsCondutorBloqueado(false);
      }

      setLoading(false);
    } catch (err: any) {
      if (isSecond) {
        setIsCondutor2Bloqueado(false);
      } else if (!isSecond) {
        setIsCondutorBloqueado(false);
      }
      setLoading(false);
    }
  };

  const verificaCpfBlackListCondutor1 = async (cpf: string) => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });
      if (response.data) {
        setCpfErroCondutor(true);
        setBloqueioInsertBlacklistCondutor(true);
      } else {
        setCpfErroCondutor(false);
        setBloqueioInsertBlacklistCondutor(false);
      }
    } catch (err: any) {}
  };

  const verificaCpfBlackListCondutor2 = async (cpf: string) => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });
      if (response.data) {
        setCpfErroCondutor2(true);
        setBloqueioInsertBlacklistCondutor(true);
      } else {
        setCpfErroCondutor2(false);
        setBloqueioInsertBlacklistCondutor(false);
      }
    } catch (err: any) {}
  };

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoAjudante && model.idAjudante) {
        const vigenciaPesquisa = await verificaPesquisaAjudante(
          tipoAjudante,
          model.idAjudante,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaAju(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoAjudante, model.idAjudante, model.dataInicio]);

  useEffect(() => {
    const checaVigencia = async () => {
      if (tipoAjudante2 && model.idAjudante2) {
        const vigenciaPesquisa = await verificaPesquisaAjudante(
          tipoAjudante2,
          model.idAjudante2,
          model.dataInicio.length === 10 && model.horaInicio.length === 5
            ? `${obterData(model.dataInicio, 'en')} ${model.horaInicio}`
            : `${obterData(dataAgora, 'en')} 00:00`
        );
        setPesquisaAju2(vigenciaPesquisa);
      }
    };
    checaVigencia();
  }, [tipoAjudante2, model.idAjudante2, model.dataInicio]);

  const verificaPesquisaAjudante = async (tipo: any, idAjudante: number, dataInicio: string) => {
    if (flagAnalisePerfil) {
      if (
        ((flagCriticarAnaliseNaoMotoristaAgregadoNaSM && tipo === '1') ||
          (flagCriticarAnaliseNaoMotoristaAutonomoNaSM && tipo === '2') ||
          (flagCriticarAnaliseNaoMotoristaFuncionarioNaSM && tipo === '3')) &&
        dataInicio
      ) {
        try {
          const response = await axios.get(`/pesquisa/verificar-vigencia-ajudante`, {
            params: {
              dataInicio: new Date(dataInicio),
              idAjudante,
              idCliente,
            },
          });
          if (response.data === 'Pesquisa em acordo') {
            return '';
          }
          if (response.data === 'Ajudante possui pesquisa vencida') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
          if (response.data === 'Ajudante não possui pesquisa ou pesquisa em acordo') {
            return 'Analise de Perfil Pendente, Vencida ou Inexistente';
          }
        } catch {
          return '';
        }
      } else {
        return '';
      }
    }
    return '';
  };

  const loadDadosAjudante = async (idAjudante: number, isSecond: boolean) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/ajudante-funcionario/listarDadosAjudanteFuncionarioParaSM?ajudante=${idAjudante}`
      );

      setCriticaAju(response.data.statusCritica);

      if (isSecond === true) {
        verificaCpfBlackListAjudante2(response.data.cpf);
      } else {
        verificaCpfBlackListAjudante(response.data.cpf);
      }

      isSecond ? setTipoAjudante2(response.data.tipo) : setTipoAjudante(response.data.tipo);

      if (response.data.bloqueado && isSecond) {
        setIsAjudante2Bloqueado(true);

        addToast({
          title: 'Erro',
          description: 'Ajudante Bloqueado',
          type: 'error',
        });
      } else if (!response.data.bloqueado && isSecond) {
        setIsAjudante2Bloqueado(false);
      } else if (response.data.bloqueado && !isSecond) {
        setIsAjudanteBloqueado(true);

        addToast({
          title: 'Erro',
          description: 'Ajudante Bloqueado',
          type: 'error',
        });
      } else if (!response.data.bloqueado && !isSecond) {
        setIsAjudanteBloqueado(false);
      }

      setLoading(false);
    } catch (err: any) {
      if (isSecond) {
        setIsAjudante2Bloqueado(false);
      } else if (!isSecond) {
        setIsAjudanteBloqueado(false);
      }
      setLoading(false);
    }
  };

  const verificaCpfBlackListAjudante = async (cpf: string) => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });

      if (response.data) {
        setCpfErroAjudante(true);
        setBloqueioInsertBlacklistAjudante(true);
      } else {
        setCpfErroAjudante(false);
        setBloqueioInsertBlacklistAjudante(false);
      }
    } catch (err: any) {}
  };

  const verificaCpfBlackListAjudante2 = async (cpf: string) => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });

      if (response.data) {
        setCpfErroAjudante2(true);
        setBloqueioInsertBlacklistAjudante(true);
      } else {
        setCpfErroAjudante2(false);
        setBloqueioInsertBlacklistAjudante(false);
      }
    } catch (err: any) {}
  };

  const loadDadosTecnologiaComunicacaoPorTecnologiaEscolta = async (
    idTecnologiaEscolta: number
  ) => {
    setLoading(true);
    try {
      const responseEscolta = await axios.get(
        `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${idTecnologiaEscolta}`
      );
      setTipoComunicacaoEscolta(responseEscolta.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const loadDadosTecnologiaComunicacaoPorTecnologiaIsca = async (idTecnologiaIsca: number) => {
    setLoading(true);
    try {
      const responseIsca = await axios.get(
        `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${idTecnologiaIsca}`
      );
      setTipoComunicacaoIsca(responseIsca.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  function handleTecnologiaIsca(tecnologiaIsca: ChangeEvent<HTMLInputElement>) {
    loadDadosTecnologiaComunicacaoPorTecnologiaIsca(parseInt(tecnologiaIsca.target.value, 10));
    setModel((prevState) => {
      return {
        ...prevState,
        [tecnologiaIsca.target.name]: tecnologiaIsca.target.value,
      };
    });
  }

  function handleTecnologiaEscolta(tecnologiaEscolta: ChangeEvent<HTMLInputElement>) {
    loadDadosTecnologiaComunicacaoPorTecnologiaEscolta(
      parseInt(tecnologiaEscolta.target.value, 10)
    );
    setModel((prevState) => {
      return {
        ...prevState,
        [tecnologiaEscolta.target.name]: tecnologiaEscolta.target.value,
      };
    });
  }


  const verifPontos = (
    baseDate: string,
    date: string,
    hour: string,
    baseHour: string,
    index?: number
  ) => {
    const arr = erroDataPontoInterm;
    for (let i = 0; i < model.solicitacaoMonitoramentoPlanoViagemVO.length; i++) {
      if (index != null) {
        if (model.solicitacaoMonitoramentoPlanoViagemVO[index - 1]) {
          const v1 = isHourLessThanBaseHour({
            baseDate: model.solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyDataPonto,
            date,
            hour,
            baseHour: model.solicitacaoMonitoramentoPlanoViagemVO[index - 1].onlyTimePonto,
          });
          arr[index] = v1 ? `Data/Hora menor que Ponto(${index + 1}).` : '';
        } else {
          const v1 = isHourLessThanBaseHour({
            baseDate: pontoOrigem.onlyDataPonto,
            date,
            hour,
            baseHour: pontoOrigem.onlyTimePonto,
          });
          arr[index] = v1 ? `Data/Hora menor que Ponto Origem.` : '';
        }
        if (model.solicitacaoMonitoramentoPlanoViagemVO[index + 1]) {
          const v1 = isHourLessThanBaseHour({
            baseDate,
            date: model.solicitacaoMonitoramentoPlanoViagemVO[index + 1].onlyDataPonto,
            hour: model.solicitacaoMonitoramentoPlanoViagemVO[index + 1].onlyTimePonto,
            baseHour,
          });
          arr[index + 1] = v1 ? `Data/Hora menor que Ponto(${index + 2}).` : '';
        }
      }
    }
    setErroDataPontoIntermediario(arr);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id !== '0') {
        const response = await axios.get(
          `/solicitacao-monitoramento-plano-viagem/listarPontosPorPreSM/${id}`
        );
        if (!response) {
          setIsConfirmPreSM(false);
        } else {
          setIsConfirmPreSM(true);
          setIsRotaEmbarcador(true);
          setTipoRota(1);
          setPreSmResults(response.data);
        }
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (checklistValidade === 'VENCIDO') {
        if (flagValidarChecklistPendente === 2) {
          setChecklistVencido(true);
          return;
        }
        if (flagValidarChecklistPendente === 1) {
          setChecklistVencido(true);
          setIsDialogVisibleBloqueioChecklist(true);
          setDesabilitaForm(true);
        }
      } else {
        const dating =
          dataInicioPreSm ||
          toZoned(`${model.dataInicio}${model.horaInicio !== '' ? ` ${model.horaInicio}` : ''}`);
        if (!model.dataInicio || !dating) {
          return;
        }

        try {
          if (model.horaInicio === 'undefined' && model.idVeiculo) {
            const response = await axios.get(
              `/veiculo/retornaStatusChecklistPorVeiculoDataViagemSM`,
              {
                params: {
                  veiculoId: veiculo?.id,
                  dataViagem: toZoned(model.dataInicio),
                },
              }
            );
            setChecklistValidade(response.data);
            if (flagChecklistPendente === 2) {
              if (response.data === 'VENCIDO') {
                setChecklistVencido(true);
              } else {
                setChecklistVencido(false);
              }
            }

            if (flagChecklistPendente === 1) {
              if (response.data === 'VENCIDO') {
                setChecklistVencido(true);
                setIsDialogVisibleBloqueioChecklist(true);
                setDesabilitaForm(true);
              } else {
                setChecklistVencido(false);
                setIsDialogVisibleBloqueioChecklist(false);
                setDesabilitaForm(false);
              }
            }
          }
        } catch (err: any) {}

        try {
          if (model.dataInicio && model.horaInicio !== 'undefined' && model.idVeiculo) {
            const response = await axios.get(
              `/veiculo/retornaStatusChecklistPorVeiculoDataViagemSM`,
              {
                params: {
                  veiculoId: veiculo?.id,
                  dataViagem: dating,
                },
              }
            );
            setChecklistValidade(response.data);

            if (flagChecklistPendente === 2) {
              if (response.data === 'VENCIDO') {
                setChecklistVencido(true);
              } else {
                setChecklistVencido(false);
              }
            }

            if (flagChecklistPendente === 1) {
              if (response.data === 'VENCIDO') {
                setChecklistVencido(true);
                setIsDialogVisibleBloqueioChecklist(true);
                setDesabilitaForm(true);
              } else {
                setChecklistVencido(false);
                setIsDialogVisibleBloqueioChecklist(false);
                setDesabilitaForm(false);
              }
            }
          }
        } catch (err: any) {}
      }
    };
    fetchData();
  }, [dataInicioPreSm, model.dataInicio, veiculo?.id, model.horaInicio, model.idVeiculo]); // dataInicioPreSm entra ou nao ?

  useEffect(() => {
    const fetchData = async () => {
      if (checklistValidadeCar1 === 'VENCIDO') {
        if (flagValidarChecklistPendente === 2) {
          setChecklistVencidoCar1(true);
        }
        if (flagValidarChecklistPendente === 1) {
          setChecklistVencidoCar1(true);
          setIsDialogVisibleBloqueioChecklist(true);
          setDesabilitaForm(true);
        }
      } else {
        if (!model.dataInicio) {
          return;
        }

        if (model.dataInicio && model.idCarreta) {
          const response = await axios.get(
            `/veiculo/retornaStatusChecklistPorVeiculoDataViagemSM`,
            {
              params: {
                veiculoId: idCarreta1,
                dataViagem: dataInicioPreSm || `${model.dataInicio} ${model.horaInicio}`,
              },
            }
          );
          setChecklistValidadeCar1(response.data);

          if (flagChecklistPendente === 1) {
            if (checklistValidadeCar1 === 'VENCIDO') {
              setChecklistVencidoCar1(true);
              setIsDialogVisibleBloqueioChecklist(true);
              setDesabilitaForm(true);
            } else {
              setChecklistVencidoCar1(false);
              setIsDialogVisibleBloqueioChecklist(false);
              setDesabilitaForm(false);
            }
          }

          if (flagChecklistPendente === 2) {
            if (checklistValidadeCar1 === 'VENCIDO') {
              setChecklistVencidoCar1(true);
            } else {
              setChecklistVencidoCar1(false);
            }
          }
        }
      }
    };
    fetchData();
  }, [dataInicioPreSm, model.dataInicio, model.horaInicio, model.idCarreta]);

  useEffect(() => {
    const fetchData2 = async () => {
      if (checklistValidadeCar2 === 'VENCIDO') {
        if (flagValidarChecklistPendente === 2) {
          setChecklistVencidoCar2(true);
        }
        if (flagValidarChecklistPendente === 1) {
          setChecklistVencidoCar2(true);
          setIsDialogVisibleBloqueioChecklist(true);
          setDesabilitaForm(true);
        }
      } else {
        if (!model.dataInicio) {
          return;
        }

        if (model.dataInicio && model.idCarreta2) {
          const response = await axios.get(
            `/veiculo/retornaStatusChecklistPorVeiculoDataViagemSM`,
            {
              params: {
                veiculoId: idCarreta2,
                dataViagem: dataInicioPreSm || `${model.dataInicio} ${model.horaInicio}`,
              },
            }
          );
          setChecklistValidadeCar2(response.data);

          if (flagChecklistPendente === 1) {
            if (checklistValidadeCar2 === 'VENCIDO') {
              setChecklistVencidoCar2(true);
              setDesabilitaForm(true);
              setIsDialogVisibleBloqueioChecklist(true);
            } else {
              setChecklistVencidoCar2(false);
              setIsDialogVisibleBloqueioChecklist(false);
              setDesabilitaForm(false);
            }
          }

          if (flagChecklistPendente === 2) {
            if (checklistValidadeCar2 === 'VENCIDO') {
              setChecklistVencidoCar2(true);
            } else {
              setChecklistVencidoCar2(false);
            }
          }
        }
      }
    };
    fetchData2();
  }, [dataInicioPreSm, model.dataInicio, model.horaInicio, model.idCarreta2]);

  useEffect(() => {
    const fetchData = async () => {
      setChecklistVencidoBau(false);
      setIsDialogVisibleBloqueioChecklist(false);
      setDesabilitaForm(false);
      if (!model.idBau) {
        return;
      }

      const response = await axios.get(
        `/bau-intercambiavel/listarDadosBauParaSM?bau=${model.idBau}`
      );

      if (response.data.checklist != null) {
        const dataCheck = new Date(response.data.checklist.split('T')[0]);
        const dataSm = new Date(model.dataInicio);
        const dataAtual = new Date();
        if (model.dataInicio && model.idBau) {
          if (flagValidarChecklistPendente === 1) {
            if (dataSm > dataCheck) {
              setChecklistVencidoBau(true);
              setIsDialogVisibleBloqueioChecklist(true);
              setDesabilitaForm(true);
            } else {
              setChecklistVencidoBau(false);
              setIsDialogVisibleBloqueioChecklist(false);
              setDesabilitaForm(false);
            }
          }

          if (flagValidarChecklistPendente === 2) {
            if (dataSm > dataCheck) {
              setChecklistVencidoBau(true);
            } else {
              setChecklistVencidoBau(false);
            }
          }
        } else {
          if (flagValidarChecklistPendente === 1) {
            if (dataAtual > dataCheck) {
              setChecklistVencidoBau(true);
              setIsDialogVisibleBloqueioChecklist(true);
              setDesabilitaForm(true);
            } else {
              setChecklistVencidoBau(false);
              setIsDialogVisibleBloqueioChecklist(false);
              setDesabilitaForm(false);
            }
          }

          if (flagValidarChecklistPendente === 2) {
            if (dataAtual > dataCheck) {
              setChecklistVencidoBau(true);
            } else {
              setChecklistVencidoBau(false);
            }
          }
        }
      }
      // }
    };
    fetchData();
  }, [
    dataInicioPreSm,
    model.dataInicio,
    model.horaInicio,
    model.idBau,
    flagValidarChecklistPendente,
  ]);

  useEffect(() => {
    if (flagServicoRotaPorPontosContratado) {
      setProgressMessage('Carregando pontos...');
      if (isRotaPorPontos) {
        if (!isRotaEmbarcador) {
          axios
            .get(
              `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${idCliente}`
            )
            .then((response: any) => {
              setPontosCliente(response.data);
            });
        } else {
          axios
            .get(
              `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${embarcadorId}`
            )
            .then((response: any) => {
              setPontosCliente(response.data);
            });
        }
      }
    } else if (isRotaEmbarcador) {
      axios
        .get(
          `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${embarcadorId}`
        )
        .then((response: any) => {
          setPontosCliente(response.data);
        });
    }
  }, [
    idCliente,
    embarcadorId,
    flagServicoRotaPorPontosContratado,
    isRotaEmbarcador,
    isRotaPorPontos,
    preSmResults,
  ]);

  const addNewInput = (event: any) => {
    if (pontoOrigem.idPonto && pontoOrigem.onlyDataPonto && pontoOrigem.onlyTimePonto) {
      if (flagSolicitarNumeroRemessaNaSM && pontoOrigem.remessa) {
        setAdicionaPontosIntermediarios(true);
      }
      setAdicionaPontosIntermediarios(true);
    }

    if (dataPreenchida && horaPreenchida && idPontoPreenchido) {
      if (flagSolicitarNumeroRemessaNaSM && remessaPreenchida) {
        setAdicionaPontosIntermediarios(true);
      }
      setAdicionaPontosIntermediarios(true);
    }

    if (preSmResults) {
      setAdicionaPontosIntermediarios(true);
    }

    if (!dataPreenchida || !horaPreenchida || !idPontoPreenchido) {
      return;
    }

    if (adicionaPontosIntermediarios) {
      const planoViagem = [
        ...model.solicitacaoMonitoramentoPlanoViagemVO,
        {
          onlyDataPonto: '',
          onlyTimePonto: '',
          dataPonto: '',
          idPonto: null,
          idSm: null,
          idSmPv: null,
          idUsuario: 0,
          remessa: '',
          sequencia: 0,
          tipoPonto: '',
        },
      ];
      setIdPontoPreenchido(false);
      setRemessaPreenchida(false);
      setDataPreenchida(false);
      setHoraPreenchida(false);

      setModel((prevState) => ({
        ...prevState,
        solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
      }));
    }
  };

  const removeInput = (index: number) => {
    if (index === 0) {
      return;
    }
    const planoViagem = [...model.solicitacaoMonitoramentoPlanoViagemVO];
    planoViagem.splice(index, 1);
    setIdPontoPreenchido(true);
    setRemessaPreenchida(true);
    setDataPreenchida(true);
    setHoraPreenchida(true);

    setModel((prevState) => ({
      ...prevState,
      solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
    }));
  };

  const handleAddParada = () => {
    const planoParada = [
      ...model.paradaVO,
      {
        ativo: true,
        bairro,
        cep,
        cidade,
        complemento: logradouroComplemento,
        idCodigoIbge,
        idUf: idCodigoIbge,
        local,
        logradouro,
        motivo,
        numero: logradouroNumero,
        rotaId: null,
        sequencia,
        statusAtivo: true,
      },
    ];

    setModel((prevState) => ({
      ...prevState,
      paradaVO: planoParada,
    }));
  };

  const [ultimaSeqLista, setUltimaSeqLista] = useState(0);

  useEffect(() => {
    const arraySequencia = model.paradaVO.map((parada) => parada.sequencia);
    const ultimoElementoLista = arraySequencia.pop();

    if (ultimoElementoLista) {
      setUltimaSeqLista(ultimoElementoLista);
    } else {
    }
  }, [model.paradaVO]);

  const removeParada = (index: number) => {
    if (model.idRota === null) {
      const planoParada = [...model.paradaVO];
      planoParada.splice(index + 1, 1);

      const listaAtualizada = planoParada.map((parada, index) => ({
        ...parada,
        sequencia: index,
      }));
      if (sequencia > index) {
        setModel((prevState) => ({
          ...prevState,
          paradaVO: listaAtualizada,
        }));
      }
    } else {
      const planoParada = [...model.paradaVO];
      planoParada.splice(index, 1);

      const listaAtualizada = planoParada.map((parada, index) => ({
        ...parada,
        sequencia: index + 1,
      }));

      setModel((prevState) => ({
        ...prevState,
        paradaVO: listaAtualizada,
      }));
    }
  };

  const verificaDataViagem = (
    dataInicio: string,
    horaInicio: string,
    dataFim: string,
    horaFim: string
  ) => {
    if (dataInicio) {
      const validaDataAtual = isDateLessThanBaseDate({
        baseDate: obterDataAtual(),
        date: dataInicio,
      });

      // Data inicial da viagem não pode ser menor do que a data atual.
      setErroDataInicial(validaDataAtual);
    }

    if (dataInicio && dataFim) {
      // Data inicial da viagem não pode ser maior do que a data final.
      const validaDataInicial = isDateLessThanBaseDate({
        baseDate: dataInicio,
        date: dataFim,
      });
      // Data inicial da viagem não pode ser menor do que a data atual.
      setErroDataInicial2(validaDataInicial);

      const validaData = isDateLessThanBaseDate({
        baseDate: dataInicio,
        date: dataFim,
      });
      // Data final da viagem não pode ser menor do que a data inicial da viagem.
      setErroDataFinal(validaData);
    }

    if (dataInicio && horaInicio) {
      const validaHoraAntecedencia = isHourLessThanBaseHour({
        date: dataInicio,
        hour: horaInicio,
        minutesAdition: parametros && parametros.antecedenciaInclusaoSmEmMinutos,
      });
      // Hora inicial da viagem deve ter uma antecedência de x minutos
      setErroHoraAntecendencia(validaHoraAntecedencia);
    }

    if (dataInicio && horaInicio && dataFim && horaFim) {
      const validaHora = isHourLessThanBaseHour({
        baseDate: dataInicio,
        baseHour: horaInicio,
        date: dataFim,
        hour: horaFim,
      });
      // Horário de início deverá ser menor que o horário final.
      setErroHoraInicialFim(validaHora);
      // Horário final da viagem não pode ser menor do que o horário inicial.
      setErroHoraFinal(validaHora);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (model.idRota) {
        setLoadingParadas(true);
        try {
          const response = await axios.get(`/rota/parada-por-rota?idRota=${model.idRota}`);
          const paradasResponse = response.data.map((each: any) => {
            return {
              id: each.idParada,
              sequencia: each.sequencia,
              motivo: each.motivo,
              local: each.local,
              logradouro: each.logradouro,
              numero: each.numero,
              complemento: each.complemento,
              bairro: each.bairro,
              cidade: each.cidade,
              ativo: each.ativo,
              idCodigoIbge: each.codigoIbgeUf,
              cep: each.cep,
              rotaId: model.idRota,
              idSm: each.idSm,
              idUf: each.codigoIbgeUf,
              statusAtivo: each.ativo,
            };
          });
          setParadas(paradasResponse);
          if (paradasResponse.length > 0) {
            setModel((prevState) => ({
              ...prevState,
              paradaVO: paradasResponse,
            }));
          } else {
            setModel((prevState) => ({
              ...prevState,
              paradaVO: [],
            }));
          }
        } catch (error) {
          setLoadingParadas(false);
          setModel((prevState) => ({
            ...prevState,
            paradaVO: [],
          }));
        }
      }
      setLoadingParadas(false);
    };
    fetchData();
  }, [model.idRota]);

  const verificaLancamentoGeral = async () => {
    if (
      model.dataInicio === '' ||
      model.horaInicio === '' ||
      !model.dataInicio ||
      !model.horaInicio
    ) {
      return;
    }

    const arrayItens = [];

    if (model.idVeiculo) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idVeiculo: model.idVeiculo,
        },
      });
      if (response.data) {
        arrayItens.push('Veículo');
      }
    }
    if (model.idCondutor) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idCondutor: model.idCondutor,
        },
      });
      if (response.data) {
        arrayItens.push('Condutor(1)');
      }
    }
    if (model.idCondutor2) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idCondutor2: model.idCondutor2,
        },
      });
      if (response.data) {
        arrayItens.push('Condutor(2)');
      }
    }
    if (model.idAjudante) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idAjudante: model.idAjudante,
        },
      });
      if (response.data) {
        arrayItens.push('Ajudante');
      }
    }
    if (model.idAjudante2) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idAjudante2: model.idAjudante2,
        },
      });
      if (response.data) {
        arrayItens.push('Ajudante(2)');
      }
    }
    if (model.idBau) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idBau: model.idBau,
        },
      });
      if (response.data) {
        arrayItens.push('Baú');
      }
    }
    if (model.idCarreta) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idCarreta: model.idCarreta,
        },
      });
      if (response.data) {
        arrayItens.push('Carreta(1)');
      }
    }
    if (model.idCarreta2) {
      const response = await axios.get(`/solicitacao-monitoramento/verificar-lancamento-sm`, {
        params: {
          dataInicio: toZoned(`${model.dataInicio} ${model.horaInicio}`),
          idCarreta2: model.idCarreta2,
        },
      });
      if (response.data) {
        arrayItens.push('Carreta(2)');
      }
    }
    if (arrayItens.length) {
      setItensLancamento(arrayItens.join(', '));
      setIsDialogVisibleMsgSmExiste(true);
    } else {
      setItensLancamento('');
    }
  };

  const fetchDadosVeiculosParaSat = async (idVeiculo: number | null) => {
    if (idVeiculo) {
      try {
        const response = await axios.get('/veiculo/listarDadosVeiculoParaSAT', {
          params: {
            idCliente,
            veiculo: idVeiculo,
          },
        });

        setDispositivos(response.data);
      } catch (err: any) {
        console.error(err);
      }
    }
  };

  const fetchDadosCarreta1ParaSat = async (idCarreta1: number | null) => {
    if (!idCarreta1) {
    } else {
      const response = await axios.get('/veiculo/listarDadosVeiculoParaSAT', {
        params: {
          idCliente,
          veiculo: idCarreta1,
        },
      });
      setDispositivosCarreta(response.data);
    }
  };

  const fetchDadosCarreta2ParaSat = async (idCarreta2: number) => {
    if (!idCarreta2) {
    } else {
      const response = await axios.get('/veiculo/listarDadosVeiculoParaSAT', {
        params: {
          idCliente,
          veiculo: idCarreta2,
        },
      });

      setDispositivosCarreta2(response.data);
    }
  };

  const fetchDadosBauParaSat = async (idBau: number) => {
    try {
      const response = await axios.get('/bau-intercambiavel/listarDadosBauParaSAT', {
        params: {
          bau: idBau,
        },
      });

      setDispositivosBau(response.data);
    } catch (err: any) {
      console.error(err);
    }
  };

  const validaCnh = async (id: number, isSecond: boolean) => {
    if (!id) {
      if (isSecond) {
        setCnhCondutor2Invalida(false);
        setCnhCondutor2Valida(false);
      } else {
        setCnhCondutor1Invalida(false);
        setCnhCondutor1Valida(false);
      }
      return;
    }
    try {
      const response = await axios.get(`/validacaoCNH/cnhValida?id=${id}`);

      if (isSecond) {
        setCnhCondutor2Invalida(!response.data);

        setCnhCondutor2Valida(response.data);
      } else {
        setCnhCondutor1Invalida(!response.data);
        setCnhCondutor1Valida(response.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const renderDispositivosVeiculo = (dispositivosVeiculo: DispositivoDTO) => {
    return (
      <>
        <Form.Row style={{ marginLeft: '27.5%' }}>
          <Form.Group as={Col}>
            <Form.Label style={{ fontSize: '10px' }}>
              Solicitação de Assistência Técnica Nº
            </Form.Label>
            <Form.Control
              value={dispositivosVeiculo.idSat}
              id="idSat"
              name="idSat"
              placeholder=""
              disabled
              style={{ width: '62%', fontSize: '10px' }}
            />
          </Form.Group>
        </Form.Row>
        <div
          className="table-responsive"
          style={{ width: '45%', marginLeft: '28%', marginBottom: '2%' }}
        >
          <table className="table">
            <thead className="thead">
              <tr>
                <th style={{ fontSize: '10px' }}>Dispositivo</th>
                <th style={{ fontSize: '10px' }}>Inclusão</th>
              </tr>
            </thead>
            <tbody>
              {dispositivosVeiculo.idSat &&
                dispositivosVeiculo.listaSatDispositivos
                  .filter((fil) => fil.defeito === true)
                  .map((each) => {
                    return (
                      <tr key={each.id} className="dispositivos-table">
                        <td style={{ fontSize: '10px' }}>{each.dispositivoNome}</td>
                        <td style={{ fontSize: '10px' }}>
                          {each.dtLancamento ? obterDataHora(each.dtLancamento) : ''}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const retornaTipoPara = (motivo: string) => {
    switch (motivo) {
      case '1':
        return 'Alimentação';
      case '2':
        return 'Descanso';
      case '3':
        return 'Entrega';
      case '4':
        return 'Coleta';
      case '5':
        return 'Fiscalização';
      case '9':
        return 'Outros';
      default:
        return '';
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      {/* MODAL */}
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={erroLoginUs === true || showSuccessLoginUs === true}
        onHide={() => {
          setErroLoginUs(false);
          setShowSuccessLoginUs(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {erroLoginUs === true && showSuccessLoginUs === false ? (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Usuário ou Senha incorretos!
          </Modal.Body>
        ) : (
          <>
            {showSuccessLoginUs === true && (
              <>
                <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
                  Login Confirmado com Sucesso!
                </Modal.Body>
              </>
            )}
          </>
        )}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setErroLoginUs(false);
              setShowSuccessLoginUs(false);
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={showSuccessLoginUs2 === true || erroLoginUs2 === true}
        onHide={() => {
          setShowSuccessLoginUs2(false);
          setErroLoginUs2(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {erroLoginUs2 === true ? (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Usuário ou Senha incorretos!
          </Modal.Body>
        ) : (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Login Confirmado com Sucesso!
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSuccessLoginUs2(false);
              setErroLoginUs2(false);
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={showSuccessLoginUs3 === true || erroLoginUs3 === true}
        onHide={() => {
          setShowSuccessLoginUs3(false);
          setErroLoginUs3(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {erroLoginUs3 === true ? (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Usuário ou Senha incorretos!
          </Modal.Body>
        ) : (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Login Confirmado com Sucesso!
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSuccessLoginUs3(false);
              setErroLoginUs3(false);
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={showSuccessLoginUs4 === true || erroLoginUs4 === true}
        onHide={() => {
          setShowSuccessLoginUs4(false);
          setErroLoginUs4(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {erroLoginUs4 === true ? (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Usuário ou Senha incorretos!
          </Modal.Body>
        ) : (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Login Confirmado com Sucesso!
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSuccessLoginUs4(false);
              setErroLoginUs4(false);
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={showSuccessLoginUs5 === true || erroLoginUs5 === true}
        onHide={() => {
          setShowSuccessLoginUs5(false);
          setErroLoginUs5(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>

        {erroLoginUs5 === true ? (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Usuário ou Senha incorretos!
          </Modal.Body>
        ) : (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Login Confirmado com Sucesso!
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSuccessLoginUs5(false);
              setErroLoginUs5(false);
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalAddParada
        setShow={setShowModal}
        show={showModal}
        onPress={() => handleAddParada()}
        idParada={idParada}
        setIdParada={setIdParada}
        sequencia={sequencia}
        setSequencia={setSequencia}
        motivo={motivo}
        setMotivo={setMotivo}
        local={local}
        setLocal={setLocal}
        cep={cep}
        setCep={setCep}
        logradouro={logradouro}
        setLogradouro={setLogradouro}
        logradouroNumero={logradouroNumero}
        setLogradouroNumero={setLogradouroNumero}
        logradouroComplemento={logradouroComplemento}
        setLogradouroComplemento={setLogradouroComplemento}
        bairro={bairro}
        setBairro={setBairro}
        idCodigoIbge={idCodigoIbge}
        setIdCodigoIbge={setIdCodigoIbge}
        cidade={cidade}
        setCidade={setCidade}
        setRotaIdParada={model.idRota}
        rotaIdParada={model.idRota}
      />
      {/* MODAL */}
      <MenuLateral />

      <Dialog
        header="Ajudante(1) encontra-se bloqueado. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02)."
        footer={
          <>
            <Button label="OK" onClick={() => setCpfErroAjudante(false)} />
          </>
        }
        visible={cpfErroAjudante}
        style={{ width: '50vw' }}
        modal
        onHide={() => setCpfErroAjudante(false)}
      />

      <Dialog
        header="Ajudante(2) encontra-se bloqueado. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02)."
        footer={
          <>
            <Button label="OK" onClick={() => setCpfErroAjudante2(false)} />
          </>
        }
        visible={cpfErroAjudante2}
        style={{ width: '50vw' }}
        modal
        onHide={() => setCpfErroAjudante2(false)}
      />

      <Dialog
        header="Condutor(1) encontra-se bloqueado. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02)."
        footer={
          <>
            <Button label="OK" onClick={() => setCpfErroCondutor(false)} />
          </>
        }
        visible={cpfErroCondutor}
        style={{ width: '50vw' }}
        modal
        onHide={() => setCpfErroCondutor(false)}
      />

      <Dialog
        header="Condutor(2) encontra-se bloqueado. Favor entrar em contato com nosso SAP (21) 3644-7000 (Opção 02)."
        footer={
          <>
            <Button label="OK" onClick={() => setCpfErroCondutor2(false)} />
          </>
        }
        visible={cpfErroCondutor2}
        style={{ width: '50vw' }}
        modal
        onHide={() => setCpfErroCondutor2(false)}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento</h2>
          <h6 className="subtitulo">{'Cadastro > Solicitação de Monitoramento'}</h6>
        </div>
        <Dialog
          header={
            state && state === 'confirmaSM'
              ? 'Pré SM confirmada com sucesso!'
              : 'Solicitação de Monitoramento cadastrada com sucesso!'
          }
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {preSmResults.length ? null : <p>Número SM: {idSmEnviada}</p>}
              <ButtonBootstrap onClick={() => history.push('/home')}>OK</ButtonBootstrap>
            </div>
          }
          visible={isSMLoading}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push('/home')}
        />
        <Dialog
          header="Veículo está desabilitado conforme vossa solicitação. Para voltar a programar viagens para ele solicite a ativação novamente à Golden Service. Obrigado!"
          footer={
            <Button
              label="Ok"
              onClick={() => setIsDialogVisibleVeiculoNaoRastreadoFaturamento(false)}
            />
          }
          visible={isDialogVisibleVeiculoNaoRastreadoFaturamento}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleVeiculoNaoRastreadoFaturamento(false)}
        />
        <Dialog
          header="Checklist Pendente."
          footer={<Button label="Ok" onClick={() => setIsDialogVisibleBloqueioChecklist(false)} />}
          visible={isDialogVisibleBloqueioChecklist}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleBloqueioChecklist(false)}
        />
        <Dialog
          header="Veículo com mais de 30 pontos de Não Conformidades."
          footer={
            <Button label="Ok" onClick={() => setIsDialogVisibleMsgVeiculoNaoVigente(false)} />
          }
          visible={isDialogVisibleMsgVeiculoNaoVigente}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgVeiculoNaoVigente(false)}
        />
        <Dialog
          header="Carreta 1 com mais de 30 pontos de Não Conformidades."
          footer={
            <Button label="Ok" onClick={() => setIsDialogVisibleMsgCarreta1NaoVigente(false)} />
          }
          visible={isDialogVisibleMsgCarreta1NaoVigente}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgCarreta1NaoVigente(false)}
        />
        <Dialog
          header="Carreta 2 com mais de 30 pontos de Não Conformidades."
          footer={
            <Button label="Ok" onClick={() => setIsDialogVisibleMsgCarreta2NaoVigente(false)} />
          }
          visible={isDialogVisibleMsgCarreta2NaoVigente}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgCarreta2NaoVigente(false)}
        />
        <Dialog
          header="Condutor com mais de 30 pontos de Não Conformidades."
          footer={
            <Button label="Ok" onClick={() => setIsDialogVisibleMsgCondutorNaoVigente(false)} />
          }
          visible={isDialogVisibleMsgCondutorNaoVigente}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgCondutorNaoVigente(false)}
        />
        <Dialog
          header="SAT Pendente."
          footer={
            <Button
              label="Ok"
              onClick={() => {
                setIsDialogVisibleBloqueioSat(false);
              }}
            />
          }
          visible={isDialogVisibleBloqueioSat}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleBloqueioSat(false)}
        />
        <Dialog
          header={`Já existe uma SM para ${itensLancamento} ${
            model.dataInicio !== (undefined || '') && model.horaInicio !== (undefined || '')
              ? `na data  ${obterData(model.dataInicio)} às ${model.horaInicio}.`
              : '.'
          }`}
          footer={<Button label="Ok" onClick={() => setIsDialogVisibleMsgSmExiste(false)} />}
          visible={isDialogVisibleMsgSmExiste}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgSmExiste(false)}
        />
        <Dialog
          header="Usuário não foi logado"
          footer={<Button label="Ok" onClick={() => setIsDialogVisibleUsuarioNaoLogado(false)} />}
          visible={isDialogVisibleUsuarioNaoLogado}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleUsuarioNaoLogado(false)}
        />
        <Dialog
          header="Solicitação de monitoramento cadastrada com sucesso!"
          footer={<Button onClick={() => setIsDialogVisibleMsgEmail(true)}>OK</Button>}
          visible={isDialogVisibleCadOk}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleCadOk(false)}
        />
        <Dialog
          header="Solicitação de monitoramento cadastrada com sucesso, mas não foi possivel enviar o e-mail"
          footer={<Button onClick={() => setIsDialogVisibleCadOkEmail(true)}>OK</Button>}
          visible={isDialogVisibleCadOkEmail}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleCadOkEmail(false)}
        />
        <Dialog
          header="SM não enviada. Serviço de Análise de Perfil não contratado, solicite o desmarcação da crítica de Análise de Perfil!"
          footer={<Button onClick={() => setIsDialogErroPesquisa(false)}>OK</Button>}
          visible={isDialogErroPesquisa}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogErroPesquisa(false)}
        />
        <Dialog
          header="Solicitação de monitoramento cadastrada. Falha no cálculo de previsão."
          footer={<Button onClick={() => history.push('/home')}>OK</Button>}
          visible={isDialogErroDistanceMatrix}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push('/home')}
        />
        <Dialog
          header="Solicitação de monitoramento cadastrada. Ocorreu um erro ao recuperar o histórico de posição do veículo."
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {preSmResults.length ? null : <p>Número SM: {idSmEnviada}</p>}
              <ButtonBootstrap onClick={() => history.push('/home')}>OK</ButtonBootstrap>
            </div>
          }
          visible={isDialogErroRecuperarPontos}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push('/home')}
        />
        <Dialog
          header={
            erroPonto
              ? 'Não foi possível recuperar os dados do(s) Ponto(s).'
              : 'Não foi possível enviar email de Solicitação de Monitoramento. Verifique o email cadastrado.'
          }
          footer={<Button onClick={() => history.push('/home')}>OK</Button>}
          visible={isDialogVisibleMsgEmail}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgEmail(false)}
        />
        <ModalCadastrarRotaPorSM
          show={showModalRota}
          setShow={setShowModalRota}
          loadDadosRotaIdNome={loadDadosRotaIdNome}
          isAvulsa={isRotaAvulsa}
        />
        <ModalCadastroCondutorPorSM
          show={showModalCondutor}
          setShow={setShowModalCondutor}
          loadDadosCondutorIdNome={loadDadosCondutorIdNome}
        />
        <ModalCadastroAjudantePorSM
          show={showModalAjudante}
          setShow={setShowModalAjudante}
          loadDadosAjudanteIdNome={loadDadosAjudanteIdNome}
        />
        <ModalCadastroBauPorSM show={showModalBau} setShow={setShowModalBau} loadBau={loadBau} />
        <ModalCadastroVeiculoPorSM
          showModalCadastro={showModalVeiculo}
          setShowModalCadastro={setShowModalVeiculo}
          loadVeiculos={loadVeiculos}
        />
        {progress < 99 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <p>{`${progressMessage}`}</p>
            </div>

            <ProgressBar variant="warning" animated now={progress} label={`${progress}%`} />
          </>
        ) : (
          <>
            <h1 className="info-obrigatorio">*Informações obrigatórias</h1>

            <Form noValidate validated={isFormInvalid} className="form-sm">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Veículo
                  </Form.Label>
                  <Form.Control
                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                      setPesquisaVei('')
                      resetVeiculo();
                      setModel({
                        ...model,
                        idVeiculo: parseInt(e.target.value, 10),
                      });
                      if (e.target.value.length > 0 && e.target.value !== null) {
                        await loadDadosVeiculo(parseInt(e.target.value, 10));
                      }
                    }}
                    onBlur={() => verificaLancamentoGeral()}
                    value={model?.idVeiculo}
                    as="select"
                    id="idVeiculo"
                    name="idVeiculo"
                    placeholder="Veículo"
                    disabled={loadingDadosSM === true}
                    required
                    style={{ width: '100%' }}
                  >
                    <option value="">Selecione uma opção</option>
                    {veiculoIdPlaca.map((each) => (
                      <option key={each.id} value={each.id}>
                        {each.placa}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Campo Veículo não pode ser nulo.
                  </Form.Control.Feedback>
                  {veiculoNaoRastreado && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Veículo sem rastreador cadastrado.
                    </p>
                  )}
                  {isVeiculoBloqueado && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Este veículo está bloqueado.
                    </p>
                  )}
                  {veiculoBloqueadoPontos && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Veículo com mais de 30 pontos de não conformidade.
                    </p>
                  )}
                  {pesquisaVei.trim() != '' && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaVei}</p>
                  )}
                </Form.Group>

                <i
                  style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                  className="pi pi-plus-circle icone_adicionar"
                  onClick={() => {
                    if (canInsertVeiculo) {
                      setShowModalVeiculo(true);
                    } else {
                      history.push('/acessonegado');
                    }
                  }}
                />

                <Form.Group as={Col}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    defaultValue={veiculo?.modelo}
                    id="modeloVeiculo"
                    name="modeloVeiculo"
                    placeholder=""
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    defaultValue={veiculo?.tecnologia}
                    id="tecnologiaRastreamentoVeiculo"
                    name="tecnologiaRastreamentoVeiculo"
                    placeholder=""
                    disabled
                  />
                </Form.Group>
              </Form.Row>

              {displayMsgChecklistInexistenteVeiculo && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta2">
                      <Form.Label className="txt_alerta">
                        Checklist inexistente para este Veículo.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              {displayMsgSatExistenteVeiculo && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta2">
                      <Form.Label className="txt_alerta">
                        Localizamos uma Solicitação de Assistência Técnica em aberto para este
                        veículo.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              {dispositivos?.idSat && renderDispositivosVeiculo(dispositivos)}

              {/* nao tem checklist e nao tem sat */}
              {displayMsgChecklistInexistenteVeiculo &&
                !displayMsgSatExistenteVeiculo &&
                !usuarioLogado.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {/* tem checklist e tem sat */}
              {displayMsgSatExistenteVeiculo &&
                !displayMsgChecklistInexistenteVeiculo &&
                !usuarioLogado.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e tem sat */}
              {displayMsgSatExistenteVeiculo &&
                displayMsgChecklistInexistenteVeiculo &&
                !usuarioLogado.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {mostrarCamposLoginSenha && (
                <>
                  <Form.Row className="justify-content-md-center mt-4">
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado.usuario}
                        onChange={(e) => {
                          setUsuarioLogado({ ...usuarioLogado, usuario: e.target.value });
                        }}
                        id="usuarioLogado"
                        name="usuarioLogado"
                        placeholder="Usuário"
                        maxLength={100}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado.senha}
                        onChange={(e) => {
                          setUsuarioLogado({ ...usuarioLogado, senha: e.target.value });
                        }}
                        id="senhaUsuarioLogado"
                        name="senhaUsuarioLogado"
                        placeholder="Senha"
                        type="password"
                        style={{ width: '102%' }}
                        maxLength={8}
                      />
                    </Col>
                    <AddButton
                      onClick={() => {
                        handleConfirmLogin(
                          'usuario1',
                          usuarioLogado,
                          roleUsuarioLogado,
                          codigoUsuarioLogado,
                          setUsuarioLogado
                        );
                      }}
                      check
                    />
                  </Form.Row>

                  <LinhaForm />
                </>
              )}

              {flagSolicitarOperacaoNaSM && (
                <Form.Group as={Col} controlId="formGridAddress1">
                  <Form.Label style={{ marginLeft: '-15px' }}>
                    <span className="txt-obrigatorio">*</span>Operação
                  </Form.Label>

                  <Form.Control
                    value={model.idTipoOperacaoCliente ? model.idTipoOperacaoCliente : ''}
                    onChange={(e: any) => {
                      setModel({
                        ...model,
                        idTipoOperacaoCliente: parseInt(e.target.value, 10),
                      });
                    }}
                    id="operacaoCliente"
                    as="select"
                    name="operacaoCliente"
                    placeholder=""
                    required
                    disabled={desabilitaForm}
                    style={{
                      width: '49%',
                      marginLeft: '-15px',
                      fontSize: '0.7rem',
                      height: 'calc(1.5em + 0.75rem + 2px)',
                    }}
                  >
                    <option value="">Selecione uma opção</option>
                    {listaTiposOperacoesCliente.map((lista) => (
                      <option value={lista.tocCdId} key={lista.tocCdId}>
                        {lista.tocTxDescricao}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Row>
                {flagSolicitarFrotaNaSM && (
                  <Form.Group as={Col} controlId="formGridAddress1">
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>Frota
                    </Form.Label>

                    <Form.Control
                      value={model.solicitarFrotaCliente}
                      onChange={(e: any) => {
                        setModel({
                          ...model,
                          solicitarFrotaCliente: e.target.value,
                        });
                      }}
                      id="solicitarFrotaCliente"
                      name="solicitarFrotaCliente"
                      placeholder=""
                      required
                      disabled={desabilitaForm}
                      style={{ width: '99.5%' }}
                      maxLength={64}
                    />
                  </Form.Group>
                )}

                {flagSolicitarNumeroIdentificadorNaSM && (
                  <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '38px' }}>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>Nº Identificador
                    </Form.Label>

                    <Form.Control
                      value={model.solicitarIdentificadorCliente}
                      onChange={(e: any) => {
                        setModel({
                          ...model,
                          solicitarIdentificadorCliente: e.target.value,
                        });
                      }}
                      id="solicitarIdentificadorCliente"
                      name="solicitarIdentificadorCliente"
                      placeholder=""
                      required
                      disabled={desabilitaForm}
                      style={{ width: '99.5%' }}
                      maxLength={64}
                    />
                  </Form.Group>
                )}
              </Form.Row>

              {carretaVisible && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>Carreta(1)
                    </Form.Label>
                    <Form.Control
                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        resetCarreta1();
                        setModel({
                          ...model,
                          idCarreta: parseInt(e.target.value, 10),
                        });
                        if (e.target.value.length > 0 && e.target.value !== null) {
                          await loadDadosCarreta(parseInt(e.target.value, 10));
                        }
                      }}
                      onBlur={() => verificaLancamentoGeral()}
                      value={model.idCarreta}
                      as="select"
                      id="idCarreta"
                      name="idCarreta"
                      placeholder=""
                      required
                      disabled={
                        !!loadingDadosSM ||
                        (desabilitaForm && displayMsgChecklistInexistenteVeiculo) ||
                        (desabilitaForm && displayMsgSatExistenteVeiculo)
                      }
                    >
                      <option value="">Selecione uma opção</option>
                      {carretaIdPlaca.map((each) => (
                        <option key={each.id} value={each.id}>
                          {each.placa}
                        </option>
                      ))}
                    </Form.Control>
                    {isCarretaBloqueada && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Esta carreta está bloqueada.
                      </p>
                    )}
                    {carretaBloqueadoPontos && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Carreta(1) com mais de 30 pontos de não conformidade.
                      </p>
                    )}
                    {pesquisaCar1.trim() != '' && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaCar1}</p>
                    )}
                  </Form.Group>
                  <Link to="/cadastro/carretas/cadastrar/true">
                    <i
                      style={{
                        fontSize: '1.2em',
                        marginLeft: 5,
                        marginRight: 20,
                        cursor: 'pointer',
                      }}
                      className="pi pi-plus-circle icone_adicionar"
                    />
                  </Link>
                  <Form.Group as={Col}>
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      defaultValue={model.idCarreta === null ? '' : carreta?.modelo}
                      id="nomeTipoVeiculoCarreta"
                      name="nomeTipoVeiculoCarreta"
                      placeholder=""
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
              )}

              {displayMsgSatExistenteCarreta1 && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta1">
                      <Form.Label className="txt_alerta">
                        Localizamos uma Solicitação de Assistência Técnica em aberto para esta
                        carreta.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              {dispositivosCarreta?.idSat && renderDispositivosVeiculo(dispositivosCarreta)}

              {displayMsgChecklistInexistenteCarreta && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta1">
                      <Form.Label className="txt_alerta">
                        Checklist inexistente para esta carreta.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              {/*  tem checklist e tem sat  */}
              {displayMsgSatExistenteCarreta1 &&
                !displayMsgChecklistInexistenteCarreta &&
                !usuarioLogado2.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha2 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha2}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e nao tem sat */}
              {displayMsgChecklistInexistenteCarreta &&
                !displayMsgSatExistenteCarreta1 &&
                !usuarioLogado2.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha2 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha2}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e tem sat */}
              {displayMsgSatExistenteCarreta1 &&
                displayMsgChecklistInexistenteCarreta &&
                !usuarioLogado2.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha2 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha2}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {mostrarCamposLoginSenha2 && (
                <>
                  <Form.Row className="justify-content-md-center mt-4">
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado2.usuario}
                        onChange={(e) => {
                          setUsuarioLogado2({ ...usuarioLogado2, usuario: e.target.value });
                        }}
                        id="usuarioLogado2"
                        name="usuarioLogado2"
                        placeholder="Usuário"
                        maxLength={100}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado2.senha}
                        onChange={(e) => {
                          setUsuarioLogado2({ ...usuarioLogado2, senha: e.target.value });
                        }}
                        id="senhaUsuarioLogado2"
                        name="senhaUsuarioLogado2"
                        placeholder="Senha"
                        type="password"
                        style={{ width: '102%' }}
                        maxLength={8}
                      />
                    </Col>
                    <AddButton
                      onClick={() => {
                        handleConfirmLogin(
                          'usuario2',
                          usuarioLogado2,
                          roleUsuarioLogado,
                          codigoUsuarioLogado,
                          setUsuarioLogado2
                        );
                      }}
                      check
                    />
                  </Form.Row>

                  <LinhaForm />
                </>
              )}

              {carretaVisible && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Carreta(2)</Form.Label>
                    <Form.Control
                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        resetCarreta2();
                        setModel({
                          ...model,
                          idCarreta2: parseInt(e.target.value, 10),
                        });
                        if (e.target.value.length > 0 && e.target.value !== null) {
                          await loadDadosCarreta2(parseInt(e.target.value, 10));
                        }
                      }}
                      onBlur={() => verificaLancamentoGeral()}
                      value={model.idCarreta2}
                      as="select"
                      id="idCarreta2"
                      name="idCarreta2"
                      placeholder=""
                      disabled={
                        !!loadingDadosSM ||
                        (desabilitaForm && displayMsgChecklistInexistenteVeiculo) ||
                        (desabilitaForm && displayMsgChecklistInexistenteCarreta) ||
                        (desabilitaForm && displayMsgSatExistenteVeiculo) ||
                        (desabilitaForm && displayMsgSatExistenteCarreta1)
                      }
                    >
                      <option value="">Selecione uma opção</option>
                      {carreta2IdPlaca
                        .filter((filcar) => filcar.id != idCarreta1)
                        .map((each) => (
                          <option key={each.id} value={each.id}>
                            {each.placa}
                          </option>
                        ))}
                    </Form.Control>
                    {isCarreta2Bloqueada && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Esta carreta está bloqueada.
                      </p>
                    )}
                    {carreta2BloqueadoPontos && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Carreta(2) com mais de 30 pontos de não conformidade.
                      </p>
                    )}
                    {pesquisaCar2.trim() != '' && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaCar2}</p>
                    )}
                  </Form.Group>
                  <Link to="/cadastro/carretas/cadastrar/true">
                    <i
                      style={{
                        fontSize: '1.2em',
                        marginLeft: 5,
                        marginRight: 20,
                        cursor: 'pointer',
                      }}
                      className="pi pi-plus-circle icone_adicionar"
                    />
                  </Link>
                  <Form.Group as={Col}>
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control
                      defaultValue={model.idCarreta2 === null ? '' : carreta2?.modelo}
                      id="nomeTipoVeiculoCarreta2"
                      name="nomeTipoVeiculoCarreta2"
                      placeholder=""
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
              )}

              {displayMsgSatExistenteCarreta2 && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta2">
                      <Form.Label className="txt_alerta">
                        Localizamos uma Solicitação de Assistência Técnica em aberto para esta
                        carreta.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              {dispositivosCarreta2?.idSat &&
                model.idCarreta2 &&
                renderDispositivosVeiculo(dispositivosCarreta2)}
              {displayMsgChecklistInexistenteCarreta2 && (
                <Form>
                  <LinhaForm />
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistCarreta2">
                      <Form.Label className="txt_alerta">
                        Checklist inexistente para esta carreta.
                        <br />
                        Esta viagem não é recomendada pela Golden Service!
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}
              {/* tem checklist e tem sat */}
              {displayMsgSatExistenteCarreta2 &&
                !displayMsgChecklistInexistenteCarreta2 &&
                !usuarioLogado3.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha3 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha3}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {/* nao tem checklist e nao tem sat */}
              {displayMsgChecklistInexistenteCarreta2 &&
                !displayMsgSatExistenteCarreta2 &&
                !usuarioLogado3.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha3 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha3}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e tem sat */}
              {displayMsgSatExistenteCarreta2 &&
                displayMsgChecklistInexistenteCarreta2 &&
                !usuarioLogado3.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha3 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha3}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {mostrarCamposLoginSenha3 && (
                <>
                  <Form.Row className="justify-content-md-center mt-4">
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado3.usuario}
                        onChange={(e) => {
                          setUsuarioLogado3({ ...usuarioLogado3, usuario: e.target.value });
                        }}
                        id="usuarioLogado3"
                        name="usuarioLogado3"
                        placeholder="Usuário"
                        maxLength={100}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado3.senha}
                        onChange={(e) => {
                          setUsuarioLogado3({ ...usuarioLogado3, senha: e.target.value });
                        }}
                        id="senhaUsuarioLogado3"
                        name="senhaUsuarioLogado3"
                        placeholder="Senha"
                        type="password"
                        style={{ width: '102%' }}
                        maxLength={8}
                      />
                    </Col>
                    <AddButton
                      onClick={() => {
                        handleConfirmLogin(
                          'usuario3',
                          usuarioLogado3,
                          roleUsuarioLogado,
                          codigoUsuarioLogado,
                          setUsuarioLogado3
                        );
                      }}
                      check
                    />
                  </Form.Row>

                  <LinhaForm />
                </>
              )}

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Baú Intercambiável</Form.Label>
                  <Form.Control
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 0 && e.target.value !== null) {
                        loadDadosBau(parseInt(e.target.value, 10));
                      }
                      // fetchDadosBauParaSat(parseInt(e.target.value, 10));
                      setModel({
                        ...model,
                        idBau: parseInt(e.target.value, 10),
                      });
                    }}
                    onBlur={() => verificaLancamentoGeral()}
                    value={model.idBau === null ? '' : model.idBau}
                    as="select"
                    id="idBau"
                    name="idBau"
                    placeholder=""
                    disabled={
                      (desabilitaForm && displayMsgChecklistInexistenteVeiculo) ||
                      (desabilitaForm && displayMsgChecklistInexistenteCarreta) ||
                      (desabilitaForm && displayMsgChecklistInexistenteCarreta2) ||
                      (desabilitaForm && displayMsgSatExistenteVeiculo) ||
                      (desabilitaForm && displayMsgSatExistenteCarreta1) ||
                      (desabilitaForm && displayMsgSatExistenteCarreta2)
                    }
                  >
                    <option value="">Selecione uma opção</option>
                    {bauIdNumero.map((each) => (
                      <option key={each.id} value={each.id}>
                        {each.numero}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <i
                  style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                  className="pi pi-plus-circle icone_adicionar"
                  onClick={() => setShowModalBau(true)}
                />

                <Form.Group as={Col}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control
                    defaultValue={model.idBau === null ? '' : bau?.numeroBau}
                    id="numeroBau"
                    name="numeroBau"
                    placeholder=""
                    disabled
                  />
                </Form.Group>
              </Form.Row>
              <LinhaForm />
              {displayMsgSatExistenteBau && (
                <Form.Row className="justify-content-md-center">
                  <Form.Group controlId="formGridAlertaChecklistCarreta2">
                    <Form.Label className="txt_alerta">
                      Localizamos uma Solicitação de Assistência Técnica em aberto para este baú.
                      <br />
                      Esta viagem não é recomendada pela Golden Service!
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
              )}
              {dispositivosBau?.idSat && renderDispositivosVeiculo(dispositivosBau)}

              {displayMsgChecklistInexistenteBau && (
                <Form.Row className="justify-content-md-center">
                  <Form.Group controlId="formGridAlertaChecklistCarreta2">
                    <Form.Label className="txt_alerta">
                      Checklist inexistente para este bau.
                      <br />
                      Esta viagem não é recomendada pela Golden Service!
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
              )}

              {/* tem checklist e sat */}
              {displayMsgSatExistenteBau &&
                !displayMsgChecklistInexistenteBau &&
                !usuarioLogado5.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha5 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha5}
                          style={{ marginBottom: '15px' }}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e nao tem sat */}
              {displayMsgChecklistInexistenteBau &&
                !displayMsgSatExistenteBau &&
                !usuarioLogado5.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha5 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha5}
                          style={{ marginBottom: '15px' }}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}
              {/* nao tem checklist e tem sat */}
              {displayMsgChecklistInexistenteBau &&
                displayMsgSatExistenteBau &&
                !usuarioLogado5.isLogado && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          value={mostrarCamposLoginSenha5 ? 'Sim' : 'Não'}
                          onChange={handleMostrarCamposLoginSenha5}
                          style={{ marginBottom: '15px' }}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {mostrarCamposLoginSenha5 && (
                <>
                  <Form.Row className="justify-content-md-center mt-4">
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado5.usuario}
                        onChange={(e) => {
                          setUsuarioLogado5({ ...usuarioLogado5, usuario: e.target.value });
                        }}
                        id="usuarioLogado5"
                        name="usuarioLogado5"
                        placeholder="Usuário"
                        maxLength={100}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado5.senha}
                        onChange={(e) => {
                          setUsuarioLogado5({ ...usuarioLogado5, senha: e.target.value });
                        }}
                        id="senhaUsuarioLogado5"
                        name="senhaUsuarioLogado5"
                        placeholder="Senha"
                        type="password"
                        style={{ width: '102%' }}
                        maxLength={8}
                      />
                    </Col>
                    <AddButton
                      onClick={() => {
                        handleConfirmLogin(
                          'usuario5',
                          usuarioLogado5,
                          roleUsuarioLogado,
                          codigoUsuarioLogado,
                          setUsuarioLogado5
                        );
                      }}
                      check
                    />
                  </Form.Row>
                  <LinhaForm />
                </>
              )}

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Início da Viagem
                  </Form.Label>
                  <Form.Control
                    value={
                      dataInicioPreSm ? dataInicioPreSm.toString().split('T')[0] : model.dataInicio
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // onChangeInicioViagem(e.target.value);
                      if (e.target.value.length <= 10) {
                        setModel({
                          ...model,
                          dataInicio: e.target.value,
                        });
                      }
                      verificaDataViagem(
                        e.target.value,
                        model.horaInicio,
                        model.dataFim,
                        model.horaFim
                      );
                    }}
                    onBlur={() => verificaLancamentoGeral()}
                    id="dataInicioSM"
                    name="dataInicioSM"
                    type="date"
                    placeholder=""
                    required
                    disabled={
                      state === 'confirmaSM' ||
                      !!dataInicioPreSm ||
                      (desabilitaForm && displayMsgChecklistInexistenteVeiculo) ||
                      (desabilitaForm && displayMsgSatExistenteVeiculo)
                    }
                  />
                  {erroDataInicial && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Data inicial da viagem não pode ser menor que a data atual.
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Campo Início da viagem não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Hora de Início da Viagem
                  </Form.Label>
                  {/* TODO: criar validação que impeça a inserção de horas anteriores à parametrização (30min) */}
                  <Form.Control
                    value={
                      horaInicioPreSm
                        ? horaInicioPreSm.toString().split(/[T,.]/)[1]
                        : model.horaInicio
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        horaInicio: e.target.value,
                      });
                      verificaDataViagem(
                        model.dataInicio,
                        e.target.value,
                        model.dataFim,
                        model.horaFim
                      );
                    }}
                    onBlur={() => {
                      verificaLancamentoGeral();
                      if (erroHoraAntecendencia === true) {
                        if (!parametros) return;
                        addToast({
                          title: 'Info',
                          description: `Horário de início da viagem deve ter uma antecedência de ${parametros.antecedenciaInclusaoSmEmMinutos} minutos a partir do horário de cadastro.`,
                          type: 'info',
                        });
                      }
                    }}
                    id="horaInicio"
                    name="horaInicio"
                    type="time"
                    placeholder=""
                    required
                    disabled={
                      state === 'confirmaSM' ||
                      !model.dataInicio ||
                      erroDataInicial ||
                      desabilitaForm
                    }
                  />
                  {erroHoraAntecendencia && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Horário de início da viagem inválida.
                    </p>
                  )}
                  {model.horaInicio && erroHoraInicialFim && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Horário de início não pode ser maior que o horário final.
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Campo Hora início da viagem não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Término da Viagem
                  </Form.Label>
                  <Form.Control
                    value={dataFimPreSm ? dataFimPreSm.toString().split('T')[0] : model.dataFim}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length <= 10) {
                        setModel({
                          ...model,
                          dataFim: e.target.value,
                        });
                      }
                      verificaDataViagem(
                        model.dataInicio,
                        model.horaInicio,
                        e.target.value,
                        model.horaFim
                      );
                      // onChangeTerminoViagem(e.target.value)
                    }}
                    id="dataFim"
                    name="dataFim"
                    type="date"
                    placeholder=""
                    required
                    disabled={state === 'confirmaSM' || !model.dataInicio || desabilitaForm}
                  />
                  {erroDataFinal && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Data final da viagem não pode ser menor do que a data inicial da viagem.
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Campo Término da viagem não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Hora de Término da Viagem
                  </Form.Label>
                  <Form.Control
                    value={horaFimPreSm ? horaFimPreSm.toString().split(/[T,.]/)[1] : model.horaFim}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // const validation = isHourLessThanBaseHour({
                      //   baseDate: model.dataInicio,
                      //   baseHour: model.horaInicio,
                      //   date: model.dataFim,
                      //   hour: e.target.value,
                      // });
                      // setErroHoraFinal(validation);

                      setModel({
                        ...model,
                        horaFim: e.target.value,
                      });
                      verificaDataViagem(
                        model.dataInicio,
                        model.horaInicio,
                        model.dataFim,
                        e.target.value
                      );
                    }}
                    id="horaFim"
                    name="horaFim"
                    type="time"
                    placeholder=""
                    required
                    disabled={
                      state === 'confirmaSM' || !model.dataFim || erroDataFinal || desabilitaForm
                    }
                  />
                  {erroHoraFinal && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Horário final da viagem não pode ser menor do que o horário inicial.
                    </p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Campo Hora término da viagem não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <LinhaForm />

              {(checklistVencido ||
                checklistVencidoCar1 ||
                checklistVencidoCar2 ||
                checklistVencidoBau) && (
                <>
                  <Form.Row className="justify-content-md-center">
                    <Form.Group controlId="formGridAlertaChecklistVeiculo">
                      <Form.Label className="txt_alerta">
                        {checklistVencido &&
                          'Veículo com Checklist vencido / com defeito. Esta viagem não é recomendada pela Golden Service!'}
                        {checklistVencidoCar1 &&
                          'Carreta(1) com Checklist vencido / com defeito. Esta viagem não é recomendada pela Golden Service!'}
                        {checklistVencidoCar2 &&
                          'Carreta(2) com Checklist vencido / com defeito. Esta viagem não é recomendada pela Golden Service!'}
                        {checklistVencidoBau &&
                          'Baú com Checklist vencido / com defeito. Esta viagem não é recomendada pela Golden Service!'}
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              {!usuarioLogado4.isLogado &&
                (checklistVencido ||
                  checklistVencidoCar1 ||
                  checklistVencidoCar2 ||
                  checklistVencidoBau) && (
                  <>
                    <Form.Row className="justify-content-md-center">
                      <Form.Group controlId="formGridAlertaChecklist">
                        <Form.Label className="txt_alerta">
                          Mesmo com as considerações citadas, deseja utilizá-lo nesta viagem?
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="justify-content-md-center">
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          id="options"
                          defaultValue="Não"
                          onChange={handleMostrarCamposLoginSenha4}
                          disabled={desabilitaForm}
                        >
                          <option>Não</option>
                          <option>Sim</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                  </>
                )}

              {mostrarCamposLoginSenha4 && (
                <>
                  <Form.Row className="justify-content-md-center mt-4">
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado4.usuario}
                        onChange={(e) => {
                          setUsuarioLogado4({ ...usuarioLogado4, usuario: e.target.value });
                        }}
                        id="usuarioLogado4"
                        name="usuarioLogado4"
                        placeholder="Usuário"
                        maxLength={100}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className="justify-content-md-center mt-3" style={{ marginLeft: '2%' }}>
                    <Col md={3}>
                      <Form.Control
                        value={usuarioLogado4.senha}
                        onChange={(e) => {
                          setUsuarioLogado4({ ...usuarioLogado4, senha: e.target.value });
                        }}
                        id="senhaUsuarioLogado4"
                        name="senhaUsuarioLogado4"
                        placeholder="Senha"
                        type="password"
                        style={{ width: '102%' }}
                        maxLength={8}
                      />
                    </Col>
                    <AddButton
                      onClick={() => {
                        handleConfirmLogin(
                          'usuario4',
                          usuarioLogado4,
                          roleUsuarioLogado,
                          codigoUsuarioLogado,
                          setUsuarioLogado4
                        );
                      }}
                      check
                    />
                  </Form.Row>

                  <LinhaForm />
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Regras Apólice/ DDR/ Embarcador
                  </Form.Label>
                  <Form.Control
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setIsClienteEmbarcador(false);
                      loadDadosEmbarcador(parseInt(e.target.value, 10));
                      setModel({
                        ...model,
                        idEmbarcador: parseInt(e.target.value, 10),
                        valorCarga: null,
                      });
                      setIsRotaEmbarcador(false);
                      setIsRotaPorPontos(false);
                      setEmbarcadorMudou(true);
                    }}
                    value={model.idEmbarcador}
                    as="select"
                    id="idEmbarcador"
                    name="idEmbarcador"
                    placeholder=""
                    required
                    disabled={desabilitaForm || !!preSmResults.length}
                  >
                    <option />
                    {embarcadorIdRazaoSocial.map((each) => (
                      <option key={each.idEmbarcador} value={each.idEmbarcador}>
                        {each.razaoSocial}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Campo Embarcador não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <LinhaForm />

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Condutor
                  </Form.Label>
                  <Form.Control
                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                      resetCondutor();
                      setModel({
                        ...model,
                        idCondutor: parseInt(e.target.value, 10),
                      });
                      if (e.target.value.length > 0 && e.target.value !== null) {
                        await loadDadosCondutor(parseInt(e.target.value, 10), false);
                        validaCnh(parseInt(e.target.value, 10), false);
                      }

                      if (validaCondutor === true) {
                        setIsDialogVisibleMsgSmExiste(true);
                      } else {
                        setIsDialogVisibleMsgSmExiste(false);
                      }
                    }}
                    onBlur={() => verificaLancamentoGeral()}
                    value={model.idCondutor}
                    as="select"
                    id="idCondutor"
                    name="idCondutor"
                    placeholder=""
                    required
                    disabled={desabilitaForm}
                  >
                    <option />
                    {condutorIdNome.map((each) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Campo Condutor não pode ser nulo.
                  </Form.Control.Feedback>
                  {cnhCondutor1Invalida && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Condutor com CNH vencida.
                    </p>
                  )}
                  {isCondutorBloqueado && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Este condutor está bloqueado.
                    </p>
                  )}
                  {condutorBloqueadoPontos && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Condutor com mais de 30 pontos de não conformidade.
                    </p>
                  )}
                  {pesquisaCond.trim() != '' && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaCond}</p>
                  )}
                </Form.Group>

                <i
                  style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                  className="pi pi-plus-circle icone_adicionar"
                  onClick={() => {
                    if (canInsertCondutor) {
                      setShowModalCondutor(true);
                    } else {
                      history.push('/acessonegado');
                    }
                  }}
                />
              </Form.Row>
              {model.idCondutor ? (
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Condutor (2)</Form.Label>
                    <Form.Control
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCnhCondutor2Invalida(false)
                        setIsCondutor2Bloqueado(false)
                        setPesquisaCond2('')

                        if (e.target.value.length > 0 && e.target.value !== null) {
                          loadDadosCondutor(parseInt(e.target.value, 10), true);
                          validaCnh(parseInt(e.target.value, 10), true);
                        }
                        setModel({
                          ...model,
                          idCondutor2: parseInt(e.target.value, 10),
                        });
                      }}
                      onBlur={() => verificaLancamentoGeral()}
                      value={model.idCondutor2}
                      as="select"
                      id="idCondutor2"
                      name="idCondutor2"
                      placeholder=""
                      disabled={desabilitaForm}
                    >
                      <option />
                      {condutorIdNome.map((each) => (
                        <option key={each.id} value={each.id}>
                          {each.nome}
                        </option>
                      ))}
                    </Form.Control>
                    {cnhCondutor2Invalida && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Condutor com CNH vencida.
                      </p>
                    )}
                    {isCondutor2Bloqueado && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Este condutor está bloqueado.
                      </p>
                    )}
                    {pesquisaCond2.trim() != '' && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaCond2}</p>
                    )}
                  </Form.Group>
                  <i
                    style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                    className="pi pi-plus-circle icone_adicionar"
                    onClick={() => setShowModalCondutor(true)}
                  />
                </Form.Row>
              ) : null}

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Ajudante</Form.Label>
                  <Form.Control
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setIsAjudanteBloqueado(false)
                      setPesquisaAju('')

                      if (e.target.value.length > 0 && e.target.value !== null) {
                        loadDadosAjudante(parseInt(e.target.value, 10), false);
                      }
                      setModel({
                        ...model,
                        idAjudante: parseInt(e.target.value, 10),
                      });
                    }}
                    onBlur={() => verificaLancamentoGeral()}
                    value={model.idAjudante}
                    as="select"
                    id="idAjudante"
                    name="idAjudante"
                    placeholder=""
                    disabled={desabilitaForm}
                  >
                    <option />
                    {ajudanteIdNome.map((each) => (
                      <option key={each.id} value={each.id}>
                        {each.nome}
                      </option>
                    ))}
                  </Form.Control>
                  {isAjudanteBloqueado && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Este ajudante está bloqueado.
                    </p>
                  )}
                  {pesquisaAju.trim() != '' && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaAju}</p>
                  )}
                </Form.Group>

                <i
                  style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                  className="pi pi-plus-circle icone_adicionar"
                  onClick={() => {
                    if (canInsertAjudante) {
                      setShowModalAjudante(true);
                    } else {
                      history.push('/acessonegado');
                    }
                  }}
                />
              </Form.Row>

              {model.idAjudante ? (
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Ajudante (2)</Form.Label>
                    <Form.Control
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setIsAjudante2Bloqueado(false)
                        setPesquisaAju2('')

                        if (e.target.value.length > 0 && e.target.value !== null) {
                          loadDadosAjudante(parseInt(e.target.value, 10), true);
                        }
                        setModel({
                          ...model,
                          idAjudante2: parseInt(e.target.value, 10),
                        });
                      }}
                      onBlur={() => verificaLancamentoGeral()}
                      value={model.idAjudante2}
                      as="select"
                      id="idAjudante"
                      name="idAjudante"
                      placeholder=""
                      disabled={desabilitaForm}
                    >
                      <option />
                      {ajudanteIdNome.map((each) => (
                        <option key={each.id} value={each.id}>
                          {each.nome}
                        </option>
                      ))}
                    </Form.Control>
                    {isAjudante2Bloqueado && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Este ajudante está bloqueado.
                      </p>
                    )}
                    {pesquisaAju2.trim() != '' && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{pesquisaAju2}</p>
                    )}
                  </Form.Group>

                  <i
                    style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20, cursor: 'pointer' }}
                    className="pi pi-plus-circle icone_adicionar"
                    onClick={() => setShowModalAjudante(true)}
                  />
                </Form.Row>
              ) : null}

              <LinhaForm />

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Operação
                  </Form.Label>
                  <Form.Control
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleTipoOperacaoChange(e)}
                    value={model.tipoOperacao}
                    as="select"
                    id="tipoOperacao"
                    name="tipoOperacao"
                    placeholder=""
                    required
                    disabled={desabilitaForm}
                  >
                    <option />
                    {tipoOperacaoSMIdDescricao.map((each) => (
                      <option key={each.id} value={each.id}>
                        {each.descricao}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Campo Operação não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Valor da Carga
                  </Form.Label>
                  <Form.Control
                    onChange={(e: any) => {
                      const value = toCurrency(soNumeros(e.target.value));
                      handleValorCargaChange(Number(toDecimal(value)));

                      if (e.target.value === 0 || e.target.value === null || !e.target.value) {
                        setValorCargaChanged(true);
                      } else {
                        setValorCargaChanged(false);
                      }

                      if (!e.target.value) {
                        setErroValorCarga(false);
                      }
                    }}
                    value={toCurrency(model.valorCarga)}
                    id="valorCarga"
                    name="valorDaCarga"
                    placeholder=""
                    required
                    maxLength={21}
                    disabled={desabilitaForm}
                    isInvalid={valorCargaChanged}
                  />
                  {valorCargaMinimoErro && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Valor da carga abaixo do limite permitido
                    </p>
                  )}
                  {erroValorCarga && model.valorCarga > 1 && (
                    <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                      Valor da carga acima do limite permitido para o Embarcador
                    </p>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Campo Valor da Carga não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>
                    <span className="txt-obrigatorio">*</span>Descrição da Carga
                  </Form.Label>
                  <Form.Control
                    value={model.descricaoCarga}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    id="descricaoCarga"
                    name="descricaoCarga"
                    placeholder=""
                    required
                    maxLength={256}
                    disabled={desabilitaForm}
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo Descrição da carga não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              {/* {temperaturaVisible && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>
                      Temperatura
                    </Form.Label>
                    <Form.Control
                      onChange={(e: any) => {
                        setModel({ ...model, temperatura: soNumeros(e.target.value) });
                      }}
                      value={model.temperatura}
                      required
                      max={300}
                      maxLength={3}
                      disabled={desabilitaForm}
                    />
                    <Form.Control.Feedback type="invalid">
                      Temperatura não pode ser nulo.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              )} */}


                {(servicosContratados?.controleTemperatura == 1 && vehicleTemperatureVinculed) && (
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Temperatura mínima
                      </Form.Label>
                      <Form.Control
                        onChange={(e: any) => {
                          setModel({ ...model, temperaturaMinima: e.target.value });
                          if (e.target.value > 99 || e.target.value < -99) {
                            setErroTemperaturaMinima(true);
                          } else {
                            setErroTemperaturaMinima(false);
                          }
                        }}
                        value={model.temperaturaMinima}
                        id="temperaturaMinina"
                        maxLength={3}
                        max={99}
                        min={-99}
                        name="temperaturaMinina"
                        placeholder="ºC"
                        type="number"
                        required
                        disabled={desabilitaForm}
                      />
                      {erroTemperaturaMinima && model.temperaturaMinima && (
                        <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                          Temperatura mínima não pode ser maior que 99° ou menor que -99°.
                        </p>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Temperatura mínima não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Temperatura máxima
                      </Form.Label>
                      <Form.Control
                        onChange={(e: any) => {
                          setModel({ ...model, temperaturaMaxima: e.target.value });
                          if (
                            e.target.value > 99 ||
                            e.target.value < -99 ||
                            e.target.value < model.temperaturaMinima
                          ) {
                            setErroTemperaturaMaxima(true);
                          } else {
                            setErroTemperaturaMaxima(false);
                          }
                        }}
                        value={model.temperaturaMaxima}
                        id="temperaturaMaxima"
                        name="temperaturaMaxima"
                        maxLength={3}
                        max={99}
                        min={-99}
                        placeholder="ºC"
                        required
                        type="number"
                        disabled={desabilitaForm}
                      />
                      {erroTemperaturaMaxima && model.temperaturaMaxima && (
                        <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                          Temperatura máxima não pode ser maior que 99°, menor que -99° ou ser
                          menor/igual que a temperatura mínima.
                        </p>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Temperatura máxima não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                ) }

              {(servicosContratados?.controleVelocidade == 1 && vehicleSpeedVinculed) && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>
                      Velocidade máxima
                    </Form.Label>
                    <Form.Control
                      onChange={(e: any) => {
                        setModel({ ...model, velocidadeMaxima: Number(soNumeros(e.target.value)) });
                        if (soNumeros(e.target.value) > 140) {
                          setErroVelocidadeMaxima(true);
                        } else {
                          setErroVelocidadeMaxima(false);
                        }
                      }}
                      value={model.velocidadeMaxima}
                      id="velocidadeMaxima"
                      name="velocidadeMaxima"
                      placeholder="km/h"
                      required
                      max={140}
                      maxLength={3}
                      disabled={desabilitaForm}
                    />
                    {erroVelocidadeMaxima && model.velocidadeMaxima && (
                      <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                        Velocidade máxima não pode ser maior que 140km/h.
                      </p>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Velocidade máxima não pode ser nulo.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              )}

              <LinhaForm />

              {flagObrigatoriedadeEscolta && mostrarCamposEscolta ? (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                    <Form.Label>Adicionar Escolta?</Form.Label>
                    <Form.Control placeholder="Sim" type="text" disabled />
                  </Form.Group>
                </Form.Row>
              ) : (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                    <Form.Label>Adicionar Escolta?</Form.Label>
                    <Form.Control
                      as="select"
                      id="options"
                      disabled={desabilitaForm}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSelecionarCamposEscolta(e);
                        setModel({
                          ...model,
                          idTecnologiaEscolta: '',
                          idTipoComunicacaoEscolta: '',
                          veiculoEscolta: null,
                          flagMonitoramentoEscoltaGs: false,
                          numeroEquipamentoTecnologiaEscolta: null,
                          empresaEscolta: null,
                          telEscolta: null,
                          agenteEscolta: null,
                          agente2Escolta: null,
                          telAgente2Escolta: null,
                          telAgenteEscolta: null,
                        });
                      }}
                    >
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              {(flagObrigatoriedadeEscolta && mostrarCamposEscolta) || camposEscolta ? (
                <>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Veículo Escolta
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.veiculoEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length < 7) {
                            setErroPlacaEscolta(true);
                          } else {
                            setErroPlacaEscolta(false);
                          }
                          if (validarPlaca(e.target.value)) {
                            updateModel(e);
                          }
                        }}
                        id="veiculoEscolta"
                        name="veiculoEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                      />{' '}
                      {erroPlacaEscolta ? (
                        <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>Placa inválida</p>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Monitorado pela GS?
                      </Form.Label>
                      <Form.Control
                        as="select"
                        id="options"
                        defaultValue={model.flagMonitoramentoEscoltaGs}
                        required
                        disabled={desabilitaForm}
                        onChange={(e) =>
                          setModel({ ...model, flagMonitoramentoEscoltaGs: e.target.value === '1' })
                        }
                      >
                        <option value="" />
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Tecnologia Escolta</Form.Label>
                      <Form.Control
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleTecnologiaEscolta(e)}
                        value={model.idTecnologiaEscolta}
                        as="select"
                        id="idTecnologiaEscolta"
                        name="idTecnologiaEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                      >
                        <option value="0">Selecione uma opção</option>
                        {tecnologiaRastreamentoIdNome.map((each) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Comunicação Escolta</Form.Label>
                      <Form.Control
                        value={model.idTipoComunicacaoEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            idTipoComunicacaoEscolta: parseInt(e.target.value, 10),
                          });
                        }}
                        as="select"
                        id="idTipoComunicacaoEscolta"
                        name="idTipoComunicacaoEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                      >
                        <option value="0">Selecione uma opção</option>
                        {tipoComunicacaoEscolta.map((each) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Nº Equipamento Escolta
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.numeroEquipamentoTecnologiaEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setModel({
                            ...model,
                            [e.target.name]: soNumeros(e.target.value),
                          })
                        }
                        id="numeroEquipamentoTecnologiaEscolta"
                        name="numeroEquipamentoTecnologiaEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                        maxLength={15}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Empresa Escolta
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.empresaEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                        id="empresaEscolta"
                        name="empresaEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                        maxLength={60}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Telefone Contato
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.telEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setModel({
                            ...model,
                            telEscolta: maskTelefone(e.target.value),
                          })
                        }
                        id="telEscolta"
                        name="telEscolta"
                        placeholder=""
                        maxLength={15}
                        disabled={desabilitaForm}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Agente Escolta
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.agenteEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setModel({
                            ...model,
                            agenteEscolta: soLetras(e.target.value),
                          })
                        }
                        id="agenteEscolta"
                        name="agenteEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                        maxLength={60}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Telefone Contato Agente
                      </Form.Label>
                      <Form.Control
                        required={mostrarCamposEscolta || camposEscolta}
                        value={model.telAgenteEscolta}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setModel({
                            ...model,
                            telAgenteEscolta: maskTelefone(e.target.value),
                          })
                        }
                        id="telAgenteEscolta"
                        name="telAgenteEscolta"
                        placeholder=""
                        disabled={desabilitaForm}
                      />
                    </Form.Group>
                  </Form.Row>
                  {model.agenteEscolta ? (
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Agente Escolta (2)</Form.Label>
                        <Form.Control
                          value={model.agente2Escolta}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setModel({
                              ...model,
                              agente2Escolta: soLetras(e.target.value),
                            })
                          }
                          id="agenteEscolta2"
                          name="agenteEscolta2"
                          placeholder=""
                          disabled={desabilitaForm}
                          maxLength={60}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Telefone Contato Agente (2)</Form.Label>
                        <Form.Control
                          value={model.telAgente2Escolta}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setModel({
                              ...model,
                              telAgente2Escolta: maskTelefone(e.target.value),
                            })
                          }
                          id="telAgenteEscolta2"
                          name="telAgenteEscolta2"
                          placeholder=""
                          disabled={desabilitaForm}
                        />
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  {/*
                TODO: Confirmar com GS a qtde de agentes de escolta
                */}
                </>
              ) : null}

              <LinhaForm />

              {flagObrigatoriedadeIsca ? (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoIsca">
                    <Form.Label>Adicionar Isca?</Form.Label>
                    <Form.Control
                      type="text"
                      id="options"
                      onChange={() => handleMostrarCamposIsca()}
                      placeholder="Sim"
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
              ) : (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                    <Form.Label>Adicionar Isca?</Form.Label>
                    <Form.Control
                      as="select"
                      id="options"
                      disabled={desabilitaForm}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSelecionarCamposIsca(e);
                        setModel({
                          ...model,
                          flagIsca: false,
                          iscaNumEquipamento: null,
                          iscaSite: null,
                          iscaSiteLogin: null,
                          iscaSiteSenha: null,
                          idTipoComunicacaoIsca: '',
                          idTecnologiaIsca: '',
                        });
                      }}
                    >
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              {/* TODO: Incluir verificação sobre a viagem exigir uso de Isca */}
              {flagObrigatoriedadeIsca || camposIsca ? (
                <>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Marca Isca
                      </Form.Label>
                      <Form.Control
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleTecnologiaIsca(e)}
                        value={model.idTecnologiaIsca}
                        as="select"
                        id="idTecnologiaIsca"
                        name="idTecnologiaIsca"
                        required
                        disabled={desabilitaForm}
                      >
                        <option />
                        {tecnologiaRastreamentoIdNome.map((each) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Campo Marca Isca não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Comunicação Isca
                      </Form.Label>
                      <Form.Control
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            idTipoComunicacaoIsca: parseInt(e.target.value, 10),
                          });
                        }}
                        value={model.idTipoComunicacaoIsca}
                        as="select"
                        id="idTipoComunicacaoIsca"
                        name="idTipoComunicacaoIsca"
                        placeholder=""
                        required
                        disabled={desabilitaForm}
                      >
                        <option />
                        {tipoComunicacaoIsca.map((each) => (
                          <option key={each.id} value={each.id}>
                            {each.nome}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Campo Comunicação isca não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Nº Equipamento Isca
                      </Form.Label>
                      <Form.Control
                        value={model.iscaNumEquipamento}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setModel({
                            ...model,
                            [e.target.name]: soNumeros(e.target.value),
                          })
                        }
                        id="iscaNumEquipamento"
                        name="iscaNumEquipamento"
                        placeholder=""
                        required
                        disabled={desabilitaForm}
                        maxLength={15}
                      />
                      <Form.Control.Feedback type="invalid">
                        Campo Nº Equipamento isca não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Site
                      </Form.Label>
                      <Form.Control
                        value={model.iscaSite}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                        id="iscaSite"
                        name="iscaSite"
                        placeholder=""
                        required
                        disabled={desabilitaForm}
                        maxLength={60}
                      />
                      <Form.Control.Feedback type="invalid">
                        Campo Site não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Login
                      </Form.Label>
                      <Form.Control
                        value={model.iscaSiteLogin}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                        id="iscaSiteLogin"
                        name="iscaSiteLogin"
                        placeholder=""
                        required
                        disabled={desabilitaForm}
                        maxLength={32}
                      />
                      <Form.Control.Feedback type="invalid">
                        Campo Login não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Senha
                      </Form.Label>
                      <Form.Control
                        value={model.iscaSiteSenha}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                        id="iscaSiteSenha"
                        name="iscaSiteSenha"
                        placeholder=""
                        required
                        disabled={desabilitaForm}
                        maxLength={32}
                      />
                      <Form.Control.Feedback type="invalid">
                        Campo Senha não pode ser nulo.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </>
              ) : null}
              <LinhaForm />

              {isClienteEmbarcador && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                      <Form.Label>Utilizar Rota do Embarcador?</Form.Label>
                      <Form.Control
                        as="select"
                        id="options"
                        value={preSmResults.length ? '1' : undefined}
                        disabled={desabilitaForm || !!preSmResults.length}
                        onChange={(e: any) => {
                          if (e.target.value === '1') {
                            setIsRotaPorPontos(true);
                            setIsRotaEmbarcador(true);
                            setModel((prevState) => ({
                              ...prevState,
                              paradaVO: [],
                            }));
                            setModel({
                              ...model,
                              idRota: null,
                            });
                            setSelectedRota(undefined);
                          }
                          if (e.target.value === '0') {
                            setIsRotaPorPontos(false);
                            setIsRotaEmbarcador(false);
                            setTipoRota(0);
                            setPontoOrigem({
                              onlyDataPonto: '',
                              onlyTimePonto: '',
                              dataPonto: '',
                              idPonto: null,
                              idSm: null,
                              idSmPv: null,
                              idUsuario,
                              remessa: '',
                              sequencia: 0,
                              tipoPonto: '',
                            });
                          }
                        }}
                      >
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </>
              )}
              {flagServicoRotaPorPontosContratado && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                      <Form.Label>
                        <span className="txt-obrigatorio">*</span>Tipo de Rota
                      </Form.Label>
                      <Form.Control
                        as="select"
                        id="options"
                        value={tipoRota}
                        disabled={desabilitaForm || !!preSmResults.length}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setTipoRota(parseInt(e.target.value));
                          if (e.target.value === '0') {
                            setIsRotaPorPontos(false);
                            setIsRotaAvulsa(false);
                            setPontoOrigem({
                              onlyDataPonto: '',
                              onlyTimePonto: '',
                              dataPonto: '',
                              idPonto: null,
                              idSm: null,
                              idSmPv: null,
                              idUsuario,
                              remessa: '',
                              sequencia: 0,
                              tipoPonto: '',
                            });
                          } else if (e.target.value === '1') {
                            setIsRotaPorPontos(true);
                            setIsRotaAvulsa(false);
                            setModel({
                              ...model,
                              idRota: null,
                            });
                          } else if (e.target.value === '2') {
                            setIsRotaPorPontos(false);
                            setIsRotaAvulsa(true);
                            setShowModalRota(true);
                          } else {
                            setIsRotaPorPontos(false);
                          }
                        }}
                      >
                        {isRotaEmbarcador ? (
                          <>
                            <option value="1">Por pontos</option>
                          </>
                        ) : (
                          <>
                            <option>Selecione</option>
                            <option value="0">Pré-cadastradas</option>
                            <option value="1">Por pontos</option>
                            <option value="2">Avulsa</option>
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              {state !== 'confirmaSM' &&
              !preSmResults.length &&
              (tipoRota === 0 || (tipoRota === 2 && selectedRota)) &&
              !isRotaPorPontos &&
              !isRotaEmbarcador ? (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>
                      <span className="txt-obrigatorio">*</span>Rota
                    </Form.Label>
                    <Form.Control
                      value={model.idRota}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setModel({
                          ...model,
                          idRota: parseInt(e.target.value, 10),
                        });
                        setSequencia(0);
                      }}
                      as="select"
                      id="idRota"
                      name="idRota"
                      placeholder="Selecione uma opção"
                      required
                      disabled={desabilitaForm || isRotaAvulsa}
                    >
                      <option />
                      {rotaIdNome.map((each) => (
                        <option key={each.id} value={each.id}>
                          {each.nome}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Campo Rota não pode ser nulo.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!isRotaAvulsa ? (
                    <i
                      style={{ fontSize: '1.2em', cursor: 'pointer' }}
                      className="pi pi-plus-circle icone_adicionar"
                      onClick={() => {
                        if (canInsertRota) {
                          setShowModalRota(true);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    />
                  ) : null}

                  <Form.Group as={Col}>
                    <Form.Label>Rota Origem</Form.Label>
                    <Form.Control
                      value={
                        selectedRota
                          ? `${selectedRota.logradouroOrigem}${
                              selectedRota.complementoOrigem ? ', ' : ' '
                            }${selectedRota.complementoOrigem} - ${selectedRota.bairroOrigem}. ${
                              selectedRota?.cidadeOrigem
                            }, ${getUfEndereco(selectedRota.idUFOrigem)} - CEP: ${
                              selectedRota.cepOrigem
                            }`
                          : ''
                      }
                      id="enderecoOrigem"
                      name="enderecoOrigem"
                      placeholder=""
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Rota Destino</Form.Label>
                    <Form.Control
                      value={
                        selectedRota
                          ? `${selectedRota.logradouroDestino}${
                              selectedRota.complementoDestino ? ', ' : ' '
                            }${selectedRota.complementoDestino} - ${selectedRota.bairroDestino}. ${
                              selectedRota?.cidadeDestino
                            }, ${getUfEndereco(selectedRota.idUFDestino)} - CEP: ${
                              selectedRota.cepDestino
                            }`
                          : ''
                      }
                      id="enderecoOrigem"
                      name="enderecoOrigem"
                      placeholder=""
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
              ) : null}

              {((tipoRota === 1 && flagServicoRotaPorPontosContratado) ||
                (isRotaPorPontos && state !== 'confirmaSM')) &&
                !preSmResults.length && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <span className="txt-obrigatorio">*</span>Ponto Origem
                        </Form.Label>
                        <Form.Control
                          value={pontoOrigem.idPonto!}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPontoOrigem({
                              ...pontoOrigem,
                              idPonto: parseInt(e.target.value, 10),
                            });
                          }}
                          as="select"
                          id="idPontoOrigem"
                          name="idPontoOrigem"
                          placeholder="Selecione uma opção"
                          style={{ width: '108%' }}
                          required
                        >
                          <option />
                          {sortBy(pontosCliente, 'nomePonto')
                            .filter((fil) => fil.ativo === true)
                            .map((each) => {
                              return (
                                <option key={each.idPonto} value={each.idPonto}>
                                  {each.nomePonto}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="formGridAddress1"
                        style={{ marginLeft: '38px' }}
                      >
                        <Form.Label>
                          {flagSolicitarNumeroRemessaNaSM && (
                            <span className="txt-obrigatorio">*</span>
                          )}
                          Nº Remessa
                        </Form.Label>
                        <Form.Control
                          type="number"
                          value={pontoOrigem.remessa}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPontoOrigem({
                              ...pontoOrigem,
                              remessa: e.target.value,
                            });
                          }}
                          id="solicitarIdentificadorCliente"
                          name="solicitarIdentificadorCliente"
                          placeholder=""
                          required={flagSolicitarNumeroRemessaNaSM}
                          style={{ width: '99.5%' }}
                          maxLength={20}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          <span className="txt-obrigatorio">*</span>Data
                        </Form.Label>
                        <Form.Control
                          value={
                            dataOrigemMudou
                              ? pontoOrigem.onlyDataPonto
                              : (pontoOrigem.onlyDataPonto = model.dataInicio)
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > 10) {
                            } else {
                              setPontoOrigem({
                                ...pontoOrigem,
                                onlyDataPonto: e.target.value ?? model.dataInicio,
                              });
                              setDataOrigemMudou(true);
                              const horaAtual = new Date().toLocaleTimeString().slice(0, -3);
                              if (pontoOrigem.onlyTimePonto) {
                                const v1 = isHourLessThanBaseHour({
                                  baseDate: obterDataAtual(),
                                  date: e.target.value,
                                  hour: pontoOrigem.onlyTimePonto,
                                  baseHour: horaAtual,
                                });
                                setErroDataPonto(v1 ? `Data/Hora menor que a data atual.` : '');
                              }
                              if (model.solicitacaoMonitoramentoPlanoViagemVO[0]) {
                                const arr = erroDataPontoIntermediario;

                                const v1 = isHourLessThanBaseHour({
                                  baseDate: e.target.value,
                                  date: model.solicitacaoMonitoramentoPlanoViagemVO[0]
                                    .onlyDataPonto,
                                  hour: model.solicitacaoMonitoramentoPlanoViagemVO[0]
                                    .onlyTimePonto,
                                  baseHour: pontoOrigem.onlyTimePonto,
                                });
                                arr[0] = v1 ? `Data/Hora menor que Ponto Origem` : '';

                                setErroDataPontoIntermediario(arr);
                              }
                            }
                          }}
                          id="dataPonto"
                          name="dataPonto"
                          type="date"
                          placeholder=""
                          required
                          maxLength={8}
                        />
                        <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{erroDataPonto}</p>
                        <Form.Control.Feedback type="invalid">
                          Campo Data do Ponto Origem não pode ser nulo.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>
                          <span className="txt-obrigatorio">*</span>Hora
                        </Form.Label>
                        <Form.Control
                          value={
                            horaOrigemMudou
                              ? pontoOrigem.onlyTimePonto
                              : (pontoOrigem.onlyTimePonto = model.horaInicio)
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setPontoOrigem({
                              ...pontoOrigem,
                              onlyTimePonto: e.target.value,
                            });
                            setHoraOrigemMudou(true);

                            if (pontoOrigem.onlyDataPonto) {
                              const horaAtual = new Date().toLocaleTimeString().slice(0, -3);
                              const v1 = isHourLessThanBaseHour({
                                baseDate: obterDataAtual(),
                                date: pontoOrigem.onlyDataPonto,
                                hour: e.target.value,
                                baseHour: horaAtual,
                              });
                              setErroDataPonto(v1 ? `Data/Hora menor que a data atual.` : '');
                            }

                            if (model.solicitacaoMonitoramentoPlanoViagemVO[0]) {
                              const arr = erroDataPontoIntermediario;

                              const v1 = isHourLessThanBaseHour({
                                baseDate: pontoOrigem.onlyDataPonto,
                                date: model.solicitacaoMonitoramentoPlanoViagemVO[0].onlyDataPonto,
                                hour: model.solicitacaoMonitoramentoPlanoViagemVO[0].onlyTimePonto,
                                baseHour: e.target.value,
                              });
                              arr[0] = v1 ? `Data/Hora menor que Ponto Origem` : '';

                              setErroDataPontoIntermediario(arr);
                            }
                          }}
                          id="horaPonto"
                          name="horaPonto"
                          type="time"
                          placeholder=""
                          required
                        />
                        <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{erroDataPonto}</p>
                        <Form.Control.Feedback type="invalid">
                          Campo Hora término da viagem não pode ser nulo.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}

              {model.solicitacaoMonitoramentoPlanoViagemVO &&
                (adicionaPontosIntermediarios || adicionaPontosIntermediariosApartirPontoOrigem) &&
                isRotaPorPontos &&
                model.solicitacaoMonitoramentoPlanoViagemVO.map((ponto, index) => {
                  return (
                    <>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {index === 0 && <span className="txt-obrigatorio">*</span>}
                            Ponto({index + 2})
                          </Form.Label>
                          <Form.Control
                            value={ponto.idPonto!}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const planoViagem = model.solicitacaoMonitoramentoPlanoViagemVO;
                              planoViagem[index] = {
                                ...planoViagem[index],
                                idPonto: parseInt(e.target.value, 10),
                              };
                              setModel((prevState) => ({
                                ...prevState,
                                solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
                              }));
                              setIdPontoPreenchido(true);
                            }}
                            as="select"
                            id="idRota"
                            name="idRota"
                            placeholder="Selecione uma opção"
                            style={{ width: '108%' }}
                            required
                          >
                            <option value="" />
                            {sortBy(pontosCliente, 'nomePonto')
                              .filter((fil) => fil.ativo === true)
                              .map((each) => (
                                <option key={each.idPonto} value={each.idPonto}>
                                  {each.nomePonto}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          controlId="formGridAddress1"
                          style={{ marginLeft: '38px' }}
                        >
                          <Form.Label>
                            {flagSolicitarNumeroRemessaNaSM && (
                              <span className="txt-obrigatorio">*</span>
                            )}
                            Nº Remessa
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={ponto.remessa}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const planoViagem = model.solicitacaoMonitoramentoPlanoViagemVO;
                              planoViagem[index] = {
                                ...planoViagem[index],
                                remessa: e.target.value,
                              };
                              setModel((prevState) => ({
                                ...prevState,
                                solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
                              }));
                              setRemessaPreenchida(true);
                            }}
                            id="solicitarIdentificadorCliente"
                            name="solicitarIdentificadorCliente"
                            placeholder=""
                            required={flagSolicitarNumeroRemessaNaSM}
                            style={{ width: '99.5%' }}
                            maxLength={20}
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            {ponto.idPonto ? <span className="txt-obrigatorio">*</span> : ''}
                            Data
                          </Form.Label>
                          <Form.Control
                            value={ponto.onlyDataPonto}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > 10) {
                              } else {
                                const planoViagem = model.solicitacaoMonitoramentoPlanoViagemVO;
                                planoViagem[index] = {
                                  ...planoViagem[index],
                                  onlyDataPonto: e.target.value,
                                };
                                setModel((prevState) => ({
                                  ...prevState,
                                  solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
                                }));
                                setDataPreenchida(true);

                                verifPontos(
                                  e.target.value,
                                  e.target.value,
                                  model.solicitacaoMonitoramentoPlanoViagemVO[index].onlyTimePonto,
                                  model.solicitacaoMonitoramentoPlanoViagemVO[index].onlyTimePonto,
                                  index
                                );
                              }
                            }}
                            id="dataPonto"
                            name="dataPonto"
                            type="date"
                            placeholder=""
                            required={ponto.idPonto != null}
                          />
                          <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                            {erroDataPontoIntermediario[index]}
                          </p>
                          {/* {erroDataPontoInterm[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroDataPontoInterm[index]}
                            </p>
                          )}
                          {erroDataPontoInterm2[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroDataPontoInterm2[index]}
                            </p>
                          )}

                          {erroDataPontoInterm3[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroDataPontoInterm3[index]}
                            </p>
                          )}

                          {erroDataPontoOrigemMaior && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              Data do Ponto (2) não pode ser menor do que a data do Ponto Origem.`
                            </p>
                          )} */}

                          <Form.Control.Feedback type="invalid">
                            Campo Término da viagem não pode ser nulo.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {ponto.idPonto ? <span className="txt-obrigatorio">*</span> : ''}
                            Hora
                          </Form.Label>
                          <Form.Control
                            value={ponto.onlyTimePonto}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const planoViagem = model.solicitacaoMonitoramentoPlanoViagemVO;
                              planoViagem[index] = {
                                ...planoViagem[index],
                                onlyTimePonto: e.target.value,
                              };
                              setModel((prevState) => ({
                                ...prevState,
                                solicitacaoMonitoramentoPlanoViagemVO: planoViagem,
                              }));
                              setHoraPreenchida(true);
                              verifPontos(
                                model.solicitacaoMonitoramentoPlanoViagemVO[index].onlyDataPonto,
                                model.solicitacaoMonitoramentoPlanoViagemVO[index].onlyDataPonto,
                                e.target.value,
                                e.target.value,
                                index
                              );
                            }}
                            id="horaPonto"
                            name="horaPonto"
                            type="time"
                            placeholder=""
                            required={ponto.idPonto != null}
                          />
                          <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                            {erroDataPontoIntermediario[index]}
                          </p>
                          {/* {erroHoraFinal && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              Horário final da viagem não pode ser menor do que o horário inicial.
                            </p>
                          )}

                          {erroHoraPontoInterm[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroHoraPontoInterm[index]}
                            </p>
                          )}
                          {erroHoraPontoInterm2[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroHoraPontoInterm2[index]}
                            </p>
                          )}

                          {erroHoraPontoInterm3[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroHoraPontoInterm3[index]}
                            </p>
                          )}

                          {erroHoraPontoIntermMenor[index] && (
                            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
                              {erroHoraPontoIntermMenor[index]}
                            </p>
                          )} */}

                          <Form.Control.Feedback type="invalid">
                            Campo Hora término da viagem não pode ser nulo.
                          </Form.Control.Feedback>
                        </Form.Group>

                        {index > 0 ? (
                          <AddButton
                            onClick={() => {
                              removeInput(index);
                              setModel((prevState) => ({
                                ...prevState,
                              }));
                            }}
                            removerPonto
                          />
                        ) : null}
                      </Form.Row>
                    </>
                  );
                })}
              {isRotaPorPontos && state !== 'confirmaSM' && (
                <Form.Row>
                  <AddButton
                    onClick={(event) => {
                      setModel((prevState) => ({
                        ...prevState,
                      }));
                      addNewInput(event);
                    }}
                    title="Adicionar Pontos ?"
                    adicionarPonto
                  />
                </Form.Row>
              )}

              {isConfirmPreSM &&
                preSmResults.map((ponto, index) => {
                  return (
                    <>
                      <Form.Row>
                        <Form.Group as={Col}>
                          {index === 0 ? (
                            <Form.Label>
                              <span className="txt-obrigatorio">*</span>Ponto Origem
                            </Form.Label>
                          ) : index === 1 ? (
                            <Form.Label> Ponto Destino</Form.Label>
                          ) : (
                            <Form.Label> Ponto({index + 1})</Form.Label>
                          )}
                          <Form.Control
                            value={ponto.nomePonto}
                            id="idRota"
                            name="idRota"
                            placeholder="Selecione uma opção"
                            style={{ width: '108%' }}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          controlId="formGridAddress1"
                          style={{ marginLeft: '38px' }}
                        >
                          <Form.Label>
                            {flagSolicitarNumeroRemessaNaSM && (
                              <span className="txt-obrigatorio">*</span>
                            )}
                            Nº Remessa
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={ponto.remessa}
                            id="solicitarIdentificadorCliente"
                            name="solicitarIdentificadorCliente"
                            placeholder=""
                            disabled
                            style={{ width: '99.5%' }}
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>
                            {ponto.idPonto ? <span className="txt-obrigatorio">*</span> : ''}
                            Data
                          </Form.Label>
                          <Form.Control
                            value={`${new Date(ponto.agendamento).toLocaleDateString('pt-br', {
                              day: '2-digit',
                              month: '2-digit',
                              year: '2-digit',
                            })}`}
                            id="dataPonto"
                            name="dataPonto"
                            // type="date"
                            placeholder=""
                            disabled
                          />

                          <Form.Control.Feedback type="invalid">
                            Campo Término da viagem não pode ser nulo.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {ponto.idPonto ? <span className="txt-obrigatorio">*</span> : ''}
                            Hora
                          </Form.Label>
                          <Form.Control
                            value={`${ponto.agendamento.toString().slice(11, -13)}`}
                            id="horaPonto"
                            name="horaPonto"
                            // type="time"
                            placeholder=""
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            Campo Hora término da viagem não pode ser nulo.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </>
                  );
                })}

              <LinhaForm />

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Observações</Form.Label>
                  <Form.Control
                    value={model.obs}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    id="obs"
                    name="obs"
                    placeholder=""
                    maxLength={500}
                    disabled={desabilitaForm}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Romaneio</Form.Label>
                  <Form.Control
                    value={model.romaneio}
                    as="textarea"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    id="romaneio"
                    name="romaneio"
                    placeholder=""
                    maxLength={5000}
                    disabled={desabilitaForm}
                  />
                </Form.Group>
              </Form.Row>

              <LinhaForm />

              {!isConfirmPreSM ? (
                <>
                  <Form.Row className="justify-content-md-left ml-1">
                    <Form.Group controlId="formGridAlertaChecklist">
                      <Form.Label>Roteirização</Form.Label>
                    </Form.Group>
                  </Form.Row>

                  {loadingParadas ? (
                    <Spinner />
                  ) : (
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          <th>#</th>
                          <th>Motivo</th>
                          <th>Local</th>
                          <th>Endereço</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {!model.idRota && model.paradaVO.length > 0
                          ? model.paradaVO
                              .filter((fil, index) => index > 0)
                              .map((parada, index) => (
                                <tr>
                                  <td>{parada.sequencia}</td>
                                  <td>{retornaTipoPara(parada.motivo)}</td>
                                  <td>{parada.local}</td>
                                  <td>{parada.logradouro}</td>
                                  <td>
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        width: 20,
                                        height: 20,
                                        padding: 0,
                                        marginRight: 15,
                                        marginBottom: 2,
                                        marginTop: '-35px',
                                      }}
                                      onClick={() => {
                                        removeParada(index);
                                        setModel((prevState) => ({
                                          ...prevState,
                                        }));
                                      }}
                                    >
                                      <i
                                        title="Remover Parada"
                                        className="pi pi-minus-circle icone_adicionar"
                                        style={{
                                          color: 'red',
                                          fontSize: '1.2em',
                                          borderColor: 'white',
                                        }}
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))
                          : model.paradaVO.map((parada, index) => (
                              <tr>
                                <td>{parada.sequencia}</td>
                                <td>{retornaTipoPara(parada.motivo)}</td>
                                <td>{parada.local}</td>
                                <td>{parada.logradouro}</td>
                                <td>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderColor: 'transparent',
                                      width: 20,
                                      height: 20,
                                      padding: 0,
                                      marginRight: 15,
                                      marginBottom: 2,
                                      marginTop: '-35px',
                                    }}
                                    onClick={() => {
                                      removeParada(index);
                                      setModel((prevState) => ({
                                        ...prevState,
                                      }));
                                    }}
                                  >
                                    <i
                                      title="Remover Parada"
                                      className="pi pi-minus-circle icone_adicionar"
                                      style={{
                                        color: 'red',
                                        fontSize: '1.2em',
                                        borderColor: 'white',
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  )}

                  <Form.Row>
                    <AddButton
                      onClick={() => {
                        setShowModal(true);
                        setMotivo('');
                        setCep('');
                        setBairro('');
                        setLogradouro('');
                        setLogradouroComplemento('');
                        setLogradouroNumero(0);
                        setLocal('');
                        setIdCodigoIbge(0);
                        setCidade('');
                        if (!model.idRota) {
                          setSequencia(sequencia + 1);
                          if (sequencia === ultimaSeqLista) {
                            setSequencia(sequencia + 1);
                          }
                          if (sequencia > ultimaSeqLista) {
                            setSequencia(1);
                          }
                        } else {
                          if (sequencia === ultimaSeqLista) {
                            setSequencia(sequencia + 1);
                          }
                          if (model.paradaVO.length === 0) {
                            setSequencia(1);
                          } else {
                            setSequencia(ultimaSeqLista + 1);
                          }
                        }
                      }}
                      title="Adicionar Parada ?"
                      adicionarPonto
                    />
                  </Form.Row>
                </>
              ) : null}

              <LinhaForm />

              {!flagAnalisePerfil ? (
                <Form.Row className="justify-content-md-center">
                  <Form.Group controlId="formGridAlertaChecklist">
                    <Form.Label className="txt_alerta">{model?.obsPesquisa}</Form.Label>
                  </Form.Group>
                </Form.Row>
              ) : null}
            </Form>

            {loadingInsert === true ? (
              <Spinner />
            ) : loadingParadas === false ? (
              <Form.Row className="container-buttons justify-content-end">
                <Form.Group as={Col} md={2}>
                  <ButtonBootstrap
                    className="btn-cancelar"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancelar
                  </ButtonBootstrap>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <ButtonBootstrap
                    className="btn-enviar"
                    disabled={
                      !!(erroHoraAntecendencia || erroHoraFinal || erroDataFinal || erroDataInicial)
                    }
                    type="submit"
                    onClick={(e: any) => {
                      if (canInsert) {
                        insertSM(e);
                      } else {
                        history.push('/acessonegado');
                      }
                    }}
                  >
                    Confirmar
                  </ButtonBootstrap>
                </Form.Group>
              </Form.Row>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SolicitacaoMonitoramentoCadastro;
