/* eslint-disable */

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactExport from 'react-data-export';
import Pagination from './Pagination/Pagination'
import './styles.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Search from '../../components/Search/Search';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { handleResultado } from '../../util/validaResultadoPesquisa';
import { validaVigencia } from '../../util/validaVigenciaPesquisa';
import { SecundaryButton } from '../../components/ButtonsForm/styles';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterData, obterDataHora } from '../../util/date';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import * as ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import api from '../../config/axiosMaquina';
export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  idCliente?: number;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

type PropsType = {
  data: ListagemHistoricoPesquisa[];
  canEdit?: boolean;
  canDelete?: boolean;
  setData: any;
  lastPage: any;
  currentPage: any;
  setRefresh: any;
  setRefreshitensPerPage: any
  totalItems: any
  itensPerPage: any
  setItensPerPage: any
  handleGoToDetails: any
  handleGoToEditarPesquisa: any
  setSearchValue: any
  handleDelete: any
  setIsDialogVisibleConfirmarExclusao: any
  isDialogVisibleConfirmarExclusao: any
  setIsDialogVisibleExclusaoEfetuada: any
  isDialogVisibleExclusaoEfetuada: any
  isLoading: any
  search: any
  setSearch: any
};

const XlsxGeneration = async (search: any) => {
  const workbook = new ExcelJS.Workbook()

  searchTable(search, workbook)

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    FileSaver.saveAs(blob, 'pesquisa.xlsx')
  })
}

const searchTable = (data: any, workbook: any) => {
  const worksheet = workbook.addWorksheet('PESQUISAS')

  worksheet.columns = [
    { header: 'Nº', key: 'numLiberacao', width: 30 },
    { header: 'Empresa', key: 'empresa', width: 30 },
    { header: 'Item Pesquisado', key: 'itemPesquisado', width: 30 },
    { header: 'Descrição', key: 'descricao', width: 30 },
    { header: 'Tipo', key: 'tipo', width: 30 },
    { header: 'Operação', key: 'operacao', width: 30 },
    { header: 'Usuário', key: 'usuario', width: 30 },
    { header: 'Lançamento', key: 'dataLancamento', width: 30 },
    { header: 'Retorno', key: 'dataRetorno', width: 30 },
    { header: 'Validade', key: 'validaVigencia', width: 30 },
    { header: 'Resultado', key: 'resultado', width: 30 },
    { header: 'Repetição', key: 'repeticao', width: 30 },
  ]

  worksheet.eachRow({ includeEmpty: true }, (row: any) => {
    row.height = 25
  })

  data.forEach((pesquisa: any, i: any) => {
    const arrayListaItens = pesquisa.itens;

    const sameOperation = data.filter((each: any) => {
      const dateFormatted = moment(new Date(pesquisa.dataLancamento)).format('YYYY-MM-DD');
      const dateFormatted2 = moment(new Date(each.dataLancamento)).format('YYYY-MM-DD');

      return each.operacao.includes('Pesquisa') && dateFormatted === dateFormatted2;
    });

    const filterByItemSearchAndDateMatches = pesquisa.itens.flatMap((item: any) => {
      return sameOperation.flatMap((each: any) => {
        return each.itens.filter((item2: any) => item.itemPesquisado === item2.itemPesquisado);
      });
    });

    const filtered =
      filterByItemSearchAndDateMatches?.length > 1 ? filterByItemSearchAndDateMatches : null;

    // Determine indices of items in the filtered array
    const indicesToMark = filtered
      ? filtered.map((item: any) => data.findIndex((d: any) => d.itens.includes(item))).slice(1)
      : [];

    const sameNumLiberationAndOperation = data.filter(
      (each: any) =>
        each.numLiberacao === pesquisa.numLiberacao && each.operacao === pesquisa.operacao
    );

    if (arrayListaItens.length > 0) {
      arrayListaItens.map((each: any, idx: any) => {
        const index = sameNumLiberationAndOperation.findIndex((item: any) => item === pesquisa);
        const operacaoIndex = index !== -1 ? index + 1 : 1;

        worksheet.addRow({
          numLiberacao: pesquisa.numLiberacao ? pesquisa.numLiberacao : '',
          empresa: pesquisa.empresa ? pesquisa.empresa : '',
          itemPesquisado: each.itemPesquisado ? each.itemPesquisado : '',
          descricao: each.descricao ? each.descricao : '',
          tipo: each.tipo ? each.tipo : '',
          operacao: pesquisa.operacao.includes('Integrada') ? `${pesquisa.operacao} (${operacaoIndex})` : pesquisa.operacao,
          usuario: pesquisa.usuario ? pesquisa.usuario : '',
          dataLancamento: pesquisa.dataLancamento ? obterDataHora(pesquisa.dataLancamento, true) : '',
          dataRetorno: handleResultado(pesquisa.itens) != 'Em Andamento' ? obterDataHora(each.dataRetorno, true) || '' : '',
          validaVigencia: handleResultado(pesquisa.itens) === 'Em Acordo' ? validaVigencia(pesquisa) || '' : '',
          resultado: pesquisa.resultado ? handleResultado(pesquisa.itens) : '',
          repeticao: indicesToMark.includes(i) ? 'X' : ''
        })

      });
    }
  });
}

const TablePesquisa = ({ search, setSearch, isLoading, isDialogVisibleExclusaoEfetuada, setIsDialogVisibleExclusaoEfetuada, isDialogVisibleConfirmarExclusao, setIsDialogVisibleConfirmarExclusao, handleDelete, setSearchValue, handleGoToEditarPesquisa, handleGoToDetails, itensPerPage, setItensPerPage, totalItems, setRefreshitensPerPage, setRefresh, currentPage, lastPage, data, canEdit, canDelete,  setData }: PropsType) => {
  const history = useHistory();
  const { request }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingXlsx, setLoadingXlsx] = useState(false)


  const [numLiberacao, setNumLiberacao] = useState<number | undefined>();
  const [idPesquisa, setIdPesquisa] = useState<number | undefined>();

  const handleXmlRequest = async ({ request, itensPerPage, page, keyword }: { request: string, itensPerPage: string, page: string, keyword: string}) => {
    setLoadingXlsx(true)

    const arrayRequest = JSON.parse(request);

    let res: any[] = [];

    const arrayRequestData = [arrayRequest[0]];

    for (let i = 0; i < arrayRequestData.length; i++) {
      try {
        const response = await api.post('/pesquisa/historico-gr', {
          ...arrayRequestData[i],
          environmentTypeGr: true,
          pagina: page,
          qtdRegistros: itensPerPage ? Number(itensPerPage) : 10,
          ...(keyword && { keyword }),
          });
        res = [...res, ...response.data.items];

        XlsxGeneration(res)
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingXlsx(false)
      }
  }}

  const paginate = (pageNumber: number) => {
    history.push({
      pathname: `/consultar-pesquisas-lancadas/${request}/${pageNumber}`,
    });
  };

  return (
    <div>
      <Dialog
        header={`Deseja excluir a pesquisa de nº ${numLiberacao}?`}
        footer={
          <>
            <Button label="Sim" onClick={() => handleDelete(numLiberacao)} />
            <Button label="Não" onClick={() => setIsDialogVisibleConfirmarExclusao(false)} />
          </>
        }
        visible={isDialogVisibleConfirmarExclusao}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleConfirmarExclusao(false)}
      />

      <Dialog
        header="Exclusão efetuada com sucesso!"
        footer={
          <>
            <Button
              label="OK"
              onClick={() => {
                setIsDialogVisibleConfirmarExclusao(false);
                setIsDialogVisibleExclusaoEfetuada(false);
              }}
            />
          </>
        }
        visible={isDialogVisibleExclusaoEfetuada}
        style={{ width: '50vw' }}
        modal
        onHide={() => window.location.reload()}
      />

      <div className="content-container">
        <div className="painel">
          <Search
            disabled={!data}
            setLoading={setLoading}
            setSearch={setSearch}
            setSearchValue={setSearchValue}
            search={search}
          />

          {
             isLoading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) :
            (
              <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Nº</th>
                    <th>Empresa</th>
                    <th>Item Pesquisado</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Usuário</th>
                    <th>Lançamento</th>
                    <th>Retorno</th>
                    <th>Validade</th>
                    <th>Resultado</th>
                    <th style={{ width: 130 }} />
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((each) => (
                      // each.itens.map((item) => (
                      <tr
                        className={`${
                          handleResultado(each.itens) === 'Análise Vencida'
                            ? 'alert-row'
                            : handleResultado(each.itens) === 'Pendente de Documentação'
                            ? 'alert-row'
                            : handleResultado(each.itens) === 'Em Andamento'
                            ? ''
                            : handleResultado(each.itens) === 'Em Acordo'
                            ? 'success-row'
                            : ''
                        }`}
                      >
                        <td>{each.numLiberacao}</td>
                        <td>{each.empresa}</td>
                        {each.operacao.includes('Integrada') ? (
                          <td
                            style={{
                              wordWrap: 'break-word',
                              fontSize: each.itens[3]
                                ? '10px'
                                : each.itens[2]
                                ? '11px'
                                : each.itens[1]
                                ? '12px'
                                : '',
                            }}
                          >
                            {each.itens[0] ? (
                              <>
                                {each.itens[0].itemPesquisado}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[1] ? (
                              <>
                                {each.itens[1].itemPesquisado}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[2] ? (
                              <>
                                {each.itens[2].itemPesquisado}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[3] ? (
                              <>
                                {each.itens[3].itemPesquisado}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        ) : (
                          <td>{each.itens[0].itemPesquisado}</td>
                        )}
                        {each.operacao.includes('Integrada') ? (
                          <td
                            style={{
                              wordWrap: 'break-word',
                              fontSize: each.itens[3]
                                ? '9px'
                                : each.itens[2]
                                ? '9px'
                                : each.itens[1]
                                ? '10px'
                                : '',
                            }}
                          >
                            {each.itens[0] ? (
                              <>
                                {each.itens[0].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[1] ? (
                              <>
                                {each.itens[1].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[2] ? (
                              <>
                                {each.itens[2].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[3] ? (
                              <>
                                {each.itens[3].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                        ) : (
                          <td>{each.itens[0].descricao}</td>
                        )}
                        <td>{each.operacao}</td>
                        <td>{each.usuario}</td>
                        <td>
                          {each.dataLancamento === null
                            ? ''
                            : obterDataHora(each.dataLancamento, true)}
                        </td>

                        {handleResultado(each.itens) != 'Em Andamento' ? (
                          <td>{obterDataHora(each.itens[0].dataRetorno, true)}</td>
                        ) : (
                          <td />
                        )}
                        <td>
                          {handleResultado(each.itens) === 'Em Acordo' ? validaVigencia(each) : ''}
                        </td>
                        <td>{handleResultado(each.itens)}</td>
                        <td id="icons-container">
                          <ButtonTooltip onClick={() => handleGoToDetails(each)} type="Visualizar" />
                          <ButtonTooltip
                            onClick={() => {
                              if (canEdit) {
                                handleGoToEditarPesquisa(each);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                            type="Editar"
                          />
                          <ButtonTooltip
                            onClick={() => {
                              if (canDelete) {
                                setIdPesquisa(each.itens[0].idPesquisaEntidade);
                                setNumLiberacao(each.numLiberacao);
                                setIsDialogVisibleConfirmarExclusao(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                            type="Cancelar"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            )
          }


          <div id="gerar-xls">
            <SecundaryButton
              size="sm"
              onClick={() => {
                setSearch('')
                history.push('/consultar-pesquisas-lancadas/')
              }}
            >
              Voltar
            </SecundaryButton>

            <button
            disabled={loadingXlsx}
            style={{ background: '#FFDA53', borderRadius: '4px', border: 'None'}}
            onClick={() => handleXmlRequest({
              itensPerPage: totalItems,
              keyword: search,
              page: '1',
              request
            })}
            >{loadingXlsx ? 'Exportando...' : 'Exportar' }
            </button>
          </div>
            <Pagination
            lastPage={lastPage}
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            paginate={paginate}
            currentPage={String(currentPage)}
            setRefresh={setRefresh}
            setData={setData}
            setRefreshitensPerPage={setRefreshitensPerPage}
            totalPosts={totalItems}
            />
        </div>
      </div>
    </div>
  );
};

export default TablePesquisa;
