/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import RelatorioXLSAdmin from './RelatorioXLSAdmin';
import RelatorioXLSUser from './RelatorioXLSUser';
import { FiltroResumoChecklist } from './';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { removerSpecials } from '../../util/format';
import GeneratePDFChecklist from './GeneratePDFChecklist';
import { useHistory } from 'react-router-dom';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterDataHora } from '../../util/date';

type ListagemConsulta = {
  checklistValidade: string;
  nomeClienteTransportador?: string;
  dataInclusao: any;
  dataValidade: any;
  idChecklist: Number;
  idClienteEmbarcador: Number;
  idClienteTransportador: Number;
  listaDispositivo: [
    {
      idChecklist: Number;
      idDispositivo: Number;
      nomeDispositivo: string;
      situacaoDispositivo: string;
      tipoDispositivo: string;
    }
  ];
  marcaBau: string;
  marcaCarreta: string;
  marcaVeiculo: string;
  modeloBau: string;
  modeloCarreta: string;
  modeloVeiculo: string;
  numeroBau: string;
  placaCarreta: string;
  placaVeiculo: string;
  responsavel: string;
  telefoneResponsavel: string;
  tipoFrotaCarreta: string;
  tipoFrotaVeiculo: string;
  usuarioLancamento: string;
};

export type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

const ResumoChecklist = () => {
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroResumoChecklist };
  } = useLocation();
  const { state } = location;
  const [data, setData] = useState<ListagemConsulta[]>([]);
  const [dataModel, setDataModel] = useState<FiltroResumoChecklist | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const { role } = getCookieSessionData().usuarioVO;
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [dataSearch, setDataSearch] = useState<ListagemConsulta[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const [excelGenerationUser, setExcelGenerationUser] = useState<ExcelGeneration2[] | undefined>();
  const [excelGenerationAdmin, setExcelGenerationAdmin] = useState<
    ExcelGeneration2[] | undefined
  >();

  function separar(base: any, max: any) {
    let res = [];
    for (let i = 0; i < max; i++) {
      res.push(base.slice(i, i + 1)[0]);
    }
    return res;
  }

  useEffect(() => {
    setData(state.listagem);
    // setDataTabela(converteArray(state.listagem));
    setDataModel(state.model);
  }, [state.model, state.listagem]);

  useEffect(() => {
    if (isAllowedByRole(['user'])) {
      RelatorioXLSUser(state, setExcelGenerationUser);
    } else {
      RelatorioXLSAdmin(state, setExcelGenerationAdmin);
    }
  }, [state]);

  const getTipoVeiculo = (each: any) => {
    if (each.placaVeiculo != null && each.placaCarreta === null) {
      return 'Veículo';
    } else if (each.placaCarreta) {
      return 'Carreta';
    } else if (each.numeroBau) {
      return 'Baú';
    } else {
      return '';
    }
  };

  const clearValue = (v: any) => {
    return removerSpecials(v?.toString().toLowerCase());
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      const searchValue = clearValue(searchTerm);

      const filteredData = data.filter(
        (each: ListagemConsulta) =>
          clearValue(each.placaCarreta).includes(searchValue) ||
          clearValue(each.placaVeiculo).includes(searchValue) ||
          clearValue(each.numeroBau).includes(searchValue) ||
          clearValue(each.marcaBau).includes(searchValue) ||
          clearValue(each.marcaCarreta).includes(searchValue) ||
          clearValue(each.marcaVeiculo).includes(searchValue) ||
          clearValue(each.modeloBau).includes(searchValue) ||
          clearValue(each.modeloCarreta).includes(searchValue) ||
          clearValue(each.modeloVeiculo).includes(searchValue) ||
          clearValue(getTipoFrota(each.tipoFrotaCarreta)).includes(searchValue) ||
          clearValue(getTipoFrota(each.tipoFrotaVeiculo)).includes(searchValue) ||
          (role === 'admin' &&
            clearValue(dataModel?.nomeClienteTransportador).includes(searchValue)) ||
          clearValue(each.responsavel).includes(searchValue) ||
          clearValue(each.dataInclusao ? obterDataHora(each.dataInclusao, true) : '').includes(
            searchValue
          )
      );

      setDataSearch(filteredData);
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Checklist"
      caminho="Consultas/Relatórios > Checklist"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={currentPage}
      excelGeneration={isAllowedByRole(['user']) ? excelGenerationUser : excelGenerationAdmin}
      filename="RelatorioChecklist"
      pdf
      onClickPdf={() => GeneratePDFChecklist(separar(data, data?.length), dataModel, data?.length)}
      filter
      onClickFilter={() => history.goBack()}
    >
      <div className="table-responsive" style={{ flex: 1 }}>
        {loadingSearch ? (
          <Spinner />
        ) : role === 'admin' ? (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Placa / Baú</th>
                <th>Tipo Veículo</th>
                <th>Marca/Modelo</th>
                <th>Tipo Frota</th>
                <th>Transportador</th>
                <th>Responsável</th>
                <th>Data/Hora Realização</th>
                <th>Checklist</th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((each: ListagemConsulta, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {each.placaVeiculo
                          ? each.placaVeiculo
                          : each.placaCarreta
                          ? each.placaCarreta
                          : each.numeroBau
                          ? each.numeroBau
                          : ''}
                      </td>
                      <td>{getTipoVeiculo(each)}</td>
                      <td>
                        {each.marcaVeiculo && each.modeloVeiculo
                          ? `${`${each.marcaVeiculo} ${each.modeloVeiculo}`}`
                          : each.marcaCarreta && each.modeloCarreta
                          ? `${`${each.marcaCarreta} ${each.modeloCarreta}`}`
                          : each.modeloBau
                          ? each.modeloBau
                          : ''}
                      </td>
                      <td>
                        {each.tipoFrotaVeiculo
                          ? getTipoFrota(each.tipoFrotaVeiculo)
                          : each.tipoFrotaCarreta
                          ? getTipoFrota(each.tipoFrotaCarreta)
                          : ''}
                      </td>
                      <td>{each?.nomeClienteTransportador ? each.nomeClienteTransportador : ''}</td>
                      <td>{each.responsavel ? each.responsavel : ''}</td>
                      <td>{each.dataInclusao ? obterDataHora(each.dataInclusao, true) : ''}</td>
                      <td align="right">
                        <ButtonTooltip
                          check
                          type="Check"
                          tooltip={
                            each?.checklistValidade?.includes('OK')
                              ? each?.checklistValidade
                              : each?.listaDispositivo?.filter((disp) =>
                                  disp?.situacaoDispositivo?.includes('2')
                                )?.length > 0 &&
                                each?.listaDispositivo?.filter((disp) =>
                                  disp?.situacaoDispositivo?.includes('3')
                                )?.length <= 0
                              ? each?.checklistValidade
                              : `Dispositivos pendentes de manutenção: (${each?.listaDispositivo
                                  .filter((fil) => fil?.situacaoDispositivo === '3')
                                  .map((each) => each?.nomeDispositivo)}
                  )` || ''
                          }
                          onClick={() => null}
                          color={
                            each?.checklistValidade?.includes('OK') ? '#3AE963' : '#E93A3A' || ''
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Placa / Baú</th>
                <th>Tipo Veículo</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Tipo Frota</th>
                <th>Responsável</th>
                <th>Data/Hora Realização</th>
                <th>Checklist</th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((each: ListagemConsulta, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {each.placaVeiculo
                          ? each.placaVeiculo
                          : each.placaCarreta
                          ? each.placaCarreta
                          : each.numeroBau
                          ? each.numeroBau
                          : ''}
                      </td>
                      <td>{getTipoVeiculo(each)}</td>
                      <td>
                        {' '}
                        {each.marcaVeiculo
                          ? each.marcaVeiculo
                          : each.marcaCarreta
                          ? each.marcaCarreta
                          : each.marcaBau
                          ? each.marcaBau
                          : ''}
                      </td>
                      <td>
                        {each.modeloVeiculo
                          ? each.modeloVeiculo
                          : each.modeloCarreta
                          ? each.modeloCarreta
                          : each.modeloBau
                          ? each.modeloBau
                          : ''}
                      </td>
                      <td>
                        {each.tipoFrotaCarreta
                          ? getTipoFrota(each.tipoFrotaCarreta)
                          : each.tipoFrotaVeiculo
                          ? getTipoFrota(each.tipoFrotaVeiculo)
                          : ''}
                      </td>
                      <td>{each.responsavel ? each.responsavel : ''}</td>
                      <td>{each.dataInclusao ? obterDataHora(each.dataInclusao, true) : ''}</td>
                      <td align="right">
                        <ButtonTooltip
                          check
                          type="Check"
                          tooltip={
                            each?.checklistValidade?.includes('OK')
                              ? each?.checklistValidade
                              : each?.listaDispositivo?.filter((disp: any) =>
                                  disp?.situacaoDispositivo?.includes('2')
                                ).length > 0 &&
                                each?.listaDispositivo?.filter((disp: any) =>
                                  disp?.situacaoDispositivo?.includes('3')
                                ).length <= 0
                              ? each?.checklistValidade
                              : `Dispositivos pendentes de manutenção: (${each?.listaDispositivo
                                  ?.filter((fil: any) => fil?.situacaoDispositivo === '3')
                                  ?.map((each: any) => each?.nomeDispositivo)}
                    )` || ''
                          }
                          onClick={() => null}
                          color={
                            each?.checklistValidade?.includes('OK') ? '#3AE963' : '#E93A3A' || ''
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Tela>
  );
};

export default ResumoChecklist;
