/* eslint-disable */
import { MultiSelect } from 'primereact/multiselect';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Spinner, Col } from 'react-bootstrap';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import { getCookieSessionData } from '../../services/cookieService';

type SMMapa = {
  bau: string;
  desMotivoFinalizacaoSm: string;
  dtCadastroSm: string;
  dtFimPlanejadoSm: string;
  dtFimRealSm: string;
  dtHrUltimaPosicao: string;
  dtInicioPlanejadoSm: string;
  dtInicioRealSm: string;
  hrFimPlanejadoSm: string;
  hrFimRealSm: string;
  hrInicioPlanejadoSm: string;
  hrInicioRealSm: string;
  idCliente: number;
  idSm: number;
  latitudeAtual: number;
  velocidade: string;
  temperatura: string;
  ignicao: string;
  operacao: string;
  listMonitoramentoSmMapaPontoVO: {
    distancia: string;
    horario: string;
    idSm: number;
    latitude: number;
    longitude: number;
    ponto: string;
    remessa: string;
    previsao: string;
    previsaocomparadas: string;
    raioAproximacao: number;
    dtHrPlanejadaViagemPonto: string;
    dtHrChegada: string;
    dtHrIncioRealSm: string;
    dtHrPrevisaoComParadas: string;
    dtHrPrevisao: string;
  }[];
  localizacao: string;
  longitudeAtual: number;
  nomeTransportador: string;
  nomeTecnologiaRastreamento: string;
  placas: string;
  possuiRotaOuPonto: string;
  progressao: string;
  razaoSocialEmbarcador: string;
  rota: string;
  statusFinalizacaoSm: boolean;
  statusViagem: string;
  tecnologia: string;
}[];

type Props = {
  loading: boolean;
  setSmFiltrada: Dispatch<SetStateAction<SMMapa>>;
  data: SMMapa;
  goToMap: () => void;
  veiculosMapa: any[];
};

export const MapaFilter: React.FC<Props> = ({
  loading,
  setSmFiltrada,
  data,
  goToMap,
  veiculosMapa,
}: Props) => {
  const {
    usuarioVO: { isEmbarcador },
  } = getCookieSessionData();
  const [filtro, setFiltro] = useState<any[]>([]);
  const [operacao, setOperacao] = useState<SMMapa[]>();
  const [cdOrigem, setCdOrigem] = useState();
  const [cdOrigemArr, setCdOrigemArr] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const operacaoArr = uniqBy(data, 'operacao');
  const placaArr: SMMapa = [];

  useEffect(() => {
    if (isEmbarcador) {
      setCdOrigemArr(
        uniqBy(filtro.length ? filtro : data, 'listMonitoramentoSmMapaPontoVO[0].ponto').map(
          (item) => {
            return { ponto: item.listMonitoramentoSmMapaPontoVO[0].ponto.split(': ')[1] };
          }
        )
      );
    }
  }, [data, filtro, isEmbarcador]);

  useEffect(() => {
    if (isEmbarcador && !operacao?.length) {
      setCdOrigem(undefined);
    }
  }, [operacao, isEmbarcador]);

  if (!isEmbarcador) {
    data?.forEach((d: any) => {


      if (d.placas.includes(' ') && d.placas.split(' ')[1]) {
        d.placas.split(' ').forEach((p: string, index: number) => {

          return (
            p.trim().length &&
            veiculosMapa.find((v: any) => v.placaVeiculo === p) &&
            placaArr.push({ ...d, placas: p.trim() })
          );
        });
      } else {
        placaArr.push({ ...d, placas: d.placas.trim() });
      }
      return placaArr;
    });
  }

  const handlePlacaChange = (value: any) => {
    setFiltro(value);
    let filtered = data.filter((a: any) => {
      return value.find((v: any) => a.placas.includes(v.placas));
    });
    const filteredNoSm = filtered.filter((f: any) => {
      return f.idSm === null;
    });
    filtered = filtered.filter((f: any) => {
      return f.idSm !== null;
    });
    const sortedArr = concat(
      uniqBy(sortBy(filtered, 'placas'), 'idSm'),
      sortBy(filteredNoSm, 'placas')
    );
    setSmFiltrada(sortedArr);
  };

  const handleEmbarcadorChange = (value: any, type?: string) => {
    let filtered: any[] = [];
    if (type === 'operacao') {
      setOperacao(value);
      filtered = data.filter((a: any) => {
        return value.find((v: any) => a.operacao === v.operacao);
      });
      setFiltro(filtered);
    }
    if (type === 'cdOrigem') {
      setCdOrigem(value);
      filtered = data.filter((a: any) => {
        return value.find(
          (v: any) => a.listMonitoramentoSmMapaPontoVO[0].ponto.split(': ')[1] === v.ponto
        );
      });
    }
    const sortedArr = sortBy(filtered, 'placas');
    setSmFiltrada(sortedArr);
  };

  const handleGoToMap = () => {
    if (isEmbarcador && !operacao?.length) {
      setError('Selecione ao menos uma operação');
      return;
    }
    goToMap();
  };

  const renderEmbarcadorOrTransportador = () => {
    if (!isEmbarcador) {
      return (
        <Form.Group style={{ display: 'flex', flexDirection: 'column', marginBottom: 50 }}>
          <Form.Label>Veículos</Form.Label>
          <MultiSelect
            style={{ maxWidth: '100vh', minWidth: '30vh' }}
            emptyFilterMessage={<>Sem resultados</>}
            display="chip"
            optionLabel="placas"
            value={filtro}
            options={uniqBy(sortBy(placaArr, 'placas'), 'placas')}
            onChange={(e: any) => handlePlacaChange(e.target.value)}
            filter
          />
        </Form.Group>
      );
    }
    return (
      <>
        <Form.Group style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
          <Form.Label className="requiredField">Operação</Form.Label>
          <MultiSelect
            style={{ maxWidth: '100vh', minWidth: '30vh' }}
            emptyFilterMessage={<>Sem resultados</>}
            display="chip"
            optionLabel="operacao"
            value={operacao}
            options={operacaoArr}
            onChange={(e: any) => handleEmbarcadorChange(e.target.value, 'operacao')}
            filter
            required
          />
          {error && <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>{error}</p>}
        </Form.Group>
        <Form.Group style={{ display: 'flex', flexDirection: 'column', marginBottom: 50 }}>
          <Form.Label>CD Origem</Form.Label>
          <MultiSelect
            style={{ maxWidth: '100vh', minWidth: '30vh' }}
            emptyFilterMessage={<>Sem resultados</>}
            display="chip"
            optionLabel="ponto"
            value={operacao?.length ? cdOrigem : []}
            options={cdOrigemArr}
            onChange={(e: any) => handleEmbarcadorChange(e.target.value, 'cdOrigem')}
            disabled={!operacao?.length}
            filter
          />
        </Form.Group>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <Form noValidate validated={!!error} onSubmit={handleGoToMap}>
          {renderEmbarcadorOrTransportador()}
          <Form.Group
            as={Col}
            style={{
              maxWidth: '100vh',
              minWidth: '30vh',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className="btn-enviar"
              onClick={() => handleGoToMap()}
              style={{ color: 'black' }}
            >
              Confirmar
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  );
};
