/* eslint-disable */
import { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { getCookieSessionData } from '../../services/cookieService';
import { IoCheckmarkSharp } from 'react-icons/io5';
import axios from '../../config/axiosMaquina';
import { PontoType } from '../../api/model/PontoType';
import { Button } from 'primereact/button';
import RelatorioXLS from './RelatorioXLS';
import { ExcelGeneration2 } from '../ResumoChecklist/ResumoChecklist';
import { Spinner } from '../../components/Spinner';
import { Tela } from '../../components/Tela';
import Search from '../../components/Search/Search';
import { obterData, obterDataHora } from '../../util/date';

type ChecklistEmbarcadorType = {
  dataInclusao: string;
  dataValidade: string;
  idChecklist: number;
  marcaVeiculo: string;
  modeloVeiculo: string;
  placaVeiculo: string;
  statusChecklist: boolean;
  transportador: string;
};

const ListagemChecklistEmbarcador = () => {
  const [checklists, setChecklists] = useState<any[]>([]);
  const [pontos, setPontos] = useState<PontoType[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [pontoSelecionado, setPontoSelecionado] = useState<PontoType>();
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPontos, setLoadingPontos] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const current = (searchTerm.trim() != '' ? searchResults : checklists).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const [excel, setExcel] = useState<ExcelGeneration2[]>();

  useEffect(() => {
    if (checklists && pontoSelecionado) {
      RelatorioXLS(checklists, pontoSelecionado?.descricao, setExcel);
    }
  }, [checklists, pontoSelecionado]);

  useEffect(() => {
    if (searchTerm.trim()) {
      setCurrentPage(1);
      const results = checklists?.filter(
        (dados) =>
          dados.placaVeiculo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.placaCarreta?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.numeroBau?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.transportador?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.veiculotipo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.tipoCarreta?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (dados.dataValidade &&
            obterData(dados.dataValidade)?.includes(searchTerm.toLowerCase())) ||
          (dados?.dataInclusao &&
            obterData(dados?.dataInclusao)?.includes(searchTerm.toLowerCase()))
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/ponto/cliente?clienteId=${clienteId}`)
      .then((response: any) => {
        setPontos(response.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  async function loadChecklists(ponto: PontoType) {
    setPontoSelecionado(ponto);
    if (ponto) {
      setLoadingPontos(true);
      const response = await axios.get(
        `/checklist-veiculo/consulta-checklist-ponto?idPonto=${ponto.id}`
      );
      setChecklists(response.data);
      setLoadingPontos(false);
    }
  }

  return (
    <Tela
      nome="Checklist"
      caminho="Checklist"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() != '' ? searchResults.length : checklists.length}
      paginate={paginate}
      currentPage={currentPage}
      excelGeneration={excel}
      filename="Checklist Veículos"
    >
      <div className="content-container" style={{ width: '100%' }}>
        {loading ? (
          <Spinner />
        ) : (
          <div className="painel">
            <Form.Label>CD Origem</Form.Label>
            <Form.Row>
              <Form.Group as={Col}>
                <Dropdown
                  value={pontoSelecionado}
                  options={pontos}
                  optionLabel="descricao"
                  filter
                  showClear
                  filterBy="descricao"
                  placeholder="Selecione"
                  onChange={(e: any) => {
                    loadChecklists(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Search
              setLoading={setLoadingPontos}
              setSearch={setSearch}
              setSearchValue={setSearchTerm}
              search={search}
            />
            {pontoSelecionado ? (
              <>
                <div className="table-responsive" style={{ flex: 1 }}>
                  {loadingPontos ? (
                    <Spinner />
                  ) : (
                    <>
                      <table className="table">
                        <thead className="thead">
                          <tr>
                            <th>Checklist</th>
                            <th>Nº Placa / Baú</th>
                            <th>Descrição</th>
                            <th>Transportador</th>
                            <th>Data Realização</th>
                            <th>Validade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {current.map((each, index) => (
                            <tr key={index}>
                              <td>
                                <Button
                                  tooltip={
                                    each.checklistValidade.includes('OK') ||
                                    each.checklistValidade.includes('Inexistente')
                                      ? each.checklistValidade
                                      : each.listaDispositivo.filter((disp: any) =>
                                          disp.situacaoDispositivo.includes('2')
                                        ).length > 0 &&
                                        each.listaDispositivo.filter((disp: any) =>
                                          disp.situacaoDispositivo.includes('3')
                                        ).length <= 0
                                      ? each.checklistValidade
                                      : `Dispositivos pendentes de manutenção: (${each.listaDispositivo
                                          .filter((fil: any) => fil.situacaoDispositivo === '3')
                                          .map((each: any) => each.nomeDispositivo)}
                                  )`
                                  }
                                  tooltipOptions={{ position: 'bottom' }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                    padding: 0,
                                    paddingRight: 5,
                                    marginBottom: 2,
                                  }}
                                >
                                  <IoCheckmarkSharp
                                    size={22}
                                    color={
                                      each.checklistValidade.includes('OK') ? '#3AE963' : '#E93A3A'
                                    }
                                  />
                                </Button>
                              </td>
                              <td>
                                {each.placaVeiculo} {each.placaCarreta}
                                {each.numeroBau}
                              </td>
                              <td>
                                {each.veiculotipo}
                                {each.tipoCarreta}
                              </td>
                              <td>{each.transportador}</td>
                              <td>
                                {each.checklistValidade?.includes('Inexistente')
                                  ? ''
                                  : obterDataHora(each.dataInclusao, true)}
                              </td>

                              <td>
                                {each.checklistValidade?.includes('OK')
                                  ? obterData(each.dataValidade)
                                  : ''}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </Tela>
  );
};

export default ListagemChecklistEmbarcador;
